import {createTheme, Theme} from '@mui/material/styles';
import {getDesignTokens} from './defaultTheme';
import nhvrLightTheme from './themes/generated/lightTheme.json';
import nhvrDarkTheme from './themes/generated/darkTheme.json';
import waTheme from './themes/generated/waTheme.json';
import nhvrGoLightTheme from './themes/generated/nhvr-go-lightTheme.json';
import nhvrGoDarkTheme from './themes/generated/nhvr-go-darkTheme.json';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {DomainMapWithDefault} from 'containers-content';
import {isNhvrGo} from 'utils/global';

const useNHVRGo = isNhvrGo();

const lightTheme = useNHVRGo ? nhvrGoLightTheme : nhvrLightTheme;
const darkTheme = useNHVRGo ? nhvrGoDarkTheme : nhvrDarkTheme;

const rawThemes = {
  light: lightTheme as unknown as Theme,
  dark: darkTheme as unknown as Theme,
  wa: waTheme as unknown as Theme,
};

const generateTheme = (themeName: string) => {
  return createTheme(
    getDesignTokens(rawThemes[themeName as keyof typeof rawThemes] as Theme),
  );
};

export const themes = {
  light: generateTheme('light'),
  dark: generateTheme('dark'),
  wa: generateTheme('wa'),
} as const;

export type ThemeKey = keyof typeof themes;

export const domainThemeMap: DomainMapWithDefault<
  AuthorisationDomains,
  ThemeKey
> = {
  wa: 'wa',
  adf: 'wa',
  nhvr: 'light',
  default: 'light',
};

type ThemeDomainMap = {
  [key in keyof typeof themes]: AuthorisationDomains;
};

export const themeDomainMap: ThemeDomainMap = {
  wa: AuthorisationDomains.WA,
  light: AuthorisationDomains.NHVR,
  dark: AuthorisationDomains.NHVR,
} as const;
