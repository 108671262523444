import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
  AuthorisationDomains,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/incidentReport',
  component: lazy(
    () => import(/* webpackChunkName: "IncidentReport" */ './IncidentReport'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'NHVAS Incident report',
  menuDescription:
    'Submit an incident report associated with an NHVAS accredited vehicle.',
  menuIcon: ReportProblemOutlined,
  authorisation: {
    domain: AuthorisationDomains.NHVR,
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
