import {DSP_AuditRequestNotificationItem} from '../types';

import {DSP_AuditRequestCustomerNotificationCardViewProps} from './AuditRequestCustomerNotificationCard.View';

export interface DSP_UseAuditRequestNotificationCardProps {
  notification: DSP_AuditRequestNotificationItem;
}

export function useAuditRequestCustomerNotificationCard({
  notification,
}: DSP_UseAuditRequestNotificationCardProps): DSP_AuditRequestCustomerNotificationCardViewProps {
  return {
    notification,
    userId: notification.fromUserId ?? undefined,
  };
}
