import React from 'react';

import {
  DSP_CustomerAccountEntityAvatarWithName,
  DSP_CustomerAccountEntityLoaderProvider,
} from 'components/DesignSystem/Portal/Entity/AccountEntity/CustomerEntity';
import {GetAuditRequestCustomerAccountPublicDocument} from 'containers-accreditation/AuditRequests/api/queries/AuditRequestCustomerAccountQuery.generated';

import {
  DSP_BaseAuditRequestNotificationCard,
  DSP_BaseAuditRequestNotificationCardProps,
} from '../Base/BaseAuditRequestNotificationCard.Connector';

export interface DSP_AuditRequestProviderNotificationCardViewProps
  extends Omit<DSP_BaseAuditRequestNotificationCardProps, 'avatar'> {
  auditRequestId: number | undefined | null;
}

export const DSP_AuditRequestProviderNotificationCardView = ({
  notification,
  auditRequestId,
}: DSP_AuditRequestProviderNotificationCardViewProps) => {
  return (
    <DSP_BaseAuditRequestNotificationCard
      notification={notification}
      avatar={
        auditRequestId && (
          <DSP_CustomerAccountEntityLoaderProvider
            entityId={auditRequestId}
            query={GetAuditRequestCustomerAccountPublicDocument}
          >
            <DSP_CustomerAccountEntityAvatarWithName />
          </DSP_CustomerAccountEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AuditRequestProviderNotificationCardView.displayName =
  'DSP_AuditRequestProviderNotificationCardView';
