import Skeleton from '@mui/material/Skeleton';
import Badge from '@mui/material/Badge';
import clsx from 'clsx';
import * as R from 'ramda';
import React, {ReactNode, SyntheticEvent} from 'react';
import {Maybe, VehicleComponentWithRelations} from 'typeDefs/types';
import {useStyles} from '../image.styles';

export enum ModeConstants {
  ICON_CONFIGURATION = 'iconConfiguration',
  ICON = 'icon',
  SINGLE = 'single',
  CARD = 'card',
  CARD_CONFIGURATION = 'cardConfiguration',
  CONFIGURATION = 'configuration',
}

type VehicleComponentImageBaseProps = {
  badgeContent?: string;
  mode?: ModeConstants;
  idx?: number;
  disableRearOffset?: boolean;
};

type VehicleComponentImagePropsWithComponent =
  VehicleComponentImageBaseProps & {
    vehicleComponent: VehicleComponentWithRelations | undefined;
    vehicleImageUrl?: never;
  };

type VehicleComponentImagePropsWithImage = VehicleComponentImageBaseProps & {
  vehicleComponent?: never;
  vehicleImageUrl: string;
};

type VehicleComponentImageProps =
  | VehicleComponentImagePropsWithComponent
  | VehicleComponentImagePropsWithImage;

const VehicleComponentImageBadge =
  ({badgeContent}: {badgeContent: ReactNode}) =>
  // eslint-disable-next-line react/display-name
  ({children}: {children: ReactNode}) => {
    return (
      <Badge
        color="secondary"
        badgeContent={badgeContent}
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBadge-badge': {
            left: '12px',
            top: '12px',
          },
        }}
      >
        {children}
      </Badge>
    );
  };

VehicleComponentImageBadge.displayName = 'VehicleComponentImageBadge';

/**
 * VehicleComponentImage
 */
const VehicleComponentImage: React.FC<VehicleComponentImageProps> = ({
  vehicleComponent = {} as VehicleComponentWithRelations,
  vehicleImageUrl,
  badgeContent,
  idx = 0,
  mode = ModeConstants.SINGLE,
  disableRearOffset,
}: VehicleComponentImageProps) => {
  const classes = useStyles();

  const className = clsx(classes.defaultImage, {
    [classes.iconImage]:
      mode === ModeConstants.ICON_CONFIGURATION || mode === ModeConstants.ICON,
    [classes.singleImage]: mode === ModeConstants.SINGLE,
    [classes.cardImage]: mode === ModeConstants.CARD,
    [classes.configuration]: mode === ModeConstants.CONFIGURATION,
    [classes.cardConfigurationImage]: mode === ModeConstants.CARD_CONFIGURATION,
  });

  if (R.isNil(vehicleComponent) && R.isNil(vehicleImageUrl)) {
    const SkeletonSize: {
      [key in string]: {
        width: number;
        height: number;
      };
    } = {
      [ModeConstants.SINGLE]: {
        width: 21,
        height: 11,
      },
      [ModeConstants.ICON_CONFIGURATION]: {
        width: 21,
        height: 11,
      },
      [ModeConstants.ICON]: {
        width: 21,
        height: 11,
      },
      [ModeConstants.CARD_CONFIGURATION]: {
        width: 90,
        height: 48,
      },
    };
    return (
      <Skeleton
        className={className}
        variant="rectangular"
        width={SkeletonSize[mode]?.width || 210}
        height={SkeletonSize[mode]?.height || 118}
      />
    );
  }

  const imageOffset =
    idx > 0
      ? calculateOffset(vehicleComponent?.imageOffset)
      : R.defaultTo(0, vehicleComponent?.imageOffset);
  const imageRearOffset = disableRearOffset
    ? 0
    : calculateOffset(vehicleComponent?.imageRearOffset);
  const style: {
    [key in string]: React.CSSProperties;
  } = {
    [ModeConstants.CONFIGURATION]: {
      marginLeft: -(imageOffset / 2.8) + 'em',
      marginRight: -(imageRearOffset / 2.8) + 'em',
    },
    [ModeConstants.CARD_CONFIGURATION]: {
      marginLeft: -(imageOffset / (3 * 2.8)) + 'em',
      marginRight: -(imageRearOffset / (3 * 2.8)) + 'em',
    },
    [ModeConstants.ICON_CONFIGURATION]: {
      marginLeft: -(imageOffset / (10 * 2.8)) + 'em',
      marginRight: -(imageRearOffset / (10 * 2.8)) + 'em',
    },
  };

  const BadgeWrapper = badgeContent
    ? VehicleComponentImageBadge({badgeContent})
    : React.Fragment;

  return (
    <BadgeWrapper>
      <img
        data-zoom="2"
        data-html="true"
        className={className}
        title={R.defaultTo('', vehicleComponent?.name)}
        data-toggle="tooltip"
        data-placement="bottom"
        style={vehicleImageUrl ? undefined : style[mode]}
        alt={R.defaultTo('', vehicleComponent.name)}
        src={vehicleImageUrl ?? vehicleComponent.fileList?.vehicleImageUrl}
        onError={(e: SyntheticEvent<HTMLImageElement, Event>): void => {
          e.currentTarget.src =
            'https://nhvrappsprod.blob.core.windows.net/vehicle-components/default.png';
        }}
      />
    </BadgeWrapper>
  );
};

export default VehicleComponentImage;

function calculateOffset(originalOffset: Maybe<number> | undefined) {
  return R.defaultTo(0, originalOffset) * 2 + 3.5;
}
