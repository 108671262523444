import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import GroupIcon from '@mui/icons-material/Group';
import {BuiltInRoles} from '@regulatory-platform/common-utils/dist';
export default {
  path: '/create-business-account',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateBusinessAccountLanding" */ './CreateBusinessAccountLanding'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  showInMode: 'beta',
  menuTitle: 'Create Business account',
  menuIcon: GroupIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.AUTHENTICATED] as AuthorisationPolicy[],
  },
  sections: [],
};
