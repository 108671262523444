import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/drivers/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationDriverMappingDetails" */ './AccreditationDriverMapping'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accreditation Driver',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccreditationDriverMappingWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Drivers',
      to: '..',
    },
  ],
};
