import React, {forwardRef} from 'react';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_BaseListItem, DSL_BaseListItemProps} from './BaseListItem';

export interface DSL_ListItemProps
  extends Omit<DSL_BaseListItemProps, DSL_RestrictedVisualProps | 'component'> {
  smallPadding?: boolean;
  showDiscs?: boolean;
}

export const DSL_ListItem = forwardRef<HTMLLIElement, DSL_ListItemProps>(
  ({smallPadding, showDiscs, ...restProps}, ref) => {
    return (
      <DSL_BaseListItem
        ref={ref}
        internal_sx={{
          ...(smallPadding && {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0.5,
          }),
          ...(showDiscs && {
            display: 'list-item',
          }),
        }}
        {...restProps}
      />
    );
  },
);

DSL_ListItem.displayName = 'DSL_ListItem';

export {
  //
  /** @deprecated use DSL_ListItemProps instead*/
  DSL_ListItemProps as ListItemProps, //
  /** @deprecated use DSL_ListItem instead*/
  DSL_ListItem as ListItem,
};
