import {useState, useCallback} from 'react';

export type DSL_UseDialogResult = [
  boolean,
  () => void,
  () => void,
  () => void,
  () => Promise<unknown>,
  (res: boolean) => void,
];

export const useDialog = (): DSL_UseDialogResult => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [, setResponse] = useState<boolean | null>(null);
  const [resolvePromise, setResolvePromise] =
    useState<(response: boolean) => void | null>();

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const toggleDialog = useCallback(() => {
    setDialogOpen(prev => !prev);
  }, []);

  /**
   * Handle async dialog responses
   */
  const handleResponse = useCallback(
    (response: boolean) => {
      setDialogOpen(false);
      setResponse(response);
      if (resolvePromise) {
        resolvePromise(response);
        setResolvePromise(undefined);
      }
    },
    [setDialogOpen, setResponse, resolvePromise],
  );

  /**
   * async trigger; opens dialog, returns promise
   *
   * const promptResponse = await promptUser();
   * if (!promptResponse) return;
   *
   * <SomeDialog
   *   open={overwriteDialogOpen}
   *   onCancel={() => handleOverwriteResponse(false)}
   *   onConfirm={() => handleOverwriteResponse(true)}
   * />
   * @returns Promise<booealn>
   */
  const promptUser = async () => {
    setDialogOpen(true);
    return new Promise(resolve => {
      setResolvePromise((prevResolve: unknown) => {
        if (prevResolve) {
          throw new Error('Previous promise not resolved');
        }
        return resolve;
      });
    });
  };

  return [
    dialogOpen,
    openDialog,
    closeDialog,
    toggleDialog,
    promptUser,
    handleResponse,
  ];
};
