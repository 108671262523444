import React from 'react';

import {List as MuiList, ListProps as MuiListProps} from '@mui/material';
import {SystemCssProperties} from '@mui/system';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_ListProps
  extends Omit<MuiListProps, DSL_RestrictedVisualProps | 'component'> {
  maxWidth?: React.CSSProperties['maxWidth'];
  width?: React.CSSProperties['width'];
  listStyleType?: string;
  listStylePosition?: string;
  /** @deprecated INTERNAL USE ONLY */
  internal_sx?: MuiListProps['sx'];
  overflowX?: SystemCssProperties['overflowX'];
  overflowY?: SystemCssProperties['overflowY'];
  height?: SystemCssProperties['height'];
  removePadding?: boolean;
  showDiscs?: boolean;
}

export const DSL_List = React.forwardRef<HTMLUListElement, DSL_ListProps>(
  (
    {
      // eslint-disable-next-line react/prop-types
      width = '100%',
      // eslint-disable-next-line react/prop-types
      maxWidth = '100%',
      // eslint-disable-next-line react/prop-types
      internal_sx = [],
      // eslint-disable-next-line react/prop-types
      overflowX,
      // eslint-disable-next-line react/prop-types
      overflowY,
      // eslint-disable-next-line react/prop-types
      height,
      // eslint-disable-next-line react/prop-types
      listStyleType,
      // eslint-disable-next-line react/prop-types
      listStylePosition,
      removePadding,
      ...props
    }: DSL_ListProps,
    ref,
  ) => {
    const enforceArray = (maybeArray: MuiListProps['sx']) =>
      Array.isArray(maybeArray) ? maybeArray : [maybeArray];

    return (
      <MuiList
        ref={ref}
        component="ul"
        sx={[
          {
            maxWidth,
            width,
            overflowX,
            overflowY,
            height,
            listStyleType,
            listStylePosition,
            ...(removePadding && {
              padding: 0,
              paddingLeft: 1.5, // this 12px is needed to align the discs
            }),
          },
          ...enforceArray(internal_sx),
        ]}
        {...props}
      />
    );
  },
);

DSL_List.displayName = 'DSL_List';

export {
  //
  /** @deprecated use DSL_ListProps instead*/
  DSL_ListProps as ListProps, //
  /** @deprecated use DSL_List instead*/
  DSL_List as List,
};
