import {useAppBarThemeProvider} from './useAppBarThemeProvider';
import React, {ReactNode} from 'react';
import {ThemeProvider} from '@mui/material';

export const AppBarThemeProvider = ({
  map,
  children,
}: {
  map?: boolean;
  children: ReactNode;
}) => {
  const appBarTheme = useAppBarThemeProvider({map});
  return <ThemeProvider theme={appBarTheme}>{children}</ThemeProvider>;
};
