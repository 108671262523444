import React from 'react';
// @ts-expect-error ts file not found
import DeviceOrientation, {Orientation} from 'react-screen-orientation';

import {
  DSL_BaseIconButton,
  DSL_CloseIcon,
  DSL_Flex,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_Heading6,
  DSL_ScreenRotationIcon,
} from 'components/DesignSystem/Library';
import {NHVRLogo} from '../theming/NHVRLogo';
import {usePortraitLock} from './usePortraitLock';
import {props} from 'ramda';

interface PortraitLockProps {
  children: React.ReactNode;
}

export const PortraitLock = ({children}: PortraitLockProps) => {
  const {
    isMap,
    viewportHeight,
    maxHeightInPixelsForLandscape,
    onHidePortraitLock,
    showPortraitLock,
  } = usePortraitLock();

  const maxWidthForPortraitLock = '600px';

  if (!isMap || !showPortraitLock) {
    return <>{children}</>;
  }

  return (
    <DeviceOrientation>
      <Orientation orientation="landscape" alwaysRender={false}>
        {viewportHeight < maxHeightInPixelsForLandscape ? (
          <DSL_FlexColumn
            justifyContent="center"
            alignItems="center"
            width={'100vw'}
            height={'100vh'}
            bgcolor={'primary.main'}
            position={'relative'}
          >
            <DSL_FlexRow
              width={'100%'}
              justifyContent={'flex-end'}
              maxWidth={maxWidthForPortraitLock}
              position={'absolute'}
              top={'16px'}
              right={'16px'}
            >
              <DSL_BaseIconButton
                aria-label="close"
                size={'medium'}
                onClick={onHidePortraitLock}
                {...props}
              >
                <DSL_CloseIcon
                  sx={theme => ({
                    color: theme.palette.primary.contrastText,
                  })}
                  fontSize={'medium'}
                />
              </DSL_BaseIconButton>
            </DSL_FlexRow>
            <DSL_FlexColumn
              justifyContent="space-between"
              alignItems="center"
              flexWrap={'nowrap'}
              maxWidth={maxWidthForPortraitLock}
              gap={5}
            >
              <DSL_Flex>
                <NHVRLogo />
              </DSL_Flex>
              <DSL_FlexRow
                alignItems={'center'}
                gap={2}
                flexWrap={'nowrap'}
                px={4}
              >
                <DSL_Flex color={'primary.contrastText'}>
                  <DSL_ScreenRotationIcon fontSize={'large'} />
                </DSL_Flex>
                <DSL_FlexColumn>
                  <DSL_Heading6 color={'primary.contrastText'}>
                    Please rotate your device to portrait mode to continue.
                  </DSL_Heading6>
                  <DSL_Heading6 color={'primary.contrastText'}>
                    Landscape mode of national maps is not supported...
                  </DSL_Heading6>
                </DSL_FlexColumn>
              </DSL_FlexRow>
            </DSL_FlexColumn>
          </DSL_FlexColumn>
        ) : (
          children
        )}
      </Orientation>
      <Orientation orientation="portrait" alwaysRender={false}>
        {children}
      </Orientation>
    </DeviceOrientation>
  );
};
