import React from 'react';

import VehicleComponentImage, {
  ModeConstants,
} from 'components/VehicleBuilder/images/VehicleComponentImage/VehicleComponentImage';
import {VehicleConfigurationImages} from 'components/VehicleBuilder/images/VehicleConfigurationImages';

import {
  ButtonCardActionArea,
  CardContent,
  FlexColumn,
  FlexRow,
  OutlinedCard,
} from 'components/DesignSystem/Library';

export interface DSP_BaseVehicleCardProps {
  children?: React.ReactNode;
  vehicleComponentIds: number[];
  onClick: () => void;
  imageUrl?: string | null;
}

export const DSP_BaseVehicleCard = ({
  children,
  onClick,
  vehicleComponentIds,
  imageUrl,
}: DSP_BaseVehicleCardProps) => {
  return (
    <ButtonCardActionArea onClick={onClick}>
      <OutlinedCard overflow={'initial'}>
        <CardContent
          paddingX={'SM'}
          paddingBottom={imageUrl ? 'XS' : 'NONE'}
          paddingTop={'SM'}
        >
          <FlexColumn gap={0} overflowX={'hidden'}>
            {children}
            <FlexRow justifyContent={'flex-end'} pt={1}>
              {imageUrl ? (
                <VehicleComponentImage
                  mode={ModeConstants.CARD_CONFIGURATION}
                  vehicleImageUrl={imageUrl}
                />
              ) : (
                <VehicleConfigurationImages
                  mode={ModeConstants.CARD_CONFIGURATION}
                  vehicleComponentIds={vehicleComponentIds}
                />
              )}
            </FlexRow>
          </FlexColumn>
        </CardContent>
      </OutlinedCard>
    </ButtonCardActionArea>
  );
};

export {
  //
  /** @deprecated use DSP_BaseVehicleCardProps instead*/
  DSP_BaseVehicleCardProps as BaseVehicleCardProps, //
  /** @deprecated use DSP_BaseVehicleCard instead*/
  DSP_BaseVehicleCard as BaseVehicleCard,
};
