import React from 'react';

import {UserProfile, UserRoles} from '@regulatory-platform/common-utils/dist';
import getUserName from 'app/router/utils/getUserName';
import {ThemeProvider} from 'app/theming/ThemeProvider';
import * as R from 'ramda';
import {mapUserRolesToRole} from 'utils/global';

import {
  Avatar,
  Body1,
  FlexRow,
  FlexColumn,
  PopperListItem,
  Popper,
  ClickableBox,
  Tooltip,
  PopperList,
  PopperListSubheader,
  Heading5,
  Body2,
  ArrowDropDownOutlinedIcon,
  ArrowDropUpOutlinedIcon,
  PopperListItemButton,
  PopperProps,
  OpenerProps,
} from 'components/DesignSystem/Library';

export interface DSP_ProfileMenuViewProps {
  userProfile: UserProfile;
  popperProps: PopperProps;
  openerProps: OpenerProps;
  onListItemClick: (to: string) => void;
  listItems: {
    id: string;
    navigateTo: string;
    menuTitle: string;
    menuSubtitle: string;
    icon: React.ReactNode;
  }[];
}

export const DSP_ProfileMenuView = ({
  userProfile,
  popperProps,
  openerProps,
  onListItemClick,
  listItems,
}: DSP_ProfileMenuViewProps) => {
  return (
    <ClickableBox {...openerProps}>
      <Tooltip title={popperProps.open ? '' : 'Profile and Account'}>
        <FlexRow alignItems={'center'}>
          <FlexColumn
            aria-controls={'menu-list-grow'}
            aria-haspopup="true"
            cursor={'pointer'}
            justifyContent={'center'}
            gap={0}
            hiddenDownTo={'md'}
          >
            <Body1>{getUserName(userProfile)}</Body1>
            <Body2 color={'text.secondary'}>
              {mapUserRolesToRole(
                R.defaultTo([], userProfile?.roles) as UserRoles[],
              )}
            </Body2>
          </FlexColumn>
          <FlexRow
            cursor={'pointer'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={0}
          >
            <Avatar alt="User" src={userProfile?.userImageUrl ?? ''} />
            <Body2>
              {popperProps.open ? (
                <ArrowDropUpOutlinedIcon color={'action'} />
              ) : (
                <ArrowDropDownOutlinedIcon color={'action'} />
              )}
            </Body2>
          </FlexRow>

          <ThemeProvider>
            <Popper id="profile-menu" {...popperProps} disablePortal>
              <PopperList
                aria-labelledby="notifications-subheader"
                subheader={
                  <>
                    <PopperListSubheader
                      disableSticky
                      id="notifications-subheader"
                    >
                      <Heading5>Profile and Account</Heading5>
                    </PopperListSubheader>
                  </>
                }
              >
                {listItems.map((item, idx) => {
                  return (
                    <PopperListItem key={idx}>
                      <PopperListItemButton
                        onClick={() => onListItemClick(item.navigateTo)}
                      >
                        <FlexRow alignItems={'center'}>
                          {item.icon}
                          <FlexColumn gap={0}>
                            <Body1>{item.menuTitle}</Body1>
                            <Body2>{item.menuSubtitle}</Body2>
                          </FlexColumn>
                        </FlexRow>
                      </PopperListItemButton>
                    </PopperListItem>
                  );
                })}
              </PopperList>
            </Popper>
          </ThemeProvider>
        </FlexRow>
      </Tooltip>
    </ClickableBox>
  );
};

export {
  //
  /** @deprecated use DSP_ProfileMenuViewProps instead*/
  DSP_ProfileMenuViewProps as ProfileMenuViewProps, //
  /** @deprecated use DSP_ProfileMenuView instead*/
  DSP_ProfileMenuView as ProfileMenuView,
};
