import {WorkflowActivitySystemType} from '@regulatory-platform/common-utils-workflow/dist/enums/activitySystemType.enum';
import {WorkflowActivityEventType} from '@regulatory-platform/common-utils-workflow/dist/types';
import {
  CommonActionVoter,
  CommonValidator,
} from '@regulatory-platform/common-utils/dist';
import {AccessProductApplicationActivityEventType} from 'containers-access/AccessProductApplication/types';
import {JSONSchema7} from 'json-schema';
import {
  AccessProductApplicationActivityPublic,
  BaseApplicationActivity,
} from 'typeDefs/types';
import {FormEventObject} from 'utils/stores/types';

import {useWorkflowActivityAuthorizer} from './hooks/useWorkflowActivityAuthorizer';
import {useCreateWorkflowActivityMachine} from './machines/useCreateWorkflowActivityMachine';
import {useWorkflowActivitiesMachine} from './machines/useWorkflowActivitiesMachine';
import useWorkflowActivityMachine from './machines/useWorkflowActivityMachine';
import {WorkflowActivityItemProps} from './WorkflowItems';
import {WorkflowTabDrawerProps} from './WorkflowTab/WorkflowTabDrawer';
export {
  OutcomeReason,
  OutcomeConfig,
} from '@regulatory-platform/common-utils/dist';
export {OutcomeType} from '@regulatory-platform/common-utils/dist/enum/outcomeType.enum';
export {WorkflowActivityState} from '@regulatory-platform/common-utils-workflow/dist/types';
export {WorkflowActivitySystemType} from '@regulatory-platform/common-utils-workflow/dist/enums/activitySystemType.enum';

export interface ActivityTree extends AccessProductApplicationActivityPublic {
  relatedActivities?: BaseApplicationActivity[];
}

export interface WorkflowContextSharedPartial {
  origRecord?: AccessProductApplicationActivityPublic;
  isAuthorized: ReturnType<typeof useWorkflowActivityAuthorizer>;
}

export type WorkflowActivitiesService = ReturnType<
  typeof useWorkflowActivitiesMachine
>;
export type WorkflowActivityService = ReturnType<
  typeof useWorkflowActivityMachine
>;
export type CreateWorkflowActivityService = ReturnType<
  typeof useCreateWorkflowActivityMachine
>;

export type UntypedWorkflowRecord = {
  [key: string]: unknown;
  type: AccessProductApplicationActivityEventType;
};

export interface ActivityFormEventObject extends FormEventObject {
  overrideType?: WorkflowActivityEventType;
  data?: {
    context?: {
      type: WorkflowActivityEventType;
    };
  };
}

export enum WorkflowActivityDrawerClientTypes {
  EMAIL = 'Email',
  TIMELINE = 'Timeline',
  OVERVIEW = 'Overview',
  NOTICE = 'Notice',
}

export type WorkflowActivityDrawerTypes =
  | WorkflowActivitySystemType
  | WorkflowActivityDrawerClientTypes;

export enum WorkflowType {
  AccessProductChangeSet = 'AccessProductChangeSet',
  AccessProductApplication = 'AccessProductApplication',
  PbsApplication = 'PbsApplication',
  AccreditationApplication = 'AccreditationApplication',
  Accreditation = 'Accreditation',
  AuditRequest = 'AuditRequest',
}

export interface AuthSchema {
  actionVoter: CommonActionVoter;
  baseSchema: JSONSchema7;
}

export interface EntitySchema {
  actionVoter: CommonActionVoter;
  baseSchema: JSONSchema7;
  validator: CommonValidator;
}

export type WorkflowActivityDrawerProps = Omit<
  WorkflowTabDrawerProps,
  'title' | 'content' | 'footer'
>;

export interface WorkflowActivityResponse extends WorkflowActivityReply {
  replies: WorkflowActivityReply[];
}

export interface WorkflowActivityReply extends WorkflowAuditDetails {
  id: number | undefined;
  description: string | undefined;
  unread: boolean;
}

export type WorkflowAuditDetails = Pick<
  WorkflowActivityItemProps,
  'userId' | 'created'
>;
export type WorkflowAuditAssignedDetails = {
  assignedById?: number;
  assignedToId?: number;
  created?: Date;
};

/**
 * ActivityExtended extends BaseApplicationActivity to provide calculated properties
 */
export interface ActivityExtended extends BaseApplicationActivity {
  unread?: boolean;
}

export type ButtonStatus = {disabled: boolean; loading: boolean};

export type RoadMangersConsentData = {
  hasCoManagedSegments: boolean;
  clearedChangeSetSegmentIds: number[];
  coManagedRoadManagerCodes: string[];
};
