import {getAccreditationSchemeCode} from 'utils/accreditation/getAccreditationSchemeCode';

import {DSP_AccreditationActionNotificationItem} from '../types';

export interface DSP_UseBaseAccreditationActionNotificationCardProps {
  notification: DSP_AccreditationActionNotificationItem;
}

export function useBaseAccreditationActionNotificationCard({
  notification,
}: DSP_UseBaseAccreditationActionNotificationCardProps) {
  const {id, accreditationNumber, accreditationSchemeId, applicationType} =
    notification.data;

  const accreditationSchemeCode =
    getAccreditationSchemeCode(accreditationSchemeId) ?? '';

  return {
    actionDescription: 'Accreditation Application is overdue',
    isRead: notification.readByLoggedInUser,
    href: `/accreditation-${accreditationSchemeCode.toLowerCase()}/${id}`,
    created: notification.created ?? undefined,
    name: applicationType ?? 'Accreditation',
    applicationId: accreditationNumber ?? '',
    status: 'Overdue',
  };
}
