import React, {forwardRef} from 'react';

import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import {useTheme} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';
import {DSL_Body2} from '../../Typography/Body2';

export interface DSL_TooltipProps
  extends Omit<MuiTooltipProps, DSL_RestrictedVisualProps> {
  isClickable?: boolean;
}

export const DSL_Tooltip = forwardRef(
  (
    {
      children,
      isClickable,
      enterDelay,
      title,
      placement,
      ...props
    }: DSL_TooltipProps,
    ref,
  ) => {
    const theme = useTheme();

    return (
      <MuiTooltip
        ref={ref}
        placement={placement ?? 'top'}
        enterTouchDelay={0}
        enterDelay={enterDelay ?? theme.transitions.duration.standard}
        title={title ? <DSL_Body2 color={'inherit'}>{title}</DSL_Body2> : ''}
        aria-label={typeof title === 'string' ? title : undefined}
        sx={{
          cursor: isClickable ? 'pointer' : undefined,
        }}
        {...props}
      >
        {children}
      </MuiTooltip>
    );
  },
);

DSL_Tooltip.displayName = 'DSL_Tooltip';

export {
  //
  /** @deprecated use DSL_TooltipProps instead*/
  DSL_TooltipProps as TooltipProps, //
  /** @deprecated use DSL_Tooltip instead*/
  DSL_Tooltip as Tooltip,
};
