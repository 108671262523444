import {AuditRequestWorkflowState} from 'containers-accreditation/Shared/types';
import {getAccreditationSchemeCode} from 'utils/accreditation/getAccreditationSchemeCode';

import {auditRequestWorkflowStateDescriptionMap} from '../constants';
import {DSP_AuditRequestNotificationItem} from '../types';

export interface DSP_UseBaseAuditRequestNotificationCardProps {
  notification: DSP_AuditRequestNotificationItem;
}

export function useBaseAuditRequestNotificationCard({
  notification,
}: DSP_UseBaseAuditRequestNotificationCardProps) {
  const {id, auditRequestState, auditType, status, accreditationSchemeId} =
    notification.data;

  const actionDescription =
    auditRequestWorkflowStateDescriptionMap[
      auditRequestState?.workflowState as AuditRequestWorkflowState
    ];

  const accreditationSchemeCode =
    getAccreditationSchemeCode(accreditationSchemeId) ?? '';

  return {
    actionDescription,
    isRead: notification.readByLoggedInUser,
    href: `/accreditation-${accreditationSchemeCode.toLowerCase()}/audit-requests/${id}`,
    created: notification.created ?? undefined,
    name: auditType ?? undefined,
    applicationId: accreditationSchemeCode + '-' + id,
    status: status ?? undefined,
  };
}
