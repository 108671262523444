import React from 'react';

export const NHVRGoLogo = () => {
  return (
    <svg
      width="278"
      height="72"
      viewBox="0 0 278 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_725_292)">
        <mask
          id="mask0_725_292"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="278"
          height="48"
        >
          <path d="M278 0H0V47.6008H278V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_725_292)">
          <path
            d="M227.117 19.4293H208.275L200.359 27.8337L212.88 27.8951L200.206 40.8957L195.903 45.2054C195.934 45.2156 195.964 45.2259 195.995 45.2412C198.566 46.2239 201.356 46.7767 204.279 46.7767C204.969 46.7767 205.648 46.7409 206.328 46.6795L218.843 34.1446V46.7716L227.137 38.4645V19.4191H227.132V19.4293H227.117Z"
            fill="white"
          />
          <path
            d="M254.784 38.1216C246.572 38.1216 239.903 31.4523 239.903 23.2169C239.903 14.9815 246.561 8.31223 254.784 8.31223C263.006 8.31223 269.66 14.9815 269.66 23.2169C269.66 31.4523 263.001 38.1216 254.784 38.1216ZM254.784 0.276398C241.962 0.276398 231.568 10.6871 231.568 23.5342C231.568 36.3813 241.962 46.7921 254.784 46.7921C267.606 46.7921 278 36.3813 278 23.5342C278 10.6871 267.606 0.276398 254.784 0.276398Z"
            fill="#CBDB2A"
          />
          <path
            d="M36.4875 0.404351H33.8966C33.3549 0.404351 32.803 0.542546 32.803 1.49968V37.0979C28.3161 31.5086 6.39809 4.77543 2.45294 0.542546C2.03901 0.138196 1.63529 0 1.0936 0C0.413934 0 0 0.404351 0 0.957134V45.5585C0 46.3775 0.275956 46.6539 0.955625 46.6539H3.6743C4.49195 46.6539 4.62993 46.1113 4.62993 45.5585V10.2265C9.25985 15.8209 31.8627 43.0966 34.8573 46.6436C35.1333 47.048 35.537 47.3244 36.2167 47.3244C37.1723 47.3244 37.438 46.7818 37.438 46.229V1.63788C37.576 1.09533 37.576 0.414587 36.4824 0.414587"
            fill="white"
          />
          <path
            d="M81.9589 0.404358H79.644C78.6883 0.404358 78.5606 0.818945 78.5606 1.76584V23.9949H50.5153V1.77608C50.5153 0.957141 50.3773 0.414595 49.4217 0.414595H46.9687C46.0131 0.414595 45.7474 0.690986 45.7474 1.77608V45.5586C45.7474 46.5157 46.0233 46.7818 46.8359 46.7818H49.2888C50.1065 46.7818 50.3824 46.5054 50.3824 45.6916V28.366H78.4277V45.5534C78.4277 46.5106 78.8314 46.6488 79.5213 46.6488H81.8363C82.7919 46.6488 83.0576 46.5106 83.0576 45.5534V1.77608C83.0576 0.818945 82.9197 0.414595 81.9691 0.414595"
            fill="white"
          />
          <path
            d="M129.071 0.404357H126.214C125.672 0.404357 125.396 0.818944 125.126 1.36149L108.788 38.4594L91.4896 0.95714C91.2136 0.414593 90.8099 0.276398 90.2682 0.276398H87.4116C87.1356 0.276398 86.8699 0.276398 86.7319 0.552789C86.4559 0.829181 86.5939 1.23353 86.7319 1.50992C87.5495 3.28599 107.158 45.7019 107.561 46.5208C107.699 46.7972 107.965 47.611 108.783 47.611C109.462 47.611 109.738 47.2067 109.876 46.6539C110.28 45.8349 128.667 3.96162 129.485 2.19067C129.761 1.23353 129.888 0.967377 129.623 0.690985C129.761 0.414593 129.485 0.414593 129.081 0.414593"
            fill="white"
          />
          <path
            d="M138.331 4.63723H151.811C158.21 4.63723 162.977 9.00319 162.977 15.0019C162.977 21.0006 158.624 24.6859 151.541 24.6859H138.336V4.63723H138.331ZM169.912 44.8727C165.824 39.1452 159.431 30.2802 157.796 28.0998C163.923 25.9194 167.326 21.1439 167.326 15.007C167.326 6.54638 160.79 0.414581 151.944 0.414581H134.513C134.375 0.414581 133.695 0.552777 133.695 1.50991V45.7019C133.695 45.9782 133.695 46.7921 134.651 46.7921H137.508C138.325 46.7921 138.463 46.2495 138.463 45.7019V28.929H153.166L165.282 45.84C165.686 46.3826 166.1 46.5208 166.642 46.5208H169.365C169.769 46.5208 170.587 46.5208 170.587 45.7019C170.311 45.4255 170.183 45.1593 169.907 44.8829"
            fill="white"
          />
          <path
            d="M191.34 42.8407L197.62 36.5451C192.745 34.0985 189.393 29.0467 189.393 23.2118C189.393 14.9814 196.051 8.30709 204.269 8.30709C209.359 8.30709 213.85 10.8714 216.528 14.7767H225.773C222.318 6.26486 213.999 0.266144 204.264 0.266144C191.442 0.266144 181.053 10.6769 181.053 23.524C181.053 31.57 185.136 38.659 191.335 42.8356"
            fill="#CBDB2A"
          />
        </g>
        <path
          d="M7.61945 60.7192H4.38974V69.2156H3.22971V60.7192H0V59.7057H7.61945V60.7192Z"
          fill="white"
        />
        <path
          d="M15.0243 64.5579V69.2208H13.9562V64.6654C13.9562 63.5957 13.3685 63.1299 12.3822 63.1299C11.4879 63.1299 10.4863 63.8004 10.4863 65.3206V69.2208H9.41827V59.7057H10.4863V63.2732C10.9411 62.6334 11.6617 62.1625 12.5918 62.1625C14.1249 62.1625 15.0192 63.0327 15.0192 64.5528L15.0243 64.5579Z"
          fill="white"
        />
        <path
          d="M23.63 66.1446H18.4021C18.5095 67.6955 19.322 68.4018 20.5229 68.4018C21.499 68.4018 22.1122 67.8951 22.3524 67.1171H23.4613C23.1292 68.412 22.1429 69.3743 20.4974 69.3743C18.6168 69.3743 17.2932 67.9975 17.2932 65.7659C17.2932 63.5343 18.5606 62.1574 20.4667 62.1574C22.521 62.1574 23.63 63.7851 23.63 65.7659V66.1395V66.1446ZM18.4277 65.1824H22.4853C22.378 64.0461 21.6983 63.1401 20.4718 63.1401C19.3935 63.1401 18.6066 63.8209 18.4328 65.1824H18.4277Z"
          fill="white"
        />
        <path
          d="M35.0362 64.5579V69.2208H33.9681V64.6654C33.9681 63.5957 33.3804 63.1299 32.3941 63.1299C31.4998 63.1299 30.4982 63.8004 30.4982 65.3206V69.2208H29.4302V59.7057H30.4982V63.2732C30.953 62.6334 31.6736 62.1625 32.6037 62.1625C34.1368 62.1625 35.0311 63.0327 35.0311 64.5528L35.0362 64.5579Z"
          fill="white"
        />
        <path
          d="M43.6419 66.1446H38.4141C38.5214 67.6955 39.334 68.4018 40.5349 68.4018C41.5109 68.4018 42.1242 67.8951 42.3644 67.1171H43.4733C43.1411 68.412 42.1548 69.3743 40.5093 69.3743C38.6287 69.3743 37.3052 67.9975 37.3052 65.7659C37.3052 63.5343 38.5725 62.1574 40.4787 62.1574C42.533 62.1574 43.6419 63.7851 43.6419 65.7659V66.1395V66.1446ZM38.4397 65.1824H42.4972C42.3899 64.0461 41.7102 63.1401 40.4838 63.1401C39.4055 63.1401 38.6185 63.8209 38.4448 65.1824H38.4397Z"
          fill="white"
        />
        <path
          d="M51.8593 68.3506V69.2207C51.6855 69.2463 51.4709 69.2617 51.2716 69.2617C50.5101 69.2617 50.1626 68.929 50.1115 68.2738C49.6312 69.0109 48.8595 69.3845 47.8681 69.3845C46.3759 69.3845 45.4407 68.6628 45.4407 67.3679C45.4407 66.2879 46.0949 65.428 47.9908 65.2284L48.9771 65.1209C49.6721 65.039 50.0553 64.7882 50.0553 64.174C50.0553 63.4523 49.6823 63.0941 48.5069 63.0941C47.3315 63.0941 46.841 63.4268 46.7745 64.4555H45.7065C45.7882 63.1197 46.4935 62.1728 48.5069 62.1728C50.4131 62.1728 51.0927 63.0122 51.0927 64.1792V68.0282C51.0927 68.2431 51.2103 68.3609 51.4658 68.3609H51.8542L51.8593 68.3506ZM50.0604 66.4927V65.6788C49.8458 65.8375 49.5545 65.9348 49.1508 65.9706L48.1492 66.0883C46.8972 66.2367 46.5497 66.6616 46.5497 67.3321C46.5497 68.0026 46.9789 68.4683 47.9243 68.4683C48.9515 68.4683 50.0604 67.8541 50.0604 66.4927Z"
          fill="white"
        />
        <path
          d="M59.2539 62.3263L56.5454 69.2208H55.4263L52.7332 62.3263H53.8932L55.0277 65.3973C55.3496 66.2675 55.7073 67.199 56.0037 68.0691C56.295 67.199 56.6579 66.2521 56.9798 65.382L58.1143 62.3212H59.259L59.2539 62.3263Z"
          fill="white"
        />
        <path
          d="M66.6434 62.3263L63.7356 70.3007C63.2961 71.5189 62.5909 71.8772 61.4922 71.8772C61.2776 71.8772 61.0783 71.8618 60.8534 71.8362V70.9405H61.482C62.1616 70.9405 62.5245 70.6334 62.734 70.0857C62.8158 69.8861 62.8924 69.6558 62.9742 69.446L60.3884 62.3263H61.5637L62.5398 65.1977C62.8873 66.2009 63.2092 67.1888 63.5261 68.1766C63.8327 67.1888 64.1649 66.1856 64.5124 65.1824L65.4884 62.3212H66.6485L66.6434 62.3263Z"
          fill="white"
        />
        <path
          d="M77.84 62.3263L75.1316 69.2208H74.0124L71.3193 62.3263H72.4793L73.6138 65.3973C73.9357 66.2675 74.2935 67.199 74.5899 68.0691C74.8812 67.199 75.244 66.2521 75.5659 65.382L76.7004 62.3212H77.8451L77.84 62.3263Z"
          fill="white"
        />
        <path
          d="M85.3113 66.1446H80.0834C80.1907 67.6955 81.0033 68.4018 82.2042 68.4018C83.1803 68.4018 83.7935 67.8951 84.0337 67.1171H85.1426C84.8104 68.412 83.8242 69.3743 82.1786 69.3743C80.2981 69.3743 78.9745 67.9975 78.9745 65.7659C78.9745 63.5343 80.2418 62.1574 82.148 62.1574C84.2023 62.1574 85.3113 63.7851 85.3113 65.7659V66.1395V66.1446ZM80.109 65.1824H84.1665C84.0592 64.0461 83.3796 63.1401 82.1531 63.1401C81.0748 63.1401 80.2878 63.8209 80.1141 65.1824H80.109Z"
          fill="white"
        />
        <path
          d="M93.1811 64.5579V69.2208H92.1131V64.6654C92.1131 63.5957 91.5254 63.1299 90.5391 63.1299C89.6448 63.1299 88.6432 63.8004 88.6432 65.3206V69.2208H87.5751V59.7057H88.6432V63.2732C89.098 62.6334 89.8186 62.1625 90.7486 62.1625C92.2817 62.1625 93.176 63.0327 93.176 64.5528L93.1811 64.5579Z"
          fill="white"
        />
        <path
          d="M95.7465 59.9719H96.8809V61.2822H95.7465V59.9719ZM95.7873 62.3263H96.8554V69.2207H95.7873V62.3263Z"
          fill="white"
        />
        <path
          d="M99.1193 65.771C99.1193 63.5547 100.402 62.1625 102.308 62.1625C103.948 62.1625 105.057 63.1401 105.272 64.6603H104.163C103.974 63.6161 103.177 63.1248 102.308 63.1248C101.133 63.1248 100.238 63.9642 100.238 65.771C100.238 67.5777 101.158 68.4171 102.308 68.4171C103.187 68.4171 103.989 67.9104 104.173 66.856H105.267C105.042 68.4325 103.851 69.3794 102.303 69.3794C100.422 69.3794 99.1141 67.9923 99.1141 65.771H99.1193Z"
          fill="white"
        />
        <path
          d="M107.408 59.7057H108.476V69.2208H107.408V59.7057Z"
          fill="white"
        />
        <path
          d="M117.077 66.1446H111.849C111.956 67.6955 112.769 68.4018 113.97 68.4018C114.946 68.4018 115.559 67.8951 115.799 67.1171H116.908C116.576 68.412 115.59 69.3743 113.944 69.3743C112.064 69.3743 110.74 67.9975 110.74 65.7659C110.74 63.5343 112.007 62.1574 113.914 62.1574C115.968 62.1574 117.077 63.7851 117.077 65.7659V66.1395V66.1446ZM111.875 65.1824H115.932C115.825 64.0461 115.145 63.1401 113.919 63.1401C112.84 63.1401 112.053 63.8209 111.88 65.1824H111.875Z"
          fill="white"
        />
        <path
          d="M128.488 64.5579V69.2208H127.42V64.6654C127.42 63.5957 126.832 63.1299 125.846 63.1299C124.952 63.1299 123.95 63.8004 123.95 65.3206V69.2208H122.882V59.7057H123.95V63.2732C124.405 62.6334 125.126 62.1625 126.056 62.1625C127.589 62.1625 128.483 63.0327 128.483 64.5528L128.488 64.5579Z"
          fill="white"
        />
        <path
          d="M136.424 62.3263V69.2207H135.413V68.1919C134.958 68.888 134.212 69.3794 133.251 69.3794C131.718 69.3794 130.824 68.5093 130.824 66.9891V62.3263H131.892V66.8816C131.892 67.9514 132.479 68.4171 133.466 68.4171C134.36 68.4171 135.361 67.7466 135.361 66.2265V62.3263H136.43H136.424Z"
          fill="white"
        />
        <path
          d="M145.603 65.771C145.603 67.977 144.335 69.3794 142.398 69.3794C141.371 69.3794 140.559 68.9392 140.037 68.151V69.2208H139.026V59.7057H140.094V63.3142C140.615 62.5771 141.402 62.1676 142.403 62.1676C144.335 62.1676 145.608 63.5701 145.608 65.7761L145.603 65.771ZM144.483 65.7198C144.483 64.0512 143.604 63.1299 142.271 63.1299C140.937 63.1299 140.058 64.1075 140.058 65.7761C140.058 67.4447 140.937 68.4223 142.271 68.4223C143.604 68.4223 144.483 67.3935 144.483 65.7249V65.7198Z"
          fill="white"
        />
        <path
          d="M157.653 59.7057V69.2207H156.641V68.151C156.119 68.9392 155.307 69.3794 154.28 69.3794C152.348 69.3794 151.076 67.977 151.076 65.771C151.076 63.565 152.343 62.1625 154.28 62.1625C155.281 62.1625 156.068 62.5771 156.59 63.309V59.7006H157.658L157.653 59.7057ZM156.625 65.771C156.625 64.1024 155.746 63.1248 154.413 63.1248C153.079 63.1248 152.2 64.1536 152.2 65.8222C152.2 67.4907 153.079 68.412 154.413 68.412C155.746 68.412 156.625 67.4344 156.625 65.7659V65.771Z"
          fill="white"
        />
        <path
          d="M163.846 62.229V63.2578H163.581C162.206 63.2578 161.327 63.9539 161.327 65.2898V69.2156H160.259V62.3212H161.271V63.5393C161.659 62.8177 162.364 62.2188 163.483 62.2188C163.591 62.2188 163.698 62.2188 163.841 62.2342L163.846 62.229Z"
          fill="white"
        />
        <path
          d="M165.809 59.9719H166.943V61.2822H165.809V59.9719ZM165.844 62.3263H166.912V69.2207H165.844V62.3263Z"
          fill="white"
        />
        <path
          d="M175.237 62.3263L172.529 69.2208H171.41L168.716 62.3263H169.876L171.011 65.3973C171.333 66.2675 171.691 67.199 171.987 68.0691C172.278 67.199 172.641 66.2521 172.963 65.382L174.098 62.3212H175.242L175.237 62.3263Z"
          fill="white"
        />
        <path
          d="M177 59.9719H178.135V61.2822H177V59.9719ZM177.041 62.3263H178.109V69.2207H177.041V62.3263Z"
          fill="white"
        />
        <path
          d="M186.311 64.5579V69.2207H185.243V64.6654C185.243 63.5957 184.655 63.1299 183.669 63.1299C182.775 63.1299 181.773 63.8004 181.773 65.3205V69.2207H180.705V62.3263H181.717V63.3551C182.172 62.659 182.918 62.1676 183.879 62.1676C185.412 62.1676 186.306 63.0378 186.306 64.5579H186.311Z"
          fill="white"
        />
        <path
          d="M195.157 62.3263V68.9546C195.157 70.746 194.13 72 191.953 72C190.19 72 189.163 71.1196 189.02 69.7837H190.088C190.22 70.6539 190.875 71.094 191.953 71.094C193.328 71.094 194.089 70.3877 194.089 68.9546V68.2841C193.568 68.9802 192.77 69.3794 191.779 69.3794C189.842 69.3794 188.575 67.977 188.575 65.771C188.575 63.5649 189.842 62.1625 191.779 62.1625C192.806 62.1625 193.619 62.5925 194.14 63.3244V62.3212H195.152L195.157 62.3263ZM194.13 65.771C194.13 64.1024 193.251 63.1248 191.917 63.1248C190.583 63.1248 189.704 64.1536 189.704 65.771C189.704 67.3884 190.583 68.4171 191.917 68.4171C193.251 68.4171 194.13 67.4395 194.13 65.771Z"
          fill="white"
        />
        <path
          d="M206.819 62.3263L203.911 70.3007C203.471 71.5189 202.766 71.8772 201.668 71.8772C201.453 71.8772 201.254 71.8618 201.029 71.8362V70.9405H201.657C202.337 70.9405 202.7 70.6334 202.914 70.0857C202.996 69.8861 203.073 69.6558 203.155 69.446L200.569 62.3263H201.744L202.72 65.1977C203.068 66.2009 203.39 67.1888 203.707 68.1766C204.013 67.1888 204.345 66.1856 204.693 65.1824L205.669 62.3212H206.829L206.819 62.3263Z"
          fill="white"
        />
        <path
          d="M207.953 65.771C207.953 63.5649 209.353 62.1625 211.29 62.1625C213.227 62.1625 214.622 63.5649 214.622 65.771C214.622 67.977 213.222 69.3794 211.29 69.3794C209.359 69.3794 207.953 67.977 207.953 65.771ZM213.503 65.771C213.503 64.1024 212.624 63.1248 211.29 63.1248C209.956 63.1248 209.077 64.1024 209.077 65.771C209.077 67.4395 209.956 68.4171 211.29 68.4171C212.624 68.4171 213.503 67.4395 213.503 65.771Z"
          fill="white"
        />
        <path
          d="M222.298 62.3263V69.2207H221.286V68.1919C220.831 68.888 220.085 69.3794 219.124 69.3794C217.591 69.3794 216.697 68.5093 216.697 66.9891V62.3263H217.765V66.8816C217.765 67.9514 218.353 68.4171 219.339 68.4171C220.233 68.4171 221.235 67.7466 221.235 66.2265V62.3263H222.303H222.298Z"
          fill="white"
        />
        <path
          d="M230.035 61.5893V62.3263H231.501V63.263H230.035V69.2207H228.967V63.263H227.832V62.3263H228.967V61.62C228.967 60.4018 229.529 59.6801 230.888 59.6801C231.087 59.6801 231.409 59.6955 231.568 59.7057V60.6168H230.955C230.341 60.6168 230.035 60.9648 230.035 61.5944V61.5893Z"
          fill="white"
        />
        <path
          d="M232.84 65.771C232.84 63.5649 234.241 62.1625 236.172 62.1625C238.104 62.1625 239.509 63.5649 239.509 65.771C239.509 67.977 238.109 69.3794 236.172 69.3794C234.235 69.3794 232.84 67.977 232.84 65.771ZM238.385 65.771C238.385 64.1024 237.506 63.1248 236.172 63.1248C234.838 63.1248 233.959 64.1024 233.959 65.771C233.959 67.4395 234.838 68.4171 236.172 68.4171C237.506 68.4171 238.385 67.4395 238.385 65.771Z"
          fill="white"
        />
        <path
          d="M245.366 62.229V63.2578H245.1C243.725 63.2578 242.846 63.9539 242.846 65.2898V69.2156H241.778V62.3212H242.79V63.5393C243.178 62.8177 243.884 62.2188 245.003 62.2188C245.11 62.2188 245.217 62.2188 245.361 62.2342L245.366 62.229Z"
          fill="white"
        />
        <path
          d="M257.078 62.3263L254.784 69.2207H253.665L252.653 66.0269C252.413 65.2642 252.173 64.4658 251.958 63.6878C251.733 64.4607 251.493 65.2642 251.253 66.0269L250.251 69.2207H249.132L246.837 62.3263H247.982L248.958 65.4383C249.224 66.2777 249.464 67.1068 249.704 67.9514C249.929 67.112 250.169 66.2828 250.44 65.4383L251.401 62.3263H252.52L253.481 65.4383C253.736 66.2674 253.987 67.0966 254.227 67.9258C254.452 67.0966 254.707 66.2674 254.963 65.4383L255.939 62.3263H257.089H257.078Z"
          fill="white"
        />
        <path
          d="M264.631 68.3506V69.2207C264.458 69.2463 264.243 69.2617 264.044 69.2617C263.282 69.2617 262.935 68.929 262.884 68.2738C262.403 69.0109 261.632 69.3845 260.64 69.3845C259.148 69.3845 258.213 68.6628 258.213 67.3679C258.213 66.2879 258.867 65.428 260.763 65.2284L261.749 65.1209C262.444 65.039 262.833 64.7882 262.833 64.174C262.833 63.4523 262.46 63.0941 261.284 63.0941C260.109 63.0941 259.618 63.4268 259.552 64.4555H258.484C258.566 63.1197 259.271 62.1728 261.284 62.1728C263.19 62.1728 263.87 63.0122 263.87 64.1792V68.0282C263.87 68.2431 263.988 68.3609 264.243 68.3609H264.631V68.3506ZM262.833 66.4927V65.6788C262.618 65.8375 262.327 65.9348 261.923 65.9706L260.921 66.0883C259.664 66.2367 259.322 66.6616 259.322 67.3321C259.322 68.0026 259.751 68.4683 260.697 68.4683C261.724 68.4683 262.833 67.8541 262.833 66.4927Z"
          fill="white"
        />
        <path
          d="M270.156 62.229V63.2578H269.89C268.515 63.2578 267.636 63.9539 267.636 65.2898V69.2156H266.568V62.3212H267.58V63.5393C267.968 62.8177 268.674 62.2188 269.793 62.2188C269.9 62.2188 270.008 62.2188 270.156 62.2342V62.229Z"
          fill="white"
        />
        <path
          d="M278 59.7057V69.2207H276.988V68.151C276.467 68.9392 275.654 69.3794 274.627 69.3794C272.69 69.3794 271.423 67.977 271.423 65.771C271.423 63.565 272.69 62.1625 274.627 62.1625C275.629 62.1625 276.416 62.5771 276.937 63.309V59.7006H278.005L278 59.7057ZM276.973 65.771C276.973 64.1024 276.094 63.1248 274.76 63.1248C273.426 63.1248 272.547 64.1536 272.547 65.8222C272.547 67.4907 273.426 68.412 274.76 68.412C276.094 68.412 276.973 67.4344 276.973 65.7659V65.771Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_725_292">
          <rect width="278" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
