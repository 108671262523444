export function getAccreditationSchemeCode(
  accreditationSchemeId?: number | null,
): string | undefined {
  if (accreditationSchemeId === 1) {
    return 'NHVAS';
  }
  if (accreditationSchemeId === 2) {
    return 'WAHVA';
  }
  if (accreditationSchemeId === 3) {
    return 'DIGNHVAS';
  }
  if (accreditationSchemeId === 4) {
    return 'DIGWAHVA';
  }
  return '';
}
