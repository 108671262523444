import React from 'react';

import {AuditRequest} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AuditRequestProviderNotificationCardView} from './AuditRequestProviderNotificationCard.View';
import {useAuditRequestProviderNotificationCard} from './useAuditRequestProviderNotificationCard';

export interface DSP_AuditRequestProviderNotificationCardProps {
  notification: NotificationItem<AuditRequest>;
}

export const DSP_AuditRequestProviderNotificationCard = (
  props: DSP_AuditRequestProviderNotificationCardProps,
) => {
  const viewProps = useAuditRequestProviderNotificationCard(props);

  return <DSP_AuditRequestProviderNotificationCardView {...viewProps} />;
};

DSP_AuditRequestProviderNotificationCard.displayName =
  'DSP_AuditRequestProviderNotificationCard';
