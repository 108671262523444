import React, {ReactNode} from 'react';

import {DSP_BaseAuditRequestNotificationCardView} from './BaseAuditRequestNotificationCard.View';
import {
  useBaseAuditRequestNotificationCard,
  DSP_UseBaseAuditRequestNotificationCardProps,
} from './useBaseAuditRequestNotificationCard';

export interface DSP_BaseAuditRequestNotificationCardProps
  extends DSP_UseBaseAuditRequestNotificationCardProps {
  avatar: ReactNode | undefined;
}

export const DSP_BaseAuditRequestNotificationCard = ({
  avatar,
  ...props
}: DSP_BaseAuditRequestNotificationCardProps) => {
  const viewProps = useBaseAuditRequestNotificationCard(props);

  return (
    <DSP_BaseAuditRequestNotificationCardView avatar={avatar} {...viewProps} />
  );
};

DSP_BaseAuditRequestNotificationCard.displayName =
  'DSP_BaseAuditRequestNotificationCard';
