import React, {ForwardedRef} from 'react';

import {DSL_NotificationsNoneOutlined} from 'components/DesignSystem/Library/Icons';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_Badge, DSL_BadgeProps} from '../Badge';

export type DSL_NotificationsBellProps = Omit<
  DSL_BadgeProps,
  DSL_RestrictedVisualProps | 'color' | 'children'
>;

export const DSL_NotificationsBell = React.forwardRef(
  (props: DSL_NotificationsBellProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <DSL_Badge {...props} ref={ref}>
        <DSL_NotificationsNoneOutlined />
      </DSL_Badge>
    );
  },
);

DSL_NotificationsBell.displayName = 'DSL_NotificationsBell';

export {
  //
  /** @deprecated use DSL_NotificationsBellProps instead*/
  DSL_NotificationsBellProps as NotificationsBellProps, //
  /** @deprecated use DSL_NotificationsBell instead*/
  DSL_NotificationsBell as NotificationsBell,
};
