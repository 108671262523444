import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/audit-requests/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AuditRequestDetails" */ './AuditRequestDetails'
      ),
  ),
  visibleInMenu: false,
  menuTitle: 'Audit Request',
  menuIcon: Today,
  hideInProduction: false,
  authorisation: {
    apiKey: 'AuditRequest.updateById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'providerAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
