import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import UpdateAccreditationDriverMapping from './AccreditationDriverMapping';
import {DataUsage} from '@mui/icons-material';

const AccreditationDriverMappings = {
  path: '/drivers',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationDriverMappings" */ './AccreditationDriverMappings'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Drivers',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'accreditationDriverMappingWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};

export default AccreditationDriverMappings;

export const AccreditationDriverMappingRoutes = [
  AccreditationDriverMappings,
  UpdateAccreditationDriverMapping,
];
