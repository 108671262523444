import {Breakpoint} from '@mui/material';
import {RoadSegmentsLengthProps} from 'containers-spatial/NetworkManagement/drawers/SelectedSegmentsDrawer/RoadSegmentsLength/RoadSegmentsLength.Connector';

import {SegmentAccessType} from '../../Layout/Segments/constants';
import {DSP_SegmentCondition} from '../../Layout/Segments/SegmentConditionsPanel/types';

export interface SegmentChangeOption {
  label: string;
  subLabel?: string;
  onClick?: () => void;
  options?: SegmentChangeOption[];
  href?: string;
  to?: string;
  value?: SegmentAccessType;
  radioColor?: React.CSSProperties['color'];
  isClearUpdates?: boolean;
  isRevertUpdate?: boolean;
  noChangesAllowed?: boolean;
  hasConflict?: boolean;
  actions?: {
    label: string;
    onClick: () => void;
  }[];
}

export type DSP_FilterByAccessType = 'All' | SegmentAccessType;

export enum DSP_FilterByRoadsOrAccessType {
  ROADS = 'roads',
  ACCESS_TYPE = 'accessType',
}

export interface DSP_Segment {
  id: number;
  conditions: DSP_SegmentCondition[];
  conditionCount: number;
  accessType: SegmentAccessType;
  currentAccessType?: SegmentAccessType;
  lastAppliedAccessType?: SegmentAccessType;
  isUnmanaged: boolean;
  isCoManaged?: boolean;
}

export interface DSP_RoadsByAccessType {
  segmentAccessType: SegmentAccessType;
  withIcon?: boolean;
  conditionName?: string;
  totalRoads?: number;
}

export interface DSP_Road {
  id: number;
  name: string;
  suburb: string;
  segmentIds: number[];
  segments?: DSP_Segment[];
}

export interface DSP_SelectedSegmentsDrawerContext {
  isMinimized?: boolean;
  toggleMinimizeDrawer: () => void;
  showSingleListView: boolean;
  filterByRoadsOrAccessTypeValue: DSP_FilterByRoadsOrAccessType;
  filterByAccessTypeValue: DSP_FilterByAccessType | undefined;
  hasSelectedRoadId: boolean;
  currentBreakpoint: Breakpoint;
  isViewportSmall: boolean;
  filteredRoads: DSP_Road[];
  isViewByRoads: boolean;
  hasFilterByAccessType: boolean;
  roads: DSP_Road[];
  selectedRoadId: number | undefined;
  activeSegmentId?: number;
  getSegmentsByIds: (segmentIds?: number[]) => DSP_Segment[];
  selectSegment: (id: number) => void;
  setSelectedRoadId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setFilterByAccessTypeValue: React.Dispatch<
    React.SetStateAction<DSP_FilterByAccessType | undefined>
  >;
  setFilterByRoadsOrAccessTypeValue: React.Dispatch<
    React.SetStateAction<DSP_FilterByRoadsOrAccessType>
  >;
  RenderRoadSegmentsLengthComponent?: React.ComponentType<RoadSegmentsLengthProps>;
  onSegmentHover: (segmentIds: number[]) => void;
  onSegmentHoverOut: () => void;
  resetToDefaults: () => void;
  onBack: () => void;
  onChange: (segmentId: number) => void;
  options: SegmentChangeOption[];
}
