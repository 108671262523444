import RouteOutlined from '@mui/icons-material/RouteOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/routePlannerTool',
  component: lazy(
    () =>
      import(/* webpackChunkName: "RoutePlannerTool" */ './RoutePlannerTool'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Route Planner Tool',
  menuDescription: 'Next Generation Route Planner tool',
  menuIcon: RouteOutlined,
  authorisation: {
    domain: undefined,
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
