import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_TurnSharpright = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="M18.5 19.657c0-5.791 7.002-8.691 11.096-4.597l8.6 8.601v-4.866h5v13.107H30.09v-5h4.277l-8.306-8.306c-.945-.945-2.56-.276-2.56 1.06V45.5h-5z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_TurnSharpright);
