import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/afmApplications',
  component: lazy(
    () => import(/* webpackChunkName: "AfmApplications" */ './AfmApplications'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'AFM applications',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AfmApplicationWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead', idVerified: true},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
