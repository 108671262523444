import {PeopleOutlined} from '@mui/icons-material';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/employees/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationEmployeeMappingDetails" */ './AccreditationEmployeeMapping'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Accreditation Employee',
  menuIcon: PeopleOutlined,
  authorisation: {
    apiKey: 'AccreditationEmployeeMappingWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation Employees',
      to: '..',
    },
  ],
};
