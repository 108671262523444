import {
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';

import {useFormContext} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {DSP_FieldProps} from '../types';
import {
  DSP_GetGenericFieldProps,
  DSP_GenericFieldProps,
  getGenericFieldProps,
} from '../utils/getGenericFieldProps';

export interface DSP_UseRichTextFieldArgs
  extends DSP_FieldProps,
    Omit<DSP_GetGenericFieldProps, 'state'> {
  span?: number;
  variant?: 'basic' | 'standard' | 'full';
  enableFieldTags?: boolean;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  error?: boolean;
  helperText?: string;
}

export interface DSP_RichTextFieldProps
  extends Omit<DSP_GenericFieldProps, 'label' | 'helperText' | 'error'> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  value: string;
  span?: number;
}

export const useRichTextField = ({
  fieldRef,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  includeOptionalSuffix,
  label,
  useLabelForValidationErrors,
  ...propsOverrides
}: DSP_UseRichTextFieldArgs): DSP_RichTextFieldProps => {
  const {service} = useFormContext();
  const send = service.send;

  const stateDerivedProps = useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const {value, ...genericFieldProps} = getGenericFieldProps({
        label,
        fieldRef,
        state,
        hideLabel,
        includeOptionalSuffix,
        useLabelForValidationErrors,
      });
      const fieldConfig = schemaJsonPointerResolver(fieldRef)(
        state.context.schema,
      ) as JSONSchemaRecord;

      return {
        ...genericFieldProps,
        value: (value as string) || ('' as string),
        maxLength: fieldConfig?.maxLength,
        onChange: (event: React.ChangeEvent<HTMLInputElement>): void => {
          send({
            type: 'CHANGE',
            fieldRef,
            value: event?.currentTarget?.value ?? '',
          });
          onChangeEvent?.();
        },
        onBlur: (): void => {
          send({type: 'BLUR', fieldRef});
          onBlurEvent?.();
        },
      };
    },
  );
  return {
    ...stateDerivedProps,
    ...propsOverrides,
  };
};
