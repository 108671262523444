import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import UpdateAccreditationEmployeeMapping from './AccreditationEmployeeMapping';
import {PeopleOutlined} from '@mui/icons-material';

const AccreditationEmployeeMappings = {
  path: '/employees',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationEmployeeMappings" */ './AccreditationEmployeeMappings'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Employees',
  menuIcon: PeopleOutlined,
  authorisation: {
    apiKey: 'accreditationEmployeeMappingWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};

export default AccreditationEmployeeMappings;

export const AccreditationEmployeeMappingRoutes = [
  AccreditationEmployeeMappings,
  UpdateAccreditationEmployeeMapping,
];
