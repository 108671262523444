import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_UturnLeft = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();

  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="M23.5 24.77c0-4.628 3.641-8.27 8-8.27s8 3.642 8 8.27V49h5V24.77c0-7.27-5.761-13.27-13-13.27s-13 6-13 13.27v5.194l-3.232-3.232-3.536 3.535L21 39.535l9.268-9.268-3.536-3.535-3.232 3.232z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_UturnLeft);
