import React from 'react';

import {
  DSL_BaseIconButton,
  DSL_FlexRow,
  DSL_HelpIcon,
  DSL_Tooltip,
  DSL_TooltipProps,
} from 'components/DesignSystem/Library';

export interface DSP_HelpToolTipProps
  extends Omit<DSL_TooltipProps, 'title' | 'onClick' | 'children'> {
  open?: boolean;
}

export const DSP_HelpToolTip = ({
  open,
  ...tooltipProps
}: DSP_HelpToolTipProps) => {
  return (
    <DSL_Tooltip title={open ? '' : 'Help'} {...tooltipProps}>
      <DSL_FlexRow alignItems={'center'} cursor={'pointer'}>
        <DSL_BaseIconButton
          id="help-button"
          aria-controls={open ? 'help-list' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label="Toggle help menu"
        >
          <DSL_HelpIcon />
        </DSL_BaseIconButton>
      </DSL_FlexRow>
    </DSL_Tooltip>
  );
};

export {
  //
  /** @deprecated use DSP_HelpToolTipProps instead*/
  DSP_HelpToolTipProps as HelpToolTipProps, //
  /** @deprecated use DSP_HelpToolTip instead*/
  DSP_HelpToolTip as HelpToolTip,
};
