import {ApolloProvider} from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider} from '@mui/material/styles';
import React, {FC} from 'react';
import {client} from './apollo/clients';
import {Router} from './router';
import AppServicesProvider from './services/AppServicesProvider';
import {ThemeSelectionProvider} from './theming/ThemeSelectionProvider';
import {ThemeProvider} from './theming/ThemeProvider';
import {themes} from './theming/themes';
import {
  DSP_LocalizationProvider,
  DSP_ErrorBoundary,
} from 'components/DesignSystem/Portal';
import {DSL_PubSubProvider} from '../components/DesignSystem/Library';
import {i18n} from 'containers-content';
import {PortraitLock} from './PortraitLock/PortraitLock';

const App: FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeSelectionProvider
        availableThemes={themes}
        defaultTheme={i18n.get('GLOBAL_BRAND_THEME') as keyof typeof themes}
      >
        <ThemeProvider>
          <CssBaseline />
          <DSP_ErrorBoundary>
            <ApolloProvider client={client}>
              <AppServicesProvider>
                <DSP_LocalizationProvider>
                  <DSL_PubSubProvider>
                    <PortraitLock>
                      <Router />
                    </PortraitLock>
                  </DSL_PubSubProvider>
                </DSP_LocalizationProvider>
              </AppServicesProvider>
            </ApolloProvider>
          </DSP_ErrorBoundary>
        </ThemeProvider>
      </ThemeSelectionProvider>
    </StyledEngineProvider>
  );
};

export default App;
