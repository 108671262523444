import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';
import {AuditRequestWorkflowState} from 'containers-accreditation/Shared/types';

import {DSP_NotificationRendererComponent} from '../../types';

import {DSP_AuditRequestCustomerNotificationCard} from './Customer/AuditRequestCustomerNotificationCard.Connector';
import {DSP_AuditRequestProviderNotificationCard} from './Provider/AuditRequestProviderNotificationCard.Connector';
import {DSP_AuditRequestNotificationItem} from './types';

export const auditRequestWorkflowStateDescriptionMap: {
  [DSP_ApplicationState in AuditRequestWorkflowState]?: string | undefined;
} = {
  [AuditRequestWorkflowState.SUBMITTED_STATE]: 'created the audit request',
  [AuditRequestWorkflowState.APPROVED_STATE]: 'approved the audit request',
  [AuditRequestWorkflowState.DECLINED_STATE]: 'declined the audit request',
  [AuditRequestWorkflowState.WITHDRAWN_STATE]: 'withdrew the audit request',
};

export const AuditRequestAccountTypeNotificationRendererMap: {
  [DSP_Type in AuthorisationAccountTypes]?:
    | DSP_NotificationRendererComponent<DSP_AuditRequestNotificationItem>
    | undefined;
} = {
  [AuthorisationAccountTypes.CUSTOMER]:
    DSP_AuditRequestCustomerNotificationCard,
  [AuthorisationAccountTypes.PROVIDER]:
    DSP_AuditRequestProviderNotificationCard,
};
