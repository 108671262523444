import {DSP_AuditRequestNotificationItem} from '../types';

import {DSP_AuditRequestProviderNotificationCardViewProps} from './AuditRequestProviderNotificationCard.View';

export interface DSP_UseAuditRequestNotificationCardProps {
  notification: DSP_AuditRequestNotificationItem;
}

export function useAuditRequestProviderNotificationCard({
  notification,
}: DSP_UseAuditRequestNotificationCardProps): DSP_AuditRequestProviderNotificationCardViewProps {
  return {
    notification,
    auditRequestId: notification.data.id,
  };
}
