import React from 'react';
import {
  DSL_Heading2,
  DSL_Body1,
  DSL_FlexColumn,
} from 'components/DesignSystem/Library';
import {DSP_PageContainer} from 'components/DesignSystem/Portal';
import {IdVerify} from 'containers-accounts/Profile/ProfileDetails/IdVerify/IdVerify';

export const NonVerified = () => {
  return (
    <DSP_PageContainer hideBreadcrumbs>
      <DSL_FlexColumn gap={4}>
        <DSL_Heading2>Verify your identity</DSL_Heading2>
        <DSL_Body1 gutterBottom>
          The National Heavy Vehicle Regulator (NHVR) is committed to protecting
          your personal information and complying with all applicable privacy
          laws. <br />
          <br />
          As part of this commitment the NHVR is introducing identity
          verification for new services which provide access to information
          managed by other Government Agencies.
          <br />
          <br />
          Please click on the &apos;Verify with Digital ID&apos; button below.
          This will open up a popup window taking you to Australia Post&apos;s
          secure identity verification service. After completing the process you
          will be redirected to this page.
        </DSL_Body1>
        <IdVerify />
      </DSL_FlexColumn>
    </DSP_PageContainer>
  );
};
