import {ContentContainer} from './ContentContainer';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {ContentKeysGlobal, globalContent} from './globalContent';
import {ContentKeysContainerHome} from '../containers/Home/content';
import {getDomain} from '../utils/global';

export * from './globalContent';
export * from './components';
export * from './types';
export * from './hooks';

export type ContentKeys = ContentKeysGlobal | ContentKeysContainerHome;

export const i18n = new ContentContainer<AuthorisationDomains, ContentKeys>({
  initialDomain: getDomain(),
  initialContent: globalContent,
  defaultDomain: AuthorisationDomains.NHVR,
});
