import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';

import {
  DSP_CustomerAccountEntityAvatar,
  DSP_CustomerAccountEntityAvatarWithName,
  DSP_CustomerAccountEntityLoaderProvider,
  DSP_CustomerAccountEntityName,
} from './AccountEntity/CustomerEntity';
import {
  DSP_PartnerAccountEntityAvatar,
  DSP_PartnerAccountEntityAvatarWithName,
  DSP_PartnerAccountEntityLoaderProvider,
  DSP_PartnerAccountEntityName,
} from './AccountEntity/PartnerEntity';
import {
  DSP_RegulatorAccountEntityAvatar,
  DSP_RegulatorAccountEntityAvatarWithName,
  DSP_RegulatorAccountEntityLoaderProvider,
  DSP_RegulatorAccountEntityName,
} from './AccountEntity/RegulatorEntity';
import {EntityComponents} from './types';

export const accountTypeEntityComponentMap: {
  [AccountType in AuthorisationAccountTypes]?: EntityComponents;
} = {
  [AuthorisationAccountTypes.PARTNER]: {
    LoaderProvider: DSP_PartnerAccountEntityLoaderProvider,
    Avatar: DSP_PartnerAccountEntityAvatar,
    AvatarWithName: DSP_PartnerAccountEntityAvatarWithName,
    Name: DSP_PartnerAccountEntityName,
  },
  [AuthorisationAccountTypes.OPERATIONS]: {
    LoaderProvider: DSP_RegulatorAccountEntityLoaderProvider,
    Avatar: DSP_RegulatorAccountEntityAvatar,
    AvatarWithName: DSP_RegulatorAccountEntityAvatarWithName,
    Name: DSP_RegulatorAccountEntityName,
  },
  [AuthorisationAccountTypes.CUSTOMER]: {
    LoaderProvider: DSP_CustomerAccountEntityLoaderProvider,
    Avatar: DSP_CustomerAccountEntityAvatar,
    AvatarWithName: DSP_CustomerAccountEntityAvatarWithName,
    Name: DSP_CustomerAccountEntityName,
  },
};
