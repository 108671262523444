import React from 'react';

import {
  DSP_UserEntityAvatarWithName,
  DSP_UserEntityLoaderProvider,
} from 'components/DesignSystem/Portal/Entity';

import {DSP_RecordAssignmentNotificationCardView} from './RecordAssignmentNotificationCard.View';
import {
  useRecordAssignmentNotificationCard,
  DSP_UseRecordAssignmentNotificationCardProps,
} from './useRecordAssignmentNotificationCard';

export interface DSP_RecordAssignmentNotificationCardProps
  extends DSP_UseRecordAssignmentNotificationCardProps {}

export const DSP_RecordAssignmentNotificationCard = (
  props: DSP_RecordAssignmentNotificationCardProps,
) => {
  const viewProps = useRecordAssignmentNotificationCard(props);
  const {
    notification: {fromUserId},
  } = props;
  return (
    <DSP_RecordAssignmentNotificationCardView
      {...viewProps}
      avatar={
        <DSP_UserEntityLoaderProvider entityId={fromUserId as number}>
          <DSP_UserEntityAvatarWithName />
        </DSP_UserEntityLoaderProvider>
      }
    />
  );
};

DSP_RecordAssignmentNotificationCard.displayName =
  'DSP_RecordAssignmentNotificationCard';
