import React, {ReactNode, Ref} from 'react';

import {
  DSL_Avatar,
  DSL_Body1,
  DSL_Box,
  DSL_EntityTitle,
  DSL_FlexRow,
} from 'components/DesignSystem/Library';

export interface DSP_EntityAvatarWithNameProps {
  name: ReactNode;
  isLoading: boolean;
  anchorRef: Ref<HTMLDivElement>;
  openPopover: () => void;
  closePopover: () => void;
  avatarTitle: string;
  avatarUrl?: string;
  avatarInitials?: ReactNode;
  popover: ReactNode;
  showTitle?: boolean;
}

export const DSP_EntityAvatarWithName = ({
  name,
  isLoading,
  anchorRef,
  popover,
  openPopover,
  closePopover,
  avatarUrl,
  avatarTitle,
  avatarInitials,
  showTitle = true,
}: DSP_EntityAvatarWithNameProps) => {
  return (
    <DSL_FlexRow
      ref={anchorRef}
      alignItems={'center'}
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
      flexWrap={'nowrap'}
      width={'fit-content'}
      gap={1}
    >
      <DSL_Box
        ref={anchorRef}
        width={'fit-content'}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      >
        {popover}
        <DSL_Avatar src={avatarUrl} alt={avatarTitle}>
          {avatarInitials}
        </DSL_Avatar>
      </DSL_Box>
      {showTitle && name && (
        <DSL_EntityTitle isLoading={isLoading}>
          <DSL_Body1 color={'primary'}>{name}</DSL_Body1>
        </DSL_EntityTitle>
      )}
    </DSL_FlexRow>
  );
};

DSP_EntityAvatarWithName.displayName = 'DSP_EntityAvatarWithName';
