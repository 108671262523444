export enum VehicleNetworkMapStorageKeys {
  NATIONAL_MAPS_TERMS_ACCEPTED = 'natMap_TermsAccepted',
  STORED_NETWORK_VIEWS = 'natMap_NetworkViews',
  RECENTLY_VIEWED_NETWORKS = 'natMap_RecentlyViewed',
  FAVOURITE_VIEWS = 'natMap_FavouriteViews',
  NATMAP_SILENT_NETWORKSHARE = 'natMap_silent_networkShare',
  NATMAP_FILTERS = 'natMap_filters',
  NATMAP_SUBSCRIBER_EMAIL = 'natMap_subscriber_email',
  ROUTES_PLANNER_PLANNED_ROUTES = 'routes_planner_planned_routes',
  ROUTES_PLANNER_ORDER_BY_ROUTES = 'routes_planner_order_by_routes',
  SAVED_ROUTES = 'saved_routes',
  BASEMAP_LOADED_FROM_MAP_VIEW_PARAMS = 'basemap_loaded_from_map_view_params',
  PORTRAIT_LOCK = 'portrait_lock',
}

export enum SSOLoginStorageKeys {
  RETURN_URL_PATH = 'return_url_path',
  RETURN_URL_CLIENT_ID = 'return_url_client_id',
}

export enum WorkflowStorageKeys {
  ADVANCED_CONTROLS_PANE = 'wf_advanced_controls_pane',
}
