import React from 'react';

import {
  DSP_UserEntityLoaderProvider,
  DSP_UserEntityAvatarWithName,
} from 'components/DesignSystem/Portal/Entity/UserEntity';

import {
  DSP_BaseAuditRequestNotificationCard,
  DSP_BaseAuditRequestNotificationCardProps,
} from '../Base/BaseAuditRequestNotificationCard.Connector';

export interface DSP_AuditRequestCustomerNotificationCardViewProps
  extends Omit<DSP_BaseAuditRequestNotificationCardProps, 'avatar'> {
  userId: number | undefined;
}

export const DSP_AuditRequestCustomerNotificationCardView = ({
  userId,
  notification,
}: DSP_AuditRequestCustomerNotificationCardViewProps) => {
  return (
    <DSP_BaseAuditRequestNotificationCard
      notification={notification}
      avatar={
        userId && (
          <DSP_UserEntityLoaderProvider entityId={userId}>
            <DSP_UserEntityAvatarWithName />
          </DSP_UserEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AuditRequestCustomerNotificationCardView.displayName =
  'DSP_AuditRequestCustomerNotificationCardView';
