import React from 'react';

import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRowModel,
} from '@mui/x-data-grid-premium';
import {v4 as uuidv4} from 'uuid';

import {DSL_RestrictedVisualProps} from '../types';

export interface DSL_DataGridProps
  extends Omit<DataGridPremiumProps, DSL_RestrictedVisualProps> {
  idField?: string;
}

export const DSL_DataGrid = ({
  idField = 'id',
  ...restProps
}: DSL_DataGridProps) => {
  return (
    <DataGridPremium
      disableColumnFilter={false}
      rowHeight={38}
      disableRowSelectionOnClick={true}
      autoHeight={false}
      getRowId={(row: GridRowModel): string => {
        return row?.[idField] ? row[idField] : uuidv4();
      }}
      {...restProps}
    />
  );
};
DSL_DataGrid.displayName = 'DSL_DataGrid';

export {
  //
  /** @deprecated use DSL_DataGridProps instead*/
  DSL_DataGridProps as DataGridProps, //
  /** @deprecated use DSL_DataGrid instead*/
  DSL_DataGrid as DataGrid,
};
