import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Roundabout1 = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M30 21.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17M16.5 30c0-7.456 6.044-13.5 13.5-13.5S43.5 22.544 43.5 30 37.456 43.5 30 43.5 16.5 37.456 16.5 30"
              clipRule="evenodd"
            />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="m14.35 32.228-4.833-1.284-3.364 12.668 12.667 3.364 1.284-4.832-4.319-1.148 3.982-2.19A13.5 13.5 0 0 0 27.5 43.27v7.732h5v-8c0-2.575-2.044-4.274-4.04-4.64a8.5 8.5 0 0 1-5.03-2.966c-1.226-1.491-3.56-2.353-5.668-1.193l-4.61 2.537z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Roundabout1);
