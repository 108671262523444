import React from 'react';

export const NHVRGoNavyIcon = () => {
  return (
    <svg
      height="24"
      viewBox="0 0 544 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask
          id="mask"
          x="0"
          y="0"
          width="544"
          height="93.011"
          maskUnits="userSpaceOnUse"
        >
          <g id="mask0_302_663">
            <path fill="#fff" d="M544,0H0v93h544V0Z" />
          </g>
        </mask>
      </defs>
      <g>
        <g>
          <path
            fill="#132e52"
            d="M71.399.794h-5.067c-1.063,0-2.137.269-2.137,2.137v69.549C55.415,61.56,12.517,9.331,4.799,1.063,3.993.269,3.199,0,2.136,0,.805,0,0,.794,0,1.869v87.137c0,1.6.537,2.137,1.868,2.137h5.324c1.6,0,1.868-1.063,1.868-2.137V19.983c9.06,10.931,53.289,64.223,59.151,71.149.537.794,1.331,1.331,2.662,1.331,1.868,0,2.394-1.063,2.394-2.137V3.2c.269-1.063.269-2.394-1.868-2.394"
          />
          <path
            fill="#132e52"
            d="M160.382.794h-4.53c-1.868,0-2.125.806-2.125,2.663v43.434h-54.878V3.469c0-1.6-.269-2.663-2.136-2.663h-4.799c-1.868,0-2.394.537-2.394,2.663v85.537c0,1.868.537,2.394,2.125,2.394h4.799c1.6,0,2.136-.537,2.136-2.126v-33.846h54.877v33.577c0,1.868.794,2.137,2.137,2.137h4.53c1.868,0,2.394-.269,2.394-2.137V3.469c0-1.868-.269-2.663-2.126-2.663"
          />
          <path
            fill="#132e52"
            d="M252.565.794h-5.593c-1.063,0-1.6.806-2.125,1.869l-31.97,72.48L179.029,1.869c-.537-1.063-1.331-1.331-2.394-1.331h-5.593c-.536,0-1.062,0-1.331.537-.537.537-.268,1.331,0,1.869,1.6,3.468,39.967,86.343,40.761,87.943.269.537.795,2.126,2.394,2.126,1.331,0,1.868-.794,2.137-1.868.794-1.6,36.768-83.411,38.367-86.869.537-1.869.794-2.394.269-2.931.268-.537-.269-.537-1.063-.537"
          />
          <path
            fill="#132e52"
            d="M270.686,9.063h26.376c12.517,0,21.846,8.526,21.846,20.251s-8.524,18.92-22.383,18.92h-25.839V9.063ZM332.487,87.674c-7.998-11.189-20.514-28.509-23.714-32.772,11.992-4.263,18.647-13.594,18.647-25.577,0-16.526-12.785-28.509-30.101-28.509h-34.105c-.269,0-1.6.269-1.6,2.137v86.343c0,.537,0,2.126,1.868,2.126h5.593c1.6,0,1.868-1.063,1.868-2.126v-32.772h28.77l23.714,33.04c.794,1.063,1.599,1.331,2.662,1.331h5.325c.794,0,2.393,0,2.393-1.6-.537-.537-.794-1.063-1.331-1.6"
          />
        </g>
      </g>
      <path
        fill="#132e52"
        d="M444.147,38.437h-36.869l-15.492,16.425,24.497.123-24.799,25.398-8.423,8.425c.056.022.123.045.179.067,5.033,1.924,10.492,2.998,16.208,2.998,1.353,0,2.685-.067,4.005-.19l24.485-24.492v24.671l16.231-16.235v-37.213h-.011l-.011.022Z"
      />
      <path
        fill="#132e52"
        d="M498.286,74.957c-16.074,0-29.117-13.035-29.117-29.124s13.032-29.124,29.117-29.124,29.105,13.035,29.105,29.124-13.031,29.124-29.105,29.124ZM498.286,1.011c-25.09,0-45.426,20.341-45.426,45.437s20.336,45.437,45.426,45.437,45.426-20.341,45.426-45.437S523.376,1.011,498.286,1.011Z"
      />
      <path
        fill="#132e52"
        d="M374.134,84.176l12.294-12.296c-9.542-4.778-16.097-14.646-16.097-26.047,0-16.078,13.032-29.124,29.106-29.124,9.955,0,18.747,5.012,23.993,12.643h18.088c-6.756-16.626-23.043-28.352-42.092-28.352-25.09,0-45.426,20.341-45.426,45.437,0,15.72,7.987,29.572,20.123,37.728"
      />
    </svg>
  );
};
