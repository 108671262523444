import React from 'react';

export const WaIconWhite = () => {
  return (
    <svg
      width="193"
      height="24"
      viewBox="0 0 193 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2759 13.4048V17.3657C35.9196 17.6957 36.6787 17.9433 37.5534 18.1083C38.4363 18.2733 39.3646 18.3559 40.3383 18.3559C41.2873 18.3559 42.1867 18.2651 43.0366 18.0835C43.8948 17.902 44.6457 17.605 45.2893 17.1924C45.933 16.7715 46.4404 16.2269 46.8118 15.5585C47.1913 14.8819 47.3811 14.0485 47.3811 13.0583C47.3811 12.3404 47.2739 11.7133 47.0593 11.1769C46.8448 10.6323 46.5353 10.1496 46.131 9.72873C45.7267 9.30789 45.2398 8.93244 44.6705 8.60237C44.1093 8.26405 43.474 7.94636 42.7643 7.6493C42.2445 7.43476 41.7782 7.22847 41.3657 7.03043C40.9531 6.82414 40.6024 6.61784 40.3136 6.41155C40.0248 6.19701 39.802 5.97421 39.6452 5.74317C39.4884 5.50387 39.41 5.23569 39.41 4.93863C39.41 4.66632 39.4802 4.4229 39.6204 4.20836C39.7607 3.98556 39.9588 3.79577 40.2146 3.63899C40.4704 3.48221 40.7839 3.36256 41.1552 3.28004C41.5266 3.18927 41.9392 3.14389 42.393 3.14389C42.7231 3.14389 43.0696 3.16864 43.4327 3.21815C43.804 3.26766 44.1754 3.34605 44.5467 3.45333C44.918 3.55235 45.277 3.68025 45.6235 3.83703C45.9783 3.99381 46.3043 4.17535 46.6014 4.38164V0.680763C45.999 0.449716 45.3388 0.280557 44.6209 0.173285C43.9113 0.0577617 43.0944 0 42.1702 0C41.2295 0 40.3383 0.103146 39.4967 0.309438C38.655 0.507478 37.9123 0.821042 37.2687 1.25013C36.6333 1.67922 36.13 2.22795 35.7586 2.89634C35.3873 3.55647 35.2017 4.34863 35.2017 5.27282C35.2017 6.45281 35.54 7.45952 36.2166 8.29294C36.9015 9.12635 37.9371 9.83187 39.3234 10.4095C39.868 10.6323 40.3755 10.851 40.8458 11.0655C41.3162 11.28 41.7205 11.5028 42.0588 11.7339C42.4054 11.9649 42.6777 12.2166 42.8757 12.4889C43.0738 12.7612 43.1728 13.0707 43.1728 13.4172C43.1728 13.673 43.1109 13.9123 42.9871 14.1351C42.8633 14.3497 42.6736 14.5395 42.4178 14.7045C42.1702 14.8613 41.8566 14.985 41.4771 15.0758C41.1057 15.1666 40.6684 15.212 40.165 15.212C39.3069 15.212 38.4569 15.0634 37.6153 14.7664C36.7736 14.4611 35.9938 14.0072 35.2759 13.4048Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.3059 11.9319V18.0464H57.3079V0.297049H63.571C68.0434 0.297049 70.2796 2.18256 70.2796 5.95357C70.2796 7.73593 69.6359 9.17998 68.3487 10.2857C67.0697 11.3832 65.3574 11.9319 63.212 11.9319H61.3059ZM61.3059 3.36667V8.89942H62.8778C65.0067 8.89942 66.0712 7.96698 66.0712 6.1021C66.0712 4.27848 65.0067 3.36667 62.8778 3.36667H61.3059Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6199 18.0464H86.9768L80.6642 0.297049H75.9236L69.4626 18.0464H73.7947L75.0448 14.098H81.3574L82.6199 18.0464ZM78.5353 5.06239L80.4414 11.0284H75.8989L77.8298 5.01288C77.9948 4.50128 78.098 3.98555 78.1392 3.46569H78.2382C78.296 4.08457 78.395 4.6168 78.5353 5.06239Z"
        fill="white"
      />
      <path
        d="M99.8741 3.55234H94.8117V18.0464H90.8014V3.55234H85.7638V0.297049H99.8741V3.55234Z"
        fill="white"
      />
      <path
        d="M113.613 18.0464H117.624V0.297049H113.613V7.37699H106.261V0.297049H102.263V18.0464H106.261V10.8179H113.613V18.0464Z"
        fill="white"
      />
      <path
        d="M150.473 18.0464L155.164 0.297049H151.129L148.679 12.1423C148.538 12.8107 148.452 13.4708 148.419 14.1227H148.369C148.287 13.2893 148.192 12.6539 148.085 12.2166L145.176 0.297049H140.893L137.675 12.0681C137.469 12.819 137.349 13.5121 137.316 14.1475H137.242C137.192 13.2976 137.114 12.6209 137.007 12.1176L134.506 0.297049H130.137L134.803 18.0464H139.42L142.44 6.65909C142.581 6.13924 142.688 5.47498 142.762 4.66631H142.812C142.845 5.40071 142.94 6.06497 143.096 6.65909L146.042 18.0464H150.473Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.564 18.0464H162.863V16.2269H162.814C161.964 17.6462 160.706 18.3558 159.039 18.3558C157.809 18.3558 156.84 18.0093 156.13 17.3161C155.429 16.6147 155.078 15.6823 155.078 14.5188C155.078 12.0598 156.534 10.6405 159.447 10.261L162.888 9.80298C162.888 8.4167 162.137 7.72356 160.635 7.72356C159.125 7.72356 157.69 8.17328 156.328 9.07271V6.12686C156.873 5.8463 157.615 5.59875 158.556 5.38421C159.505 5.16967 160.367 5.0624 161.143 5.0624C164.757 5.0624 166.564 6.86539 166.564 10.4714V18.0464ZM162.888 12.8974V12.0433L160.586 12.3404C159.315 12.5054 158.68 13.0789 158.68 14.0608C158.68 14.5064 158.832 14.8736 159.138 15.1624C159.451 15.443 159.872 15.5833 160.4 15.5833C161.135 15.5833 161.733 15.3316 162.195 14.8283C162.657 14.3167 162.888 13.673 162.888 12.8974Z"
        fill="white"
      />
      <path
        d="M182.469 14.5436V17.7122C183.658 18.1413 184.949 18.3558 186.343 18.3558C187.103 18.3558 187.833 18.2775 188.534 18.1207C189.244 17.9721 189.867 17.7328 190.403 17.4028C190.948 17.0645 191.381 16.6312 191.703 16.1031C192.033 15.5668 192.198 14.9231 192.198 14.1722C192.198 13.6359 192.116 13.1738 191.95 12.786C191.794 12.3981 191.563 12.0598 191.257 11.771C190.96 11.4822 190.601 11.2305 190.18 11.016C189.76 10.8014 189.289 10.6034 188.769 10.4219C188.481 10.3228 188.188 10.2279 187.891 10.1372C187.602 10.0382 187.338 9.93088 187.099 9.81536C186.859 9.69159 186.665 9.54718 186.517 9.38215C186.368 9.21711 186.294 9.01907 186.294 8.78803C186.294 8.61474 186.343 8.46209 186.442 8.33006C186.542 8.18978 186.678 8.07838 186.851 7.99587C187.024 7.9051 187.222 7.83908 187.445 7.79783C187.676 7.74832 187.92 7.72356 188.175 7.72356C188.72 7.72356 189.273 7.79783 189.834 7.94636C190.403 8.08663 190.936 8.30118 191.431 8.58999V5.56987C190.87 5.40484 190.3 5.28107 189.723 5.19855C189.145 5.10778 188.576 5.0624 188.014 5.0624C187.297 5.0624 186.603 5.14079 185.935 5.29757C185.275 5.4461 184.685 5.6854 184.165 6.01546C183.653 6.33728 183.241 6.75399 182.927 7.26559C182.622 7.7772 182.469 8.38782 182.469 9.09747C182.469 9.61732 182.535 10.067 182.667 10.4466C182.808 10.8262 183.006 11.1604 183.261 11.4492C183.526 11.738 183.847 11.9938 184.227 12.2166C184.615 12.4311 185.06 12.6333 185.564 12.8231C185.877 12.9469 186.203 13.0583 186.542 13.1573C186.88 13.2563 187.189 13.3677 187.47 13.4915C187.759 13.6153 187.994 13.7597 188.175 13.9247C188.357 14.0897 188.448 14.296 188.448 14.5436C188.448 14.9314 188.245 15.2202 187.841 15.41C187.445 15.5998 186.9 15.6947 186.207 15.6947C185.638 15.6947 185.031 15.5998 184.388 15.41C183.752 15.2202 183.113 14.9314 182.469 14.5436Z"
        fill="white"
      />
      <path
        d="M176.578 19.0614L181.727 5.37184H177.852L175.464 13.1078C175.266 13.7432 175.129 14.3002 175.055 14.7788H175.006C174.923 14.1269 174.808 13.5616 174.659 13.083L172.295 5.37184H167.963L173.112 18.0217L172.444 19.5936C172.047 20.5426 171.346 21.017 170.339 21.017C169.729 21.017 169.163 20.8644 168.644 20.5591V23.6782C169.254 23.8927 170.034 24 170.983 24C173.475 24 175.34 22.3538 176.578 19.0614Z"
        fill="white"
      />
      <path
        d="M128.206 12.6498H121.436V9.91438H128.206V12.6498Z"
        fill="white"
      />
      <path
        d="M34.2114 0.297049L28.0969 18.0464H23.5668L17.5265 0.297049H21.8339L25.5348 12.6498C25.7328 13.3182 25.8525 13.9082 25.8937 14.4198H25.968C26.0258 13.8669 26.1537 13.2604 26.3517 12.6003L30.0278 0.297049H34.2114Z"
        fill="white"
      />
      <path
        d="M11.3502 18.0464H15.3605V0.297049H11.3502V7.37699H3.99794V0.297049H0V18.0464H3.99794V10.8179H11.3502V18.0464Z"
        fill="white"
      />
    </svg>
  );
};
