import React from 'react';

import {ThemeProvider} from 'app/theming/ThemeProvider';
import {DSP_HelpToolTip} from 'components/DesignSystem/Portal/AppBar/HelpToolTip';
import {
  launchIntercomHome,
  launchIntercomMapTour,
  launchIntercomFeedback,
} from 'utils/tracking';
import {v4 as uuid} from 'uuid';

import {
  DSL_IntercomAvatar,
  DSL_Body1,
  DSL_Body2,
  DSL_Box,
  DSL_Divider,
  DSL_Flex,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_Heading5,
  DSL_OpenInNew,
  DSL_OpenerProps,
  DSL_Popper,
  DSL_PopperList,
  DSL_PopperListItem,
  DSL_PopperListItemButton,
  DSL_PopperListItemIcon,
  DSL_PopperListSubheader,
  DSL_PopperProps,
  DSL_PrivacyTipOutlined,
  DSL_FileCopyOutlined,
  DSL_FeedbackIcon,
  DSL_TodayIcon,
} from 'components/DesignSystem/Library';

export interface DSP_HelpMenuViewProps {
  onListItemClick: (href: string) => void;
  popperProps: DSL_PopperProps;
  openerProps: DSL_OpenerProps;
}

const items = [
  {
    id: 'termsAndConditions',
    menuTitle: 'Terms of use',
    subtitle: 'Terms and conditions for use',
    href: '?view=Terms+and+conditions',
    icon: <DSL_FileCopyOutlined />,
  },
  {
    id: 'privacy',
    menuTitle: 'Privacy',
    subtitle: 'Read our privacy policy document',
    href: 'https://www.nhvr.gov.au/law-policies/privacy',
    icon: <DSL_PrivacyTipOutlined />,
  },
  // {
  //   id: 'disclaimer',
  //   menuTitle: 'Disclaimer',
  //   subtitle: '',
  //   href: 'https://www.nhvr.gov.au/disclaimer',
  //   icon: <DSL_ReportProblemOutlined />,
  // },
  // {
  //   id: 'accessibility',
  //   menuTitle: 'Accessibility',
  //   subtitle: 'Tools to help access the website',
  //   href: 'https://www.nhvr.gov.au/accessibility',
  //   icon: <DSL_AccessibleOutlined />,
  // },
  // {
  //   id: 'contact',
  //   menuTitle: 'Contact',
  //   subtitle: 'Feedback and enquiries',
  //   href: 'https://www.nhvr.gov.au/contact-us',
  //   icon: <DSL_SupportAgentOutlined />,
  // },
];

export const DSP_HelpMenuView = ({
  popperProps,
  openerProps,
  onListItemClick,
}: DSP_HelpMenuViewProps) => {
  return (
    <DSL_Box {...openerProps}>
      <DSP_HelpToolTip open={popperProps.open} />
      <ThemeProvider>
        <DSL_Popper id="help-menu-list" disablePortal {...popperProps}>
          <DSL_PopperList
            aria-labelledby="help-menu-subheader"
            subheader={
              <>
                <DSL_PopperListSubheader
                  disableSticky
                  id="help-menu-subheader"
                  key={uuid()}
                >
                  <DSL_Heading5>Help</DSL_Heading5>
                </DSL_PopperListSubheader>
              </>
            }
          >
            <DSL_PopperListItem key="intercom">
              <DSL_PopperListItemButton
                aria-label="Open help menu item"
                onClick={launchIntercomFeedback}
              >
                <DSL_FlexRow
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <DSL_FlexRow alignItems={'center'} gap={2}>
                    <DSL_Flex flex={0}>
                      <DSL_PopperListItemIcon>
                        <DSL_FeedbackIcon />
                      </DSL_PopperListItemIcon>
                    </DSL_Flex>
                    <DSL_FlexColumn
                      gap={0}
                      flex={1}
                      flexWrap={'wrap'}
                      maxWidth={'250px'}
                    >
                      <DSL_Body1>Feedback</DSL_Body1>
                      <DSL_Body2>
                        To help us improve, please share your ideas, suggestions
                        and feedback.
                      </DSL_Body2>
                    </DSL_FlexColumn>
                  </DSL_FlexRow>
                </DSL_FlexRow>
              </DSL_PopperListItemButton>
            </DSL_PopperListItem>
            <DSL_PopperListItem key="roadmap">
              <DSL_PopperListItemButton
                aria-label="Open help menu item"
                onClick={(): void => onListItemClick('/roadmap')}
              >
                <DSL_FlexRow
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <DSL_FlexRow alignItems={'center'} gap={2}>
                    <DSL_Flex flex={0}>
                      <DSL_PopperListItemIcon>
                        <DSL_TodayIcon />
                      </DSL_PopperListItemIcon>
                    </DSL_Flex>
                    <DSL_FlexColumn
                      gap={0}
                      flex={1}
                      flexWrap={'wrap'}
                      maxWidth={'250px'}
                    >
                      <DSL_Body1>Portal roadmap</DSL_Body1>
                      <DSL_Body2>
                        Find out what new features we are working on and vote
                        for new ideas.
                      </DSL_Body2>
                    </DSL_FlexColumn>
                  </DSL_FlexRow>
                  <DSL_OpenInNew color="action" fontSize="small" />
                </DSL_FlexRow>
              </DSL_PopperListItemButton>
            </DSL_PopperListItem>
            <DSL_Divider />
            {items.map(({href, menuTitle, subtitle, icon}, index) => (
              <DSL_PopperListItem key={index}>
                <DSL_PopperListItemButton
                  aria-label="Open help menu item"
                  onClick={(): void => onListItemClick(href)}
                >
                  <DSL_FlexRow
                    width={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <DSL_FlexRow alignItems={'center'} gap={2}>
                      <DSL_Flex flex={0}>
                        <DSL_PopperListItemIcon>{icon}</DSL_PopperListItemIcon>
                      </DSL_Flex>
                      <DSL_FlexColumn gap={0} flex={1} flexWrap={'wrap'}>
                        <DSL_Body1>{menuTitle}</DSL_Body1>
                        <DSL_Body2>{subtitle}</DSL_Body2>
                      </DSL_FlexColumn>
                    </DSL_FlexRow>
                    <DSL_OpenInNew color="action" fontSize="small" />
                  </DSL_FlexRow>
                </DSL_PopperListItemButton>
              </DSL_PopperListItem>
            ))}
            <DSL_Divider />
            <DSL_PopperListItem key="help-and-support">
              <DSL_PopperListItemButton
                aria-label="Open help menu item"
                onClick={launchIntercomMapTour}
              >
                <DSL_FlexRow
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <DSL_FlexRow alignItems={'center'} gap={2}>
                    <DSL_Flex flex={0}>
                      <DSL_PopperListItemIcon>
                        <DSL_IntercomAvatar />
                      </DSL_PopperListItemIcon>
                    </DSL_Flex>
                    <DSL_FlexColumn gap={0} flex={1} flexWrap={'wrap'}>
                      <DSL_Body1>Video tour</DSL_Body1>
                      <DSL_Body2>Play an introductory video tour</DSL_Body2>
                    </DSL_FlexColumn>
                  </DSL_FlexRow>
                </DSL_FlexRow>
              </DSL_PopperListItemButton>
            </DSL_PopperListItem>
            <DSL_PopperListItem key="video-tour">
              <DSL_PopperListItemButton
                aria-label="Open help menu item"
                onClick={launchIntercomHome}
              >
                <DSL_FlexRow
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <DSL_FlexRow alignItems={'center'} gap={2}>
                    <DSL_Flex flex={0}>
                      <DSL_PopperListItemIcon>
                        <DSL_IntercomAvatar />
                      </DSL_PopperListItemIcon>
                    </DSL_Flex>
                    <DSL_FlexColumn gap={0} flex={1} flexWrap={'wrap'}>
                      <DSL_Body1>Help and support</DSL_Body1>
                      <DSL_Body2>
                        Find help articles or ask us a question
                      </DSL_Body2>
                    </DSL_FlexColumn>
                  </DSL_FlexRow>
                </DSL_FlexRow>
              </DSL_PopperListItemButton>
            </DSL_PopperListItem>
          </DSL_PopperList>
        </DSL_Popper>
      </ThemeProvider>
    </DSL_Box>
  );
};

DSP_HelpMenuView.displayName = 'DSP_HelpMenuView';

export {
  //
  /** @deprecated use DSP_HelpMenuViewProps instead*/
  DSP_HelpMenuViewProps as HelpMenuViewProps, //
  /** @deprecated use DSP_HelpMenuView instead*/
  DSP_HelpMenuView as HelpMenuView,
};
