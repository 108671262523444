import React from 'react';

import {AuditRequest} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AuditRequestCustomerNotificationCardView} from './AuditRequestCustomerNotificationCard.View';
import {useAuditRequestCustomerNotificationCard} from './useAuditRequestCustomerNotificationCard';

export interface DSP_AuditRequestCustomerNotificationCardProps {
  notification: NotificationItem<AuditRequest>;
}

export const DSP_AuditRequestCustomerNotificationCard = (
  props: DSP_AuditRequestCustomerNotificationCardProps,
) => {
  const viewProps = useAuditRequestCustomerNotificationCard(props);

  return <DSP_AuditRequestCustomerNotificationCardView {...viewProps} />;
};

DSP_AuditRequestCustomerNotificationCard.displayName =
  'DSP_AuditRequestCustomerNotificationCard';
