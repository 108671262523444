import {useRef} from 'react';

type Comparator<ValueType> = (v1: ValueType, v2: ValueType) => boolean;
function defaultComparator<ValueType>(v1: ValueType, v2: ValueType) {
  return v1 === v2;
}

/**
 * Utility hook to run a callback on change of a value.
 * A more explicit alternative to useEffect which doesn't suffer from double rendering issues.
 */
export function useOnValueChange<ValueType>(
  value: ValueType,
  onChange: (value: ValueType) => void,
  comparator: Comparator<ValueType> = defaultComparator,
) {
  const currentValue = useRef(value);

  if (!comparator(currentValue.current, value)) {
    currentValue.current = value;
    onChange(value);
  }
}
