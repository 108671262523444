import {ContentMap, DomainContentMap} from './types';
import React from 'react';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {ContentKeys} from './index';
import {WaLogo} from '../app/theming/WaLogo';
import {NHVRGoLogo} from '../app/theming/NHVRGoLogo';
import {NHVRGoIcon} from '../app/theming/NHVRGoIcon';
import {NHVRLogo} from '../app/theming/NHVRLogo';
import {NHVRGoNavyIcon} from '../app/theming/NHVRGoNavyIcon';
import {NHVRIcon} from '../app/theming/NHVRIcon';

import {WaIcon} from '../app/theming/WaIcon';
import {WaIconWhite} from '../app/theming/WaIconWhite';
import splash from 'assets/splash.jpg';
import splashGo from 'assets/splash-go.png';
import {isNhvrGo} from '../utils/global';

export type ContentKeysGlobal =
  | 'GLOBAL_BRAND_NAME_LONG'
  | 'GLOBAL_BRAND_NAME_SHORT'
  | 'GLOBAL_APP_NAME_SHORT'
  | 'GLOBAL_BRAND_LOGO'
  | 'GLOBAL_BRAND_ICON'
  | 'GLOBAL_BRAND_ICON_MAP'
  | 'GLOBAL_BRAND_SPLASH'
  | 'GLOBAL_BRAND_SPLASH_OVERLAY'
  | 'GLOBAL_BRAND_THEME';

const nhvrBrandNameLong = 'National Heavy Vehicle Regulator';
const nhvrBrandNameShort = 'NHVR';

const useNHVRGo = isNhvrGo();

const nhvr: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_LOGO: <NHVRLogo />,
  GLOBAL_BRAND_ICON: <NHVRIcon />,
  GLOBAL_BRAND_ICON_MAP: <NHVRIcon />,
  GLOBAL_BRAND_NAME_LONG: nhvrBrandNameLong,
  GLOBAL_BRAND_NAME_SHORT: nhvrBrandNameShort,
  GLOBAL_APP_NAME_SHORT: `${nhvrBrandNameShort} Portal`,
  GLOBAL_BRAND_SPLASH: splash,
  GLOBAL_BRAND_SPLASH_OVERLAY: 'primary.shades.50p',
  GLOBAL_BRAND_THEME: 'light',
};

const nhvrGo: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_LOGO: <NHVRGoLogo />,
  GLOBAL_BRAND_ICON: <NHVRGoIcon />,
  GLOBAL_BRAND_ICON_MAP: <NHVRGoNavyIcon />,
  GLOBAL_BRAND_NAME_LONG: nhvrBrandNameLong,
  GLOBAL_BRAND_NAME_SHORT: nhvrBrandNameShort,
  GLOBAL_APP_NAME_SHORT: `${nhvrBrandNameShort} GO`,
  GLOBAL_BRAND_SPLASH: splashGo,
  GLOBAL_BRAND_SPLASH_OVERLAY: '',
  GLOBAL_BRAND_THEME: 'nhvrGoLight',
};

const waBrandName = 'MRWA';
const waAppName = 'HVS PATH-Ways';

const wa: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_LOGO: <WaLogo />,
  GLOBAL_BRAND_ICON: useNHVRGo ? <WaIconWhite /> : <WaIcon />,
  GLOBAL_BRAND_NAME_LONG: waBrandName,
  GLOBAL_BRAND_NAME_SHORT: waBrandName,
  GLOBAL_APP_NAME_SHORT: waAppName,
  GLOBAL_BRAND_SPLASH: splash,
  GLOBAL_BRAND_SPLASH_OVERLAY: 'primary.shades.50p',
  GLOBAL_BRAND_THEME: 'wa',
};

const adfBrandName = 'ADF Portal';

const adf: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_NAME_LONG: adfBrandName,
  GLOBAL_BRAND_NAME_SHORT: adfBrandName,
  GLOBAL_APP_NAME_SHORT: adfBrandName,
  GLOBAL_BRAND_SPLASH: splash,
  GLOBAL_BRAND_SPLASH_OVERLAY: 'primary.shades.50p',
  GLOBAL_BRAND_THEME: 'wa',
};

export const globalContent: DomainContentMap<
  AuthorisationDomains,
  ContentKeys
> = {
  nhvr: useNHVRGo ? nhvrGo : nhvr,
  wa,
  adf,
};
