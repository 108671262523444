import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/:accreditationId/audits/:auditId',
  component: lazy(() => import(/* webpackChunkName: "Audit" */ './Audit')),
  visibleInMenu: false,
  menuTitle: 'Audit',
  menuIcon: Today,
  hideInProduction: false,
  authorisation: {
    apiKey: 'Audit.findbyId',
    policies: [
      {role: 'operationsAccreditationUser'},
      {role: 'providerAccreditationUser'},
      {role: 'customerAccreditationUser'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
