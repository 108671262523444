import {getAccreditationSchemeCode} from 'utils/accreditation/getAccreditationSchemeCode';

import {DSP_AccreditationApplicationNotificationItem} from './types';

export interface DSP_UseRecordAssignmentNotificationCardProps {
  notification: DSP_AccreditationApplicationNotificationItem;
}

export function useRecordAssignmentNotificationCard({
  notification,
}: DSP_UseRecordAssignmentNotificationCardProps) {
  const {id, accreditationId, accreditation, applicationType, status} =
    notification.data;

  const actionDescription = 'assigned you to';

  const accreditationSchemeCode =
    getAccreditationSchemeCode(accreditation?.accreditationSchemeId) ?? '';

  return {
    actionDescription,
    isRead: notification.readByLoggedInUser,
    href: `/accreditation-${accreditationSchemeCode.toLowerCase()}/${accreditationId}/applications/${id}`,
    created: notification.created ?? undefined,
    name: applicationType ?? undefined,
    applicationId: accreditationSchemeCode + '-' + id,
    status: status ?? undefined,
    customerAccountId: notification.data.customerAccount?.id,
    operationsAccountId: notification.data.operationsAccountId,
  };
}
