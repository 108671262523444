import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/operationManuals/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "OperationManualGateway" */ './OperationManualGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add operation manual',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'OperationManualWithRelations.create',
    policies: [
      {role: 'customerAccreditationUser', idVerified: true},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Operation Manuals',
      to: '..',
    },
  ],
};
