import React, {ForwardedRef, forwardRef, ReactNode} from 'react';
import {
  DSL_AppBar,
  DSL_AppBarProps,
  DSL_Body2,
  DSL_CloseIconButton,
  DSL_ContainedButton,
  DSL_Flex,
  DSL_FlexRow,
  DSL_MenuIconButton,
  DSL_OutlinedButton,
  DSL_Toolbar,
} from 'components/DesignSystem/Library';
import {
  AuthorisationAccountTypes,
  UserProfile,
} from '@regulatory-platform/common-utils/dist';
import {Sidebar} from 'components/navigation/Sidebar';
import {isUATOrProduction, mapAccountTypeToReadable} from 'utils/global';
import ThemeToggle from '../ThemeToggle';
import {
  DSP_AccountHeader,
  DSP_AccountHeaderMenu,
  DSP_HelpMenu,
  DSP_ProfileMenu,
} from 'components/DesignSystem/Portal';
import {launchIntercomFeedback} from 'utils/tracking';
import {AppBarThemeProvider} from './AppBarThemeProvider';

export interface AppBarUnAuthenticatedViewProps extends DSL_AppBarProps {
  anchorEl: HTMLElement | null;
  anchorRef: React.MutableRefObject<null>;
  accountType: AuthorisationAccountTypes;
  isProfileMenuOpen: boolean;
  isSidebarOpen: boolean;
  toggleSidebarOpen: () => void;
  handleProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  handleLogin: () => void;
  showNotifications?: boolean;
  map?: boolean;
  userProfile?: UserProfile;
  logoutUrl?: string;
  navigateToUnAuthenticatedHome: () => void;
  brandIcon: ReactNode;
}

export const AppBarUnAuthenticatedView = forwardRef(
  (
    {
      anchorEl,
      anchorRef,
      accountType,
      isProfileMenuOpen,
      isSidebarOpen,
      toggleSidebarOpen,
      handleProfileMenuOpen,
      handleMenuClose,
      handleLogin,
      showNotifications,
      map,
      userProfile,
      logoutUrl,
      navigateToUnAuthenticatedHome,
      brandIcon,
      ...props
    }: AppBarUnAuthenticatedViewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <AppBarThemeProvider map={map}>
        <DSL_AppBar ref={ref} {...props}>
          <DSL_Toolbar>
            <DSL_FlexRow
              justifyContent={'space-between'}
              width={'100%'}
              data-name={'app-bar-contents-wrapper'}
            >
              <Sidebar
                isOpen={isSidebarOpen}
                toggleIsOpen={toggleSidebarOpen}
              />
              <DSL_FlexRow
                hiddenDownTo={'sm'}
                alignItems={'center'}
                data-name={'app-bar-contents-left'}
              >
                {!map &&
                  (isSidebarOpen ? (
                    <DSL_CloseIconButton
                      aria-label="close drawer"
                      onClick={toggleSidebarOpen}
                    />
                  ) : (
                    <DSL_MenuIconButton
                      aria-label="open drawer"
                      onClick={toggleSidebarOpen}
                    />
                  ))}
                <DSL_Flex
                  alignItems={'center'}
                  cursor={'pointer'}
                  role={'button'}
                  aria-label={'Navigate to UnAuthenticatd Home'}
                  onClick={navigateToUnAuthenticatedHome}
                >
                  {brandIcon}
                </DSL_Flex>
                {!isUATOrProduction() && <ThemeToggle />}
                {userProfile && (
                  <DSL_FlexRow hiddenDownTo={'md'} alignItems={'center'}>
                    <DSP_AccountHeader userProfile={userProfile} />
                    <DSL_Body2 color={'text.secondary'}>
                      {mapAccountTypeToReadable(userProfile?.accountType ?? '')}
                    </DSL_Body2>
                  </DSL_FlexRow>
                )}
              </DSL_FlexRow>
              <DSL_FlexRow
                alignItems={'center'}
                data-name={'app-bar-contents-right'}
              >
                <DSL_FlexRow alignItems={'center'} gap={2}>
                  {userProfile && (
                    <DSL_FlexRow hiddenUpTo={'md'} alignItems={'center'}>
                      <DSP_AccountHeaderMenu userProfile={userProfile} />
                    </DSL_FlexRow>
                  )}
                  <DSP_HelpMenu />
                  {map && (
                    <DSL_OutlinedButton onClick={launchIntercomFeedback}>
                      Feedback
                    </DSL_OutlinedButton>
                  )}
                  {!userProfile && !isUATOrProduction() && (
                    <DSL_ContainedButton onClick={handleLogin}>
                      Log in
                    </DSL_ContainedButton>
                  )}
                  {userProfile && (
                    <DSP_ProfileMenu
                      userProfile={userProfile}
                      logoutUrl={logoutUrl}
                    />
                  )}
                </DSL_FlexRow>
              </DSL_FlexRow>
            </DSL_FlexRow>
          </DSL_Toolbar>
        </DSL_AppBar>
      </AppBarThemeProvider>
    );
  },
);

AppBarUnAuthenticatedView.displayName = 'AppBarUnAuthenticatedView';
