import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_TurnRight = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="m35.232 15.768 3.536-3.536 9.268 9.268-9.268 9.268-3.536-3.536L38.465 24H22a1.5 1.5 0 0 0-1.5 1.5V45h-5V25.5A6.5 6.5 0 0 1 22 19h16.464z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_TurnRight);
