import {GroupOutlined} from '@mui/icons-material';
import CustomerAccount from 'containers-accounts/CustomerAccount';
import ManageCustomerAccount from 'containers-accounts/CustomerAccount/ManageCustomerAccount';
import ManageAccount from 'containers-accounts/Accounts/ManageAccount';
import RegulatorAccount from 'containers-accounts/OperationsAccount';
import ManageRegulatorAccount from 'containers-accounts/OperationsAccount/ManageRegulatorAccount';
import PartnerAccount from 'containers-accounts/PartnerAccount';
import ManagePartnerAccount from 'containers-accounts/PartnerAccount/ManagePartnerAccount';
import ProviderAccount from 'containers-accounts/ProviderAccount';
import CreateProviderAccount from './ProviderAccount/CreateProviderAccount';
import ManageProviderAccount from './ProviderAccount/ManageProviderAccount';
import ManageMembers from './Members/ManageMember';
import CreateMember from './Members/CreateMember';
import CreateProviderAccountMember from './ProviderAccountMember/CreateProviderAccountMember';
import ManageProviderAccountMember from './ProviderAccountMember/ManageProviderAccountMember';
import CreatePartnerAccountMember from './PartnerAccountMember/CreatePartnerAccountMember';
import ManagePartnerAccountMember from './PartnerAccountMember/ManagePartnerAccountMember';
import CreateRegulatorAccountMember from './OperationsAccountMember/CreateRegulatorAccountMember';
import ManageRegulatorAccountMember from './OperationsAccountMember/ManageRegulatorAccountMember';
import CreateCustomerAccountMember from './CustomerAccountMember/CreateCustomerAccountMember';
import ManageCustomerAccountMember from './CustomerAccountMember/ManageCustomerAccountMember';
import CreateRegulatorAccount from './OperationsAccount/CreateRegulatorAccount';
import CreatePartnerAccount from './PartnerAccount/CreatePartnerAccount';
import CreateCustomerAccount from './CustomerAccount/CreateCustomerAccount';
import Profile from 'containers-accounts/Profile';
import SecurityDetails from 'containers-accounts/Profile/SecurityDetails';
import EventLogDetails from 'containers-accounts/Profile/EventLogDetails';
import CreateBusinessAccountLanding from './CreateBusinessAccount/Landing';

export default {
  routes: [
    ManageAccount,
    CreateBusinessAccountLanding,
    ProviderAccount,
    PartnerAccount,
    CustomerAccount,
    RegulatorAccount,
    ManageProviderAccount,
    ManagePartnerAccount,
    ManageCustomerAccount,
    ManageRegulatorAccount,
    CreateMember,
    CreatePartnerAccount,
    CreateCustomerAccount,
    CreateProviderAccount,
    CreateProviderAccountMember,
    ManageProviderAccountMember,
    CreatePartnerAccountMember,
    ManagePartnerAccountMember,
    CreateRegulatorAccount,
    CreateRegulatorAccountMember,
    ManageRegulatorAccountMember,
    CreateCustomerAccountMember,
    ManageCustomerAccountMember,
    ManageMembers,
    Profile,
    SecurityDetails,
    EventLogDetails,
  ],
  menuTitle: 'Accounts',
  menuDescription: 'Manage your account and user memberships',
  path: '/accounts',
  showChildRoutesInMenu: true,
  menuIcon: GroupOutlined,
};
