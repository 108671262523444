import React from 'react';
import {useStyles} from '../image.styles';
import clsx from 'clsx';
import {ModeConstants} from '../VehicleComponentImage/VehicleComponentImage';

type NoVehicleComponentProps = {
  mode?: ModeConstants;
};

/**
 * NoVehicleComponent
 */
const NoVehicleComponent: React.FC<NoVehicleComponentProps> = ({
  mode = ModeConstants.CONFIGURATION,
}: NoVehicleComponentProps) => {
  const classes = useStyles();

  const className = clsx(classes.defaultImage, {
    [classes.iconImage]:
      mode === ModeConstants.ICON_CONFIGURATION || mode === ModeConstants.ICON,
    [classes.singleImage]: mode === ModeConstants.SINGLE,
    [classes.cardImage]: mode === ModeConstants.CARD,
    [classes.configuration]: mode === ModeConstants.CONFIGURATION,
    [classes.cardConfigurationImage]: mode === ModeConstants.CARD_CONFIGURATION,
  });

  return (
    <img
      className={className}
      alt="No vehicle component"
      src={
        'https://nhvrappsprod.blob.core.windows.net/vehicle-components/default.png'
      }
    />
  );
};

export default NoVehicleComponent;
