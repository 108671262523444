import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';

export default {
  path: '/registrations/jurisdictionalContacts',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "JurisdictionalContacts" */ './JurisdictionalContacts'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Jurisdictional contacts',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: 'VehicleRegistrationExtendedWithRelations.findById',
    policies: [
      {
        role: 'partnerRegistrationEnabled',
        idVerified: true,
      },
      {
        role: 'customerRegistrationEnabled',
        idVerified: true,
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
