import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_ForkLeft = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillOpacity={0.3}
              fillRule="evenodd"
              d="m44.268 17.768-6.142 6.142A17.5 17.5 0 0 0 33 36.284V48h-5V36.284a22.5 22.5 0 0 1 6.59-15.91l6.142-6.142z"
              clipRule="evenodd"
            />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="M27.004 16.598v-5H13.897v13.107h5v-4.772l3.977 3.977A17.5 17.5 0 0 1 28 36.284V48h5V36.284a22.5 22.5 0 0 0-6.59-15.91l-3.776-3.776z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_ForkLeft);
