import React, {ForwardedRef, forwardRef} from 'react';

import {DSL_MoreVertIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_MoreVertIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_MoreVertIconButton = forwardRef(
  (
    props: DSL_MoreVertIconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => {
    return (
      <DSL_BaseIconButton ref={ref} aria-label="more" {...props}>
        <DSL_MoreVertIcon />
      </DSL_BaseIconButton>
    );
  },
);

DSL_MoreVertIconButton.displayName = 'DSL_MoreVertIconButton';

export {
  //
  /** @deprecated use DSL_MoreVertIconButtonProps instead*/
  DSL_MoreVertIconButtonProps as MoreVertIconButtonProps, //
  /** @deprecated use DSL_MoreVertIconButton instead*/
  DSL_MoreVertIconButton as MoreVertIconButton,
};
