import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Roundabout4 = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M29.447 21.5a8.5 8.5 0 1 1 0 17 8.5 8.5 0 0 1 0-17m13.5 8.5c0-7.456-6.044-13.5-13.5-13.5s-13.5 6.044-13.5 13.5 6.044 13.5 13.5 13.5 13.5-6.044 13.5-13.5"
              clipRule="evenodd"
            />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="m45.097 27.683 4.832 1.283 3.365-12.667-12.668-3.365-1.283 4.833 4.363 1.159-4.01 2.286a13.48 13.48 0 0 0-10.25-4.712c-7.455 0-13.5 6.044-13.5 13.5 0 6.603 4.74 12.095 11 13.269V51h5v-8c0-2.575-2.045-4.274-4.04-4.639a8.5 8.5 0 0 1 1.54-16.861 8.48 8.48 0 0 1 6.48 2.998c1.31 1.54 3.789 2.458 6.014 1.19l4.345-2.477z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Roundabout4);
