import {AccreditationApplicationWorkflowState} from 'containers-accreditation/Shared/types';
import {getAccreditationSchemeCode} from 'utils/accreditation/getAccreditationSchemeCode';

import {applicationWorkflowStateDescriptionMap} from '../constants';
import {DSP_AccreditationApplicationNotificationItem} from '../types';

export interface DSP_UseBaseAccreditationApplicationNotificationCardProps {
  notification: DSP_AccreditationApplicationNotificationItem;
}

export function useBaseAccreditationApplicationNotificationCard({
  notification,
}: DSP_UseBaseAccreditationApplicationNotificationCardProps) {
  const {
    id,
    accreditationId,
    accreditation,
    applicationState,
    applicationType,
    status,
  } = notification.data;

  const actionDescription =
    applicationWorkflowStateDescriptionMap[
      applicationState?.workflowState as AccreditationApplicationWorkflowState
    ];

  const accreditationSchemeCode =
    getAccreditationSchemeCode(accreditation?.accreditationSchemeId) ?? '';

  return {
    actionDescription,
    isRead: notification.readByLoggedInUser,
    href: `/accreditation-${accreditationSchemeCode.toLowerCase()}/${accreditationId}/applications/${id}`,
    created: notification.created ?? undefined,
    name: applicationType ?? undefined,
    applicationId: accreditationSchemeCode + '-' + id,
    status: status ?? undefined,
  };
}
