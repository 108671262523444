import {DSL_BaseDrawerProps} from '../../BaseDrawer';
import {
  useNonModalDrawer,
  DSL_UseNonModalDrawerArgs,
} from '../useNonModalDrawer';

export interface DSL_UseCollapsibleNonModalDrawerProps
  extends Omit<DSL_UseNonModalDrawerArgs, 'anchor' | 'sidebarAnchor'> {
  isCollapsed?: boolean;
}

export interface DSL_UseCollapsibleNonModalDrawerReturn
  extends DSL_BaseDrawerProps {
  isCollapsed: boolean | undefined;
}

export function useCollapsibleNonModalDrawer({
  isCollapsed,
  ...props
}: DSL_UseCollapsibleNonModalDrawerProps): DSL_UseCollapsibleNonModalDrawerReturn {
  const nonModalDrawerProps = useNonModalDrawer({
    ...props,
    anchor: isCollapsed ? 'bottom' : ('left' as const),
    sidebarAnchor: isCollapsed ? 'bottom' : ('left' as const),
  });

  const overrideProps: Partial<DSL_BaseDrawerProps> = {};

  if (isCollapsed) {
    overrideProps.height = 'auto';
  }

  return {
    ...nonModalDrawerProps,
    ...overrideProps,
    isCollapsed,
  };
}
