import AccreditationAuditRequestDetails from './AccreditationAuditRequestDetails';
import ApplicationAuditRequestDetails from './ApplicationAuditRequestDetails';
import AuditRequestDetails from './AuditRequestDetails';
import AuditRequestProvider from './Provider';

export default [
  AuditRequestProvider,
  AuditRequestDetails,
  ApplicationAuditRequestDetails,
  AccreditationAuditRequestDetails,
];
