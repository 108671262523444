import React from 'react';
import {
  DSL_Box,
  DSL_CircularProgress,
  DSL_FlexRow,
} from 'components/DesignSystem/Library';

import {useIdVerify} from './useIdVerify';

interface IdVerifyProps {
  onVerify?: () => void;
}

export const IdVerify = ({onVerify}: IdVerifyProps) => {
  const {loading} = useIdVerify(onVerify);

  return (
    <DSL_FlexRow>
      <DSL_Box id="digitalid-verify"></DSL_Box>
      {loading && <DSL_CircularProgress />}
    </DSL_FlexRow>
  );
};
