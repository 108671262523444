import ListOutlined from '@mui/icons-material/ListOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
  AuthorisationDomains,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accreditation-nhvas',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AuditorsPublic-NHVAS" */ 'containers-accreditation/Auditors/Public/AuditorsPublic'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'NHVAS Approved Auditors',
  menuDescription:
    'List of NHVAS Approved Auditors who can audit an NHVAS management system.',
  menuIcon: ListOutlined,
  authorisation: {
    domain: AuthorisationDomains.NHVR,
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
