export enum AccreditationApplicationWorkflowState {
  DRAFTING_STATE = 1,
  SUBMITTED_STATE = 2,
  ASSIGNED_STATE = 3,
  ASSESSMENT_STATE = 4,
  INFORMATION_REQUESTED_STATE = 5,
  FINALISATION_STATE = 6,
  ISSUED_STATE = 7,
  CANCELLED_STATE = 9,
  WITHDRAWN_STATE = 10,
  ARCHIVED_STATE = 11,
  PENDING_ISSUE_STATE = 12,
}

export enum AccreditationWorkflowState {
  NO_APPLICATION_STATE = 1,
  DRAFT_STATE = 2,
  SUBMITTED_STATE = 3,
  ISSUED_STATE = 4,
  PROVISIONAL_STATE = 5,
  SUSPENDED_STATE = 6,
  EXPIRED_STATE = 8,
  RETIRED_STATE = 9,
  REVOKED_STATE = 10,
}
export enum AccreditationModuleStatus {
  Provisional = 'Provisional',
  Current = 'Current',
  Expired = 'Expired',
  Cancelled = 'Cancelled',
  Suspended = 'Suspended',
}
export enum AccreditationStatusEnum {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Issued = 'Accredited - Current',
  Revoked = 'Not Accredited - Cancelled',
  Retired = 'Not Accredited - Retired',
  Provisional = 'Accredited - Provisional', //currently unused
  Suspended = 'Not Accredited - Suspended', //currently unused
  Expired = 'Not Accredited - Expired',
}

export enum AuditRequestWorkflowState {
  DRAFT_STATE = 1,
  SUBMITTED_STATE = 2,
  APPROVED_STATE = 3,
  DECLINED_STATE = 4,
  WITHDRAWN_STATE = 5,
}
