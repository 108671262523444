import React from 'react';

import {
  DSL_Box,
  DSL_Divider,
  DSL_DrawerContent,
  DSL_FlexRow,
} from 'components/DesignSystem/Library';

import {DSP_AccessTypeSelect} from '../AccessTypeSelect';
import {DSP_RoadsByAccessTypeList} from '../RoadsByAccessTypeList/RoadsByAccessTypeList';
import {DSP_SelectedSegmentsRoadList} from '../SelectedSegmentsRoadList';
import {DSP_SelectedSegmentsSegmentList} from '../SelectedSegmentsSegmentList';
import {DSP_SelectedSegmentsSingleList} from '../SelectedSegmentsSingleList';
import {DSP_SelectedSegmentsSummary} from '../SelectedSegmentsSummary';
import {DSP_ViewByRoadsOrAccessTypeSelect} from '../ViewByRoadsOrAccessTypeSelect';

import {useDspSelectedSegmentsDrawerContents} from './useSelectedSegmentsDrawerContents';

export interface DSP_SelectedSegmentsDrawerContentsProps {
  onRemoveSegment?: (segmentId: number) => void;
  onRemoveSegments?: (segmentIds: number[]) => void;
  showChangeOptions?: boolean;
}

export const DSP_SelectedSegmentsDrawerContents = ({
  onRemoveSegment,
  onRemoveSegments,
  showChangeOptions,
}: DSP_SelectedSegmentsDrawerContentsProps) => {
  const {
    showViewByRoadsOrAccessTypeSelect,
    showAccessTypeSelect,
    showSingleListView,
  } = useDspSelectedSegmentsDrawerContents();

  return (
    <>
      <DSL_FlexRow p={2} justifyContent={'space-between'}>
        <DSP_SelectedSegmentsSummary />
        {(showViewByRoadsOrAccessTypeSelect || showAccessTypeSelect) && (
          <DSL_Box width={'150px'}>
            {showViewByRoadsOrAccessTypeSelect && (
              <DSP_ViewByRoadsOrAccessTypeSelect />
            )}
            {showAccessTypeSelect && <DSP_AccessTypeSelect />}
          </DSL_Box>
        )}
      </DSL_FlexRow>
      {!showSingleListView && <DSL_Divider />}
      <DSL_DrawerContent padding={'NONE'} neverShowBorder position={'relative'}>
        {showSingleListView ? (
          <DSP_SelectedSegmentsSingleList
            onRemoveSegment={onRemoveSegment}
            onRemoveSegments={onRemoveSegments}
            showChangeOptions={showChangeOptions}
          />
        ) : (
          <>
            <DSP_SelectedSegmentsRoadList onRemoveSegments={onRemoveSegments} />
            <DSP_SelectedSegmentsSegmentList
              showChangeOptions={showChangeOptions}
              onRemoveSegment={onRemoveSegment}
            />
            <DSP_RoadsByAccessTypeList onRemoveSegments={onRemoveSegments} />
          </>
        )}
      </DSL_DrawerContent>
    </>
  );
};
