import {useState, useContext, useEffect} from 'react';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';
import {useLocalStorage} from 'usehooks-ts';
import {VehicleNetworkMapStorageKeys} from 'utils/storage';
import AppServicesContext from 'app/services/AppServicesContext';

export const usePortraitLock = () => {
  const [showPortraitLock, setShowPortraitLock] = useLocalStorage<boolean>(
    VehicleNetworkMapStorageKeys.PORTRAIT_LOCK,
    true,
  );
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const {app} = useContext(AppServicesContext);
  const appState = useSelector(app.service, R.identity);
  const isMap = appState.context.hostname.indexOf('maps') > -1;

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const maxHeightInPixelsForLandscape = 500;

  const onHidePortraitLock = () => {
    setShowPortraitLock(false);
  };

  return {
    isMap,
    showPortraitLock,
    viewportHeight,
    maxHeightInPixelsForLandscape,
    onHidePortraitLock,
  };
};
