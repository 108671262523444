import React, {ReactNode} from 'react';

import {SelectChangeEvent} from '@mui/material';
import {ThemeProvider} from 'app/theming/ThemeProvider';
import {DSP_NotificationsToolTip} from 'components/DesignSystem/Portal/AppBar/NotificationsToolTip';
import {NotificationFilter} from 'utils/machines/notificationsMachine';

import {
  Box,
  CircularProgress,
  FilterBar,
  FlexColumn,
  Heading5,
  OpenerProps,
  Popper,
  PopperProps,
  RingingBellEmptyState,
  SelectInput,
  TextButton,
} from 'components/DesignSystem/Library';

import {
  DSP_NotificationList,
  DSP_NotificationListProps,
} from '../NotificationsList';

export interface DSP_NotificationsPopoutViewProps
  extends DSP_NotificationListProps {
  filterBy: NotificationFilter;
  isLoading: boolean;
  markAllAsRead: () => void;
  unreadCount: number;
  showFilterBar: boolean;
  showEmptyState: boolean;
  emptyStateText: string;
  showMarkAllAsReadButton: boolean;
  options: NotificationFilter[];
  popperProps: PopperProps;
  openerProps: OpenerProps<HTMLDivElement>;
  onSelectChange: (
    event: SelectChangeEvent<NotificationFilter>,
    child: ReactNode,
  ) => void;
}

export const DSP_NotificationsPopoutView = ({
  filterBy,
  isLoading,
  popperProps,
  openerProps,
  onNotificationClicked,
  markAllAsRead,
  todayNotifications,
  olderNotifications,
  unreadCount,
  showFilterBar,
  showEmptyState,
  emptyStateText,
  showMarkAllAsReadButton,
  options,
  onSelectChange,
  hasMore,
  loadMore,
}: DSP_NotificationsPopoutViewProps) => {
  return (
    <>
      <DSP_NotificationsToolTip
        ref={openerProps.ref}
        open={popperProps.open}
        onClick={openerProps.onClick}
        badgeContent={unreadCount}
      />
      <ThemeProvider>
        <Popper
          {...popperProps}
          id="notifications-list"
          disablePortal
          width={{
            xs: '100%',
            sm: '32rem',
            md: '40rem',
          }}
        >
          {isLoading ? (
            <FlexColumn alignItems={'center'} p={5}>
              <CircularProgress size={32} color={'primary'} />
            </FlexColumn>
          ) : (
            <FlexColumn flexWrap={'nowrap'} maxHeight={'inherit'} gap={0}>
              <Box flexShrink={0} px={3} pt={2}>
                <Heading5 id="notifications-subheader">Notifications</Heading5>
                {showFilterBar && (
                  <Box pt={2}>
                    <FilterBar
                      leftContent={<></>}
                      rightContent={[
                        showMarkAllAsReadButton
                          ? [
                              <TextButton
                                key={'mark-all-as-read-button'}
                                data-id={'mark-all-as-read-button'}
                                onClick={markAllAsRead}
                              >
                                Mark all as read
                              </TextButton>,
                            ]
                          : null,
                        <SelectInput
                          key={'notifications-filter-select'}
                          data-id={'notifications-filter-select'}
                          label={'Show'}
                          value={filterBy}
                          options={options}
                          onChange={onSelectChange}
                          size={'small'}
                        />,
                      ]}
                      leftFlex={showMarkAllAsReadButton ? 1 : 6}
                      rightFlex={3}
                      rightContentMaxItems={2}
                    />
                  </Box>
                )}
              </Box>
              {showEmptyState ? (
                <Box flexShrink={1}>
                  <RingingBellEmptyState title={emptyStateText} />
                </Box>
              ) : (
                <DSP_NotificationList
                  todayNotifications={todayNotifications}
                  olderNotifications={olderNotifications}
                  onNotificationClicked={onNotificationClicked}
                  hasMore={hasMore}
                  loadMore={loadMore}
                />
              )}
            </FlexColumn>
          )}
        </Popper>
      </ThemeProvider>
    </>
  );
};

DSP_NotificationsPopoutView.displayName = 'DSP_NotificationsPopoutView';

export {
  //
  /** @deprecated use DSP_NotificationsPopoutViewProps instead*/
  DSP_NotificationsPopoutViewProps as NotificationsPopoutViewProps, //
  /** @deprecated use DSP_NotificationsPopoutView instead*/
  DSP_NotificationsPopoutView as NotificationsPopoutView,
};
