import {SettingsOutlined} from '@mui/icons-material';
import {AccreditationSchemesRoutes} from './AccreditationScheme';
import AuditorCreate from '../containers-accreditation/Auditors/CreateAuditor';
import AuditorDetails from '../containers-accreditation/Auditors/AuditorDetails';
import {AccreditationDriverMappingRoutes} from './AccreditationDriverMapping';
import {AccreditationVehicleMappingRoutes} from './AccreditationVehicleMapping';
import {AccreditationEmployeeMappingRoutes} from './AccreditationEmployeeMapping';

export default {
  routes: [
    ...AccreditationSchemesRoutes,
    AuditorCreate,
    AuditorDetails,
    ...AccreditationDriverMappingRoutes,
    ...AccreditationVehicleMappingRoutes,
    ...AccreditationEmployeeMappingRoutes,
  ],
  menuTitle: 'Accreditation Administration',
  menuDescription: 'Administration of accreditation schemes',
  path: '/accreditation-admin',
  showChildRoutesInMenu: true,
  menuIcon: SettingsOutlined,
};
