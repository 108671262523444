import {
  AuthorisationDomains,
  UserRoles,
} from '@regulatory-platform/common-utils';
import Cookies from 'js-cookie';
import * as R from 'ramda';
import {DateTime} from 'luxon';
import {v5 as uuidv5} from 'uuid';

export function isProductionPrimary(): boolean {
  return R.includes(getEnvironment().remoteENV, ['Production']);
}
export function isProduction(): boolean {
  return R.includes(getEnvironment().remoteENV, ['Production', 'Backup']);
}
export function isUATOrProduction(): boolean {
  return R.includes(getEnvironment().remoteENV, [
    'Production',
    'Backup',
    'UAT',
  ]);
}
export function isUAT(): boolean {
  return R.includes(getEnvironment().remoteENV, ['UAT']);
}
export function isQA(): boolean {
  return R.includes(getEnvironment().remoteENV, ['QA']);
}

export const PortalV1Urls = {
  QA: 'https://cp-uat.service.nhvr.gov.au/',
  STAGING: 'https://cp-staging.service.nhvr.gov.au/',
  PROD: 'https://www.service.nhvr.gov.au/',
  DEV: 'http://localhost:2999/',
} as const;

export function getPortalV1Url() {
  switch (getEnvironment().remoteENV) {
    case 'QA':
    case 'UAT':
      return PortalV1Urls.QA;
    case 'STAGING':
      return PortalV1Urls.STAGING;
    case 'Backup':
    case 'Production':
      return PortalV1Urls.PROD;
    default:
      return PortalV1Urls.DEV;
  }
}

export function getDebugDomain(): AuthorisationDomains | undefined {
  return !isUATOrProduction()
    ? (sessionStorage
        .getItem('DEBUG_DOMAIN')
        ?.toLowerCase() as AuthorisationDomains)
    : undefined;
}

export function getEnvironment(): {
  author: string;
  name: string;
  code: string;
  copyright: string;
  version: string;
  buildNumber: string;
  branchName: string;
  releaseName: string;
  releaseDescription: string;
  releaseStartDate: string;
  remoteENV: string;
  brandTheme: string;
  featureNetworkManagement: string;
} {
  return R.omit(['connectors'], window.globalEnvVariables);
}

export function getEnvironmentStatus(): {
  name: string;
  version: string;
  buildNumber: string;
  branchName: string;
  releaseName: string;
  releaseDescription: string;
  releaseStartDate: string;
} {
  const env = R.clone(
    R.omit(['author', 'code', 'copyright', 'remoteENV'], getEnvironment()),
  );
  if (env.version === '#{CLIENT_APP_VERSION}#') {
    env.version = '0.0.0';
  }
  if (env.releaseStartDate === '#{RELEASE_DEPLOYMENT_STARTTIME}#') {
    env.releaseStartDate = DateTime.fromJSDate(new Date()).toFormat(
      'dd-MMM-yyyy',
    );
  } else {
    env.releaseStartDate = DateTime.fromSQL(env.releaseStartDate).toFormat(
      'dd-MMM-yyyy',
    );
  }
  return env;
}

/**
 * 7 days (uom=days) for session refresh token (remember me not selected)
 */
export const SESSION_TOKEN_EXPIRY = 7;
/**
 * 90 days (uom=days) for local refresh token (remember me selected)
 */
export const LOCAL_TOKEN_EXPIRY = 90;
/**
 * 24 hours (uom=seconds) for session idle auto logout (remember me not selected)
 */
export const SESSION_IDLE_LOGOUT = 60 * 60 * 24;
/**
 * 7 days (uom=seconds) local idle auto logout (remember me selected)
 * @TODO removing auto logout for local due to large integer not supported on all browsers
 */
// export const LOCAL_IDLE_LOGOUT = 60 * 60 * 24 * 7;
/**
 * 60 seconds (uom=seconds) for idle auto logout from trigger
 */
export const IDLE_AUTO_LOGOUT_DELAY = 60;
/**
 * 60 seconds (uom=seconds) buffer on JWT token expiry
 */
export const EXPIRY_OFFSET = 60;

/**
 * 0.5 seconds (uom=milliseconds) default debounce link
 */
export const DEFAULT_DEBOUNCE_TIMEOUT = 500;

export function getSessionType(): 'local' | 'session' {
  return Cookies.get('rememberMe') === 'true' ? 'local' : 'session';
}

/**
 * Refresh token expiry time (uom=days)
 */
export function getRefreshTokenExpiry(): number {
  return getSessionType() === 'local'
    ? LOCAL_TOKEN_EXPIRY
    : SESSION_TOKEN_EXPIRY;
}

/**
 * Idle logout trigger time (uom=seconds)
 */
export function getIdleLogout(): number {
  return SESSION_IDLE_LOGOUT;
  // const idleTrigger =
  //   getSessionType() === 'local' ? LOCAL_IDLE_LOGOUT : SESSION_IDLE_LOGOUT;
  // return idleTrigger;
}

/**
 *
 * FeatureTokens to override
 *
 * sample .env
 * OVERRIDE_FEATURE_TOKENS=[{"tokenKey": "NUR_CONSENT","value": false}]
 */
export function tokensToOverride() {
  const overrideTokens = JSON.parse(
    process.env.OVERRIDE_FEATURE_TOKENS || '[]',
  ) as Array<{tokenKey: string; value: boolean}>;
  return overrideTokens;
}

export function getDomain(): AuthorisationDomains {
  let domain: AuthorisationDomains;
  let brandThemeVar: string | undefined =
    window.globalEnvVariables?.brandTheme?.toLowerCase();

  if (brandThemeVar === '#{brand_theme}#') {
    brandThemeVar = process.env.BRAND_THEME?.toLowerCase();
  }

  if (brandThemeVar) {
    domain = brandThemeVar as AuthorisationDomains;
  } else {
    domain = AuthorisationDomains.NHVR;
  }

  return getDebugDomain() ?? domain;
}

export function webWorkerOnSameDomain(): boolean {
  if (window.globalEnvVariables.remoteENV.indexOf('{CLIENT_ENV}') !== -1) {
    return false;
  }
  return true;
}

export function getWebworkerURL(service: string, path: string): URL {
  let WEBWORKER_PATH = path.replace('./', '');
  let WEBWORKER_BASE_URL = '';

  if (window.globalEnvVariables.remoteENV.indexOf('{CLIENT_ENV}') !== -1) {
    WEBWORKER_BASE_URL =
      window.location.protocol + '//' + window.location.hostname + ':4001';
    WEBWORKER_PATH = './' + service + '/' + WEBWORKER_PATH;
  } else {
    WEBWORKER_BASE_URL =
      window.location.protocol + '//' + window.location.hostname;
    WEBWORKER_PATH = './' + service + '-api-server/' + WEBWORKER_PATH;
  }
  return new URL(WEBWORKER_PATH, WEBWORKER_BASE_URL);
}

export function getGraphqlEndpoint(): string {
  if (window.globalEnvVariables?.remoteENV.indexOf('{CLIENT_ENV}') !== -1) {
    // local environment
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':4000/graphql'
    );
  } else if (
    window.location.hostname.indexOf('master-auth-api-client-nhvr') > -1 ||
    window.location.hostname.indexOf('master-wa-auth-api-client-nhvr') > -1 ||
    window.location.hostname.indexOf('master-defence-auth-api-client-nhvr') > -1
  ) {
    return (
      window.location.protocol +
      '//portal-qa.nhvr.gov.au/gateway-api-server/graphql'
    );
  }
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    '/gateway-api-server/graphql'
  );
}

export function getGraphqlEndpointKey(): string {
  let GRAPHQL_ENDPOINT_KEY = '';

  const remoteAPIServerKey =
    window.globalEnvVariables?.connectors.remoteAPI.key;
  if (
    remoteAPIServerKey !== undefined &&
    remoteAPIServerKey !== '#{CLIENT_GRAPHQL_ENDPOINT_KEY}#'
  ) {
    GRAPHQL_ENDPOINT_KEY = remoteAPIServerKey;
  }
  return GRAPHQL_ENDPOINT_KEY;
}

export function getWebsocketEndpoint(): string {
  if (window.globalEnvVariables?.remoteENV.indexOf('{CLIENT_ENV}') === -1) {
    return (
      'wss://' +
      window.location.hostname +
      '/webpubsub/client/hubs/notifications'
    );
  }

  // assert QA environment
  return 'wss://portal-qa.nhvr.gov.au/webpubsub/client/hubs/notifications';
}

function getPortalClientAPIEndpoint(): string {
  if (isUAT()) {
    return 'https://api-public-uat.nhvr.gov.au';
  } else if (isProduction()) {
    return 'https://api-public.nhvr.gov.au';
  }

  // assert QA env
  return 'https://qa-gateway-portal-nhvr.azure-api.net';
}

export const mapBoxSuggestionSearchUrl =
  'https://api.mapbox.com/search/searchbox/v1/suggest';

export const getMapBoxApplicationToken = (): string => {
  //random application guid
  if (isProduction()) return '1b671a64-40d5-491e-99b0-da01ff1f3341';
  return '1b671a64-40d5-491e-99b0-da01ff1f3342';
};
//random application guid combined with user id to create a unique session token required for logging and rate limiting
export const getMapBoxSessionToken = (userId: number) => {
  const namespace = getMapBoxApplicationToken();

  const userSessionToken = uuidv5(userId.toString(), namespace);
  return userSessionToken;
};
/**
 * getPortalClientAPIMapEndpoint returns the API endpoint for the Map service
 */
export function getPortalClientAPIMapEndpoint(): string {
  // allow a specific Map API endpoint in local environments (Devs - use .env file)
  if (process.env.CLIENT_API_MAP_ENDPOINT) {
    return process.env.CLIENT_API_MAP_ENDPOINT;
  }

  return `${getPortalClientAPIEndpoint()}/map`;
}

/**
 * getPortalClientAPIEndpointKey obtains the Azure subscription key for accessing the Portal Client API endpoint(_not_ the GraphQL API endpoint)
 */
export function getPortalClientAPIEndpointKey(): string {
  const portalClientEndpointKey =
    window.globalEnvVariables?.connectors.portalClientAPI.key;
  if (
    portalClientEndpointKey !== undefined &&
    portalClientEndpointKey !== '#{CLIENT_API_ENDPOINT_KEY}#'
  ) {
    return portalClientEndpointKey;
  }

  // assert QA env
  // Note for Devs. We don't want to include a subscription key in public files.
  // If working locally, please add a (or add to your existing) .env file, a 'CLIENT_API_ENDPOINT_KEY' variable
  // The value for the QA/Master env can be obtained from Azure or from other Devs
  if (process.env.CLIENT_API_ENDPOINT_KEY) {
    return process.env.CLIENT_API_ENDPOINT_KEY;
  }

  return '';
}

export function getSpatialWMTSDefaultEndpoint(): string {
  // TODO switch to 'https://maps-dev.nhvr.gov.au/wmts' when DNS is updated
  let wmtsEndpoint =
    'https://app-geoserver-way2go-master-002-dev.azurewebsites.net/geoserver/gwc/service/wmts';

  const remoteAPIServer =
    window.globalEnvVariables?.connectors.spatialWMTSDefaultAPI.server;
  if (
    remoteAPIServer !== undefined &&
    remoteAPIServer !== '#{CLIENT_SPATIAL_WMTS_DEFAULT_ENDPOINT}#'
  ) {
    wmtsEndpoint = remoteAPIServer;
  }
  return wmtsEndpoint;
}

export function getSpatialWMTSNatMapEndpoint(): string {
  // TODO switch to 'https://maps-dev.nhvr.gov.au/natmap-wmts' when DNS is updated
  let wmtsEndpoint =
    'https://app-geoserver-way2go-master-002-dev.azurewebsites.net/geoserver/gwc/service/wmts';

  const remoteAPIServer =
    window.globalEnvVariables?.connectors.spatialWMTSNatMapAPI.server;
  if (
    remoteAPIServer !== undefined &&
    remoteAPIServer !== '#{CLIENT_SPATIAL_WMTS_NATMAP_ENDPOINT}#'
  ) {
    wmtsEndpoint = remoteAPIServer;
  }
  return wmtsEndpoint;
}

export function getSpatialOWSDefaultEndpoint(): string {
  // TODO switch to 'https://maps-dev.nhvr.gov.au/ows' when DNS is updated
  let wmtsEndpoint =
    'https://app-geoserver-way2go-master-002-dev.azurewebsites.net/geoserver/way2go/ows';

  const remoteAPIServer =
    window.globalEnvVariables?.connectors.spatialOWSDefaultAPI.server;
  if (
    remoteAPIServer !== undefined &&
    remoteAPIServer !== '#{CLIENT_SPATIAL_OWS_DEFAULT_ENDPOINT}#'
  ) {
    wmtsEndpoint = remoteAPIServer;
  }
  return wmtsEndpoint;
}

export function getMapboxKey(): string {
  let MAPBOX_KEY =
    'pk.eyJ1Ijoibmh2ci1wcm9kdWN0aW9uIiwiYSI6ImNsZWRpbHg1MzA0bTM0Mmxmb2V6M2dsaW4ifQ._0Dc9yxbFdL06WkpE6vm8g';

  const remoteAPIServerKey =
    window.globalEnvVariables?.connectors.remoteMapboxAPI.key;
  if (
    remoteAPIServerKey !== undefined &&
    remoteAPIServerKey !== '#{CLIENT_MAPBOX_KEY}#'
  ) {
    MAPBOX_KEY = remoteAPIServerKey;
  }
  return MAPBOX_KEY;
}

export function getMapboxStyleAccessToken(): string {
  return 'pk.eyJ1Ijoibmh2ci1wcm9kdWN0aW9uIiwiYSI6ImNsM3hsejIxYjEwYmUzYm56MGltOXZjcGoifQ.5Ju5P72_aVqIRRjd4DhS_g';
}

/**
 * Default Maps - Road Network Labels V4
 */
export const MAPBOX_STYLE_DEFAULT_ROAD_LABELS = 'clpt6xr4f00ez01r80buta5al';

/**
 * Satellite Maps - Road Network Labels V4
 */
export const MAPBOX_STYLE_SATELLITE_ROAD_LABELS = 'clpt7jw2400eu01pxbcrs67te';

/**
 * Spatial Default (Without Road Labels) V4
 */
export const MAPBOX_STYLE_DEFAULT = 'clpt71qln00ty01pg2bftg0go';

/**
 * National Map Default (Without Road Labels) V4
 */
export const MAPBOX_STYLE_DEFAULT_NATMAP = 'clpt6mnbg00es01r702rf5yu3';

/**
 * Spatial Satellite (Without Road Labels) V4
 */
export const MAPBOX_STYLE_SATELLITE = 'clpt79e1w00fh01reapy1h844';

/**
 * Spatial Satellite (Background only) V4
 */
export const MAPBOX_STYLE_SATELLITE_BG = 'cll4pwqjy008n01pr5odlf06i';

/**
 * Spatial Streets
 */
export const MAPBOX_STYLE_STREET = 'cl3xp2hy8000014p59c5n9f1z';

/**
 * Spatial Traffic
 */
export const MAPBOX_STYLE_TRAFFIC = 'cl3xomgth000115nn01sja5i2';

/**
 * Spatial Terrain
 */
export const MAPBOX_STYLE_TERRAIN = 'cl3xp8il8000115nqlu1ichpr';

/**
 * Spatial Hybrid
 */
export const MAPBOX_STYLE_HYBRID = 'cl3xntxeo000414o0zzia1xkm';

/**
 * Spatial Light
 */
export const MAPBOX_STYLE_LIGHT = 'mapbox/light-v10';

/**
 * Spatial Dark
 */
export const MAPBOX_STYLE_DARK = 'cl3xnoiih000014o6z0ciwhny';

/**
 * Reference Layer Icons V4 (style id + sprite sheet id)
 */
export const MAPBOX_STYLE_LEGEND_ICONS =
  'clqa3cigd002d01ob68hocdad/77o7nzwc9tpgx02g2uxudii4j';

const getMapboxApiUrl = (url: string): string =>
  `https://api.mapbox.com/styles/v1/nhvr-production/${url}`;

export const mapboxSpriteDataUrl = `${getMapboxApiUrl(
  MAPBOX_STYLE_LEGEND_ICONS,
)}/sprite@2x.json?access_token=${getMapboxStyleAccessToken()}`;

export const mapboxSpriteImageUrl = `${getMapboxApiUrl(
  MAPBOX_STYLE_LEGEND_ICONS,
)}/sprite@2x.png?access_token=${getMapboxStyleAccessToken()}`;

export enum EnumMapboxStyle {
  default,
  defaultNatMap,
  defaultRoadLabels,
  satellite,
  satelliteRoadLabels,
  street,
  traffic,
  terrain,
  hybrid,
  light,
  dark,
}

export type MapboxStyle = keyof typeof EnumMapboxStyle;

export function getMapboxStyle(
  style: MapboxStyle,
  options?: {removePrefix?: boolean},
): string {
  const styleMap: Record<MapboxStyle, string> = {
    default: MAPBOX_STYLE_DEFAULT,
    defaultNatMap: MAPBOX_STYLE_DEFAULT_NATMAP,
    defaultRoadLabels: MAPBOX_STYLE_DEFAULT_ROAD_LABELS,
    satellite: MAPBOX_STYLE_SATELLITE,
    satelliteRoadLabels: MAPBOX_STYLE_SATELLITE_ROAD_LABELS,
    street: MAPBOX_STYLE_STREET,
    traffic: MAPBOX_STYLE_TRAFFIC,
    terrain: MAPBOX_STYLE_TERRAIN,
    hybrid: MAPBOX_STYLE_HYBRID,
    light: MAPBOX_STYLE_LIGHT,
    dark: MAPBOX_STYLE_DARK,
  };

  let mapboxStyle = styleMap[style] ?? MAPBOX_STYLE_DEFAULT;

  const remoteAPIServerTheme =
    window.globalEnvVariables?.connectors.remoteMapboxAPI.themes[style];
  if (
    remoteAPIServerTheme !== undefined &&
    remoteAPIServerTheme.indexOf('#{CLIENT_MAPBOX_STYLE_') === -1
  ) {
    mapboxStyle = remoteAPIServerTheme;
  }

  return options?.removePrefix
    ? `nhvr-production/${mapboxStyle}`
    : `mapbox://styles/nhvr-production/${mapboxStyle}`;
}

export function getSurveyKey(survey: 'assetNomination'): string {
  let SURVEY_KEY = '';
  const surveyConfig = window._surveys[survey];
  if (surveyConfig !== undefined && surveyConfig.indexOf('#{') !== 0) {
    SURVEY_KEY = surveyConfig;
  }
  return SURVEY_KEY;
}

export function getMuiDataGridKey(): string {
  let DATAGRID_KEY =
    'fbfa6c152a052d883a99252ff0741002Tz05MTA0NixFPTE3NDc5NTEyMzcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
  const key = window.globalEnvVariables?.connectors.muiDataGrid.key;
  if (key !== undefined && key.indexOf('#{') !== 0) {
    DATAGRID_KEY = key;
  }
  return DATAGRID_KEY;
}

export function getRoutingEndpoint(): string {
  let ROUTING_ENDPOINT = 'https://fc-qa-master.azurewebsites.net/api';

  const remoteAPIServer =
    window.globalEnvVariables?.connectors.remoteWayToGoRoutingAPI.server;
  if (
    remoteAPIServer !== undefined &&
    remoteAPIServer !== '#{CLIENT_ROUTING_ENDPOINT}#'
  ) {
    ROUTING_ENDPOINT = remoteAPIServer;
  }
  return ROUTING_ENDPOINT;
}

export function getRoutingEndpointKey(): string {
  let ROUTING_KEY = 'fec9e93113274688ba92051f7ab23bde';

  const remoteAPIServerKey =
    window.globalEnvVariables?.connectors.remoteWayToGoRoutingAPI.key;
  if (
    remoteAPIServerKey !== undefined &&
    remoteAPIServerKey !== '#{CLIENT_ROUTING_KEY}#'
  ) {
    ROUTING_KEY = remoteAPIServerKey;
  }
  return ROUTING_KEY;
}

export function isNhvrGo() {
  return window.globalEnvVariables?.useNhvrGo?.toUpperCase() === 'TRUE';
}

export function getGoogleMapsApiKey(): string {
  let googleMapsKey = 'AIzaSyD2fDVWovkJtPmGFXfu7aaGg1QFxIWguvs';
  const GoogleMapsAPIKey =
    window.globalEnvVariables?.connectors.remoteGoogleMapsAPI.key;
  if (
    GoogleMapsAPIKey !== undefined &&
    GoogleMapsAPIKey !== '#{CLIENT_GOOGLE_MAPS_KEY}#'
  ) {
    googleMapsKey = GoogleMapsAPIKey;
  }
  return googleMapsKey;
}

export function getGooglePlacesApiKey(): string {
  let googlePlacesKey = 'AIzaSyAGcyTYPcQx--2hNCIXzFMFiSF1YlIx580';
  const GooglePlacesKey =
    window.globalEnvVariables?.connectors.remoteGoogleMapsAPI.key;
  if (
    GooglePlacesKey !== undefined &&
    GooglePlacesKey !== '#{CLIENT_GOOGLE_MAPS_KEY}#'
  ) {
    googlePlacesKey = GooglePlacesKey;
  }
  return googlePlacesKey;
}

export function getRecaptchaSiteKey(): string {
  let recaptchaSiteKey = '6LeyL8EZAAAAALvrL2r5i2oib4kBUUQXbR0B5wlY';
  const remoteGoogleRecaptchaAPIKey =
    window.globalEnvVariables?.connectors.remoteGoogleRecaptchaAPI.key;
  if (
    remoteGoogleRecaptchaAPIKey !== undefined &&
    remoteGoogleRecaptchaAPIKey !== '#{CLIENT_GOOGLE_RECAPTCHA_KEY}#'
  ) {
    recaptchaSiteKey = remoteGoogleRecaptchaAPIKey;
  }
  return recaptchaSiteKey;
}

export function getThemeColor(accountType: string): string {
  // This is purposefully not changed yet because any fix atm would be a hacky fix with no purpose
  // TODO: Remove this when we create the new app bar
  let themeColor;
  if (accountType === 'customer') {
    themeColor = '#7aba15';
  } else if (accountType === 'partner') {
    themeColor = '#f49d2b';
  } else if (accountType === 'operations') {
    themeColor = '#0364aa';
  } else if (accountType === 'provider') {
    themeColor = '#00bfbf';
  } else {
    themeColor = '#747474';
  }
  return themeColor;
}

export function splitCamelCase(word: string): string[] {
  return word.split(/(?=[A-Z])/g);
}

export function capitalizeFirstLetter(str: string): string {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export function mapToGetValidWords(str: string): string {
  let word;
  switch (str) {
    case 'Sys':
      word = 'System Admin';
      break;
    case 'Finance':
      word = 'Financial Admin';
      break;
    case 'Supervisor':
      word = 'Supervisor';
      break;
    case 'Pay':
      word = 'Submitter';
      break;
    case 'User':
      word = 'General User';
      break;
    case 'Read':
      word = 'Read Only';
      break;
    default:
      word = str;
  }
  return word;
}

export function mapUserRolesToRole(roles: UserRoles[]): string {
  let mappedRole = '';
  if (R.is(Array, roles)) {
    R.map(role => {
      const roleSplit = splitCamelCase(role);
      if (roleSplit.length >= 3) {
        if (roleSplit[1] === 'Account') {
          const accountRoleSplit = R.slice(2, roleSplit.length, roleSplit);
          let activeRole = mapToGetValidWords(roleSplit[2]);
          if (accountRoleSplit.length > 2) {
            activeRole = R.reduce(
              (accValue, r) => {
                return (
                  mapToGetValidWords(capitalizeFirstLetter(accValue)) +
                  ' ' +
                  mapToGetValidWords(capitalizeFirstLetter(r))
                );
              },
              '',
              accountRoleSplit,
            );
          }
          mappedRole = activeRole;
        }
      }
    }, roles);
  }
  return mappedRole;
}

export function mapAccountTypeToReadable(accountType: string): string {
  if (accountType === 'partner') {
    accountType = 'Road Manager';
  } else if (accountType === 'operations') {
    accountType = 'Regulator';
  } else if (accountType === 'provider') {
    accountType = 'Provider';
  } else {
    accountType = capitalizeFirstLetter(accountType);
  }
  return accountType;
}

export function getRemoteEnvironment(): string {
  // default to QA if not set
  if (
    !window.globalEnvVariables?.remoteENV ||
    window.globalEnvVariables?.remoteENV === '#{CLIENT_ENV}#'
  ) {
    return 'QA';
  }

  return window.globalEnvVariables?.remoteENV;
}

declare global {
  interface Window {
    globalEnvVariables: {
      apiServers: {[key: string]: string};
      connectors: {
        remoteAPI: {
          key: string;
        };
        remoteAnalyticsAPI: {
          key: string;
        };
        remoteGoogleAnalyticsAPI: {
          key: string;
        };
        remoteGoogleRecaptchaAPI: {
          key: string;
        };
        remoteGoogleMapsAPI: {
          key: string;
        };
        remoteGoogleTagAPI: {
          server: string;
          key: string;
        };
        remoteGoogleGeoAPI: {
          server: string;
          key: string;
        };
        remoteIntercomAPI: {
          key: string;
        };
        remoteSurvicateAPI: {
          key: string;
        };
        remoteWayToGoRoutingAPI: {
          server: string;
          key: string;
        };
        remoteMapboxAPI: {
          key: string;
          themes: {
            default: string;
            defaultNatMap: string;
            defaultRoadLabels: string;
            satellite: string;
            satelliteRoadLabels: string;
            street: string;
            traffic: string;
            terrain: string;
            hybrid: string;
            light: string;
            dark: string;
          };
        };
        spatialWMTSDefaultAPI: {
          server: string;
        };
        spatialWMTSNatMapAPI: {
          server: string;
        };
        spatialOWSDefaultAPI: {
          server: string;
        };
        muiDataGrid: {
          key: string;
        };
        portalClientAPI: {
          key: string;
        };
        digitalIdApi: {
          server_url: string;
          redirect_url: string;
          USER_ID: string;
          TRANSACTION_PASSWORD: string;
        };

        b2c: {
          instance_id: string;
          client_id: string;
          mfa_type: 'PHONE' | 'PHONEOREMAIL' | 'NONE';
          tenant_id: string;
        };
      };
      author: string;
      name: string;
      code: string;
      copyright: string;
      version: string;
      buildNumber: string;
      branchName: string;
      releaseName: string;
      releaseDescription: string;
      releaseStartDate: string;
      remoteENV: string;
      brandTheme: string;
      featureNetworkManagement: string;
      useNhvrGo: 'TRUE' | 'FALSE';
    };
    _surveys: {
      assetNomination: string;
    };
    _productTours: {
      nationalMap: string;
    };
    _sva: {
      setVisitorTraits: (traits: object) => void;
      showSurvey: (
        survey: string,
        options?: {
          forceDisplay: boolean;
          displayMethod?: 'immediately' | 'delayed' | 'exitIntent' | 'onScroll';
          displayOptions?: {
            delay: number;
          };
        },
      ) => void;
    };
    dataLayer: {push: (event: object) => void};
    Intercom: (action: string, event?: unknown, details?: unknown) => void;
    appInsights: {
      devConsoleLog: (message: string) => void;
      trackPageView: (
        action: string,
        event: unknown,
        environment: unknown,
        measurement?: string,
        duration?: number,
      ) => void;
      trackException(
        exception: string,
        handledAt: string,
        environment: unknown,
        measurements?: string,
      ): void;
      trackEvent(
        event: string,
        environment: unknown,
        eventValue?: string,
      ): void;
      trackMetric(
        name: string,
        average: number,
        sampleCount: number,
        min: number,
        max: number,
        environment: unknown,
      ): void;
      setAuthenticatedUserContext(
        authenticatedUserId: string,
        activeAccountTypeAndId: string,
      ): void;
      clearAuthenticatedUserContext(): void;
    };
  }
}

export const MAPBOX_GEOCODE_API_URL =
  'https://api.mapbox.com/search/geocode/v6';

export const australiaStateIconMap = {
  ACT: 'https://nhvrappsprod.blob.core.windows.net/policies/ActStateIcon.svg',
  QLD: 'https://nhvrappsprod.blob.core.windows.net/policies/QldStateIcon.svg',
  NSW: 'https://nhvrappsprod.blob.core.windows.net/policies/NswStateIcon.svg',
  SA: 'https://nhvrappsprod.blob.core.windows.net/policies/SaStateIcon.svg',
  VIC: 'https://nhvrappsprod.blob.core.windows.net/policies/VicStateIcon.svg',
  WA: 'https://nhvrappsprod.blob.core.windows.net/policies/WA-lg.png',
  NT: 'https://nhvrappsprod.blob.core.windows.net/policies/NT-lg.png',
  TAS: 'https://nhvrappsprod.blob.core.windows.net/policies/TAS-lg.png',
  '3': 'https://nhvrappsprod.blob.core.windows.net/policies/ActStateIcon.svg',
  '220': 'https://nhvrappsprod.blob.core.windows.net/policies/QldStateIcon.svg',
  '4': 'https://nhvrappsprod.blob.core.windows.net/policies/NswStateIcon.svg',
  '478': 'https://nhvrappsprod.blob.core.windows.net/policies/SaStateIcon.svg',
  '378': 'https://nhvrappsprod.blob.core.windows.net/policies/VicStateIcon.svg',
  '10752': 'https://nhvrappsprod.blob.core.windows.net/policies/WA-lg.png',
  '10749': 'https://nhvrappsprod.blob.core.windows.net/policies/NT-lg.png',
  '190': 'https://nhvrappsprod.blob.core.windows.net/policies/TAS-lg.png',
};
