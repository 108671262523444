import {lazy} from 'react';
import {RouteConfig} from 'app/router/types';
import {
  AuthorisationDomains,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils/dist';
import Today from '@mui/icons-material/Today';

export default {
  path: '/audit-requests',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AuditRequestProvider" */ 'containers-accreditation/AuditRequests/Provider/AuditRequestProvider'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: true,
  menuTitle: 'Audit Requests',
  menuIcon: Today,
  authorisation: {
    domain: AuthorisationDomains.WA,
    apiKey: 'AuditRequest.findProvider',
    policies: [{role: 'providerAccreditationUser'}] as AuthorisationPolicy[],
  },
  sections: [],
} as RouteConfig;
