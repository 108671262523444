import React from 'react';

import {DocumentNode} from '@apollo/client';

import {
  DSP_EntityLoaderProvider,
  useEntityLoaderContext,
} from '../../EntityLoaderProvider';
import {DSP_EntityLoaderProviderProps} from '../../types';

import {GetCustomerAccountFindByIdDocument} from './api/GetCustomerAccountFindById.generated';
import {DSP_CustomerAccountEveryonePublic} from './types';

export type DSP_CustomerAccountEntityLoaderProviderProps =
  DSP_EntityLoaderProviderProps & {
    query?: DocumentNode;
  };

export const DSP_CustomerAccountEntityLoaderProvider = ({
  entityId: id,
  query,
  ...props
}: DSP_CustomerAccountEntityLoaderProviderProps) => (
  <DSP_EntityLoaderProvider<DSP_CustomerAccountEveryonePublic>
    query={query ?? GetCustomerAccountFindByIdDocument}
    variables={{id}}
    {...props}
  />
);

export const useCustomerAccountEntityLoaderContext = () =>
  useEntityLoaderContext<DSP_CustomerAccountEveryonePublic>();

DSP_CustomerAccountEntityLoaderProvider.displayName =
  'DSP_CustomerAccountEntityLoaderProvider';
