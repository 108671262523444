import InfoIcon from '@mui/icons-material/InfoOutlined';
import Roadmap from 'containers-information/Roadmap';
import NHVASApprovedAuditors from 'containers-information/NHVASApprovedAuditors';
import WAHVAApprovedAuditors from 'containers-information/WAHVAApprovedAuditors';
import RoutePlannerTool from 'containers-information/RoutePlannerTool';
import AvmSearch from 'containers-information/AvmSearch';
import IncidentReport from 'containers-information/IncidentReport';
import NHVASApprovedRTOs from 'containers-information/NHVASApprovedRTOs';
import Home from 'containers-information/Home';

export default {
  routes: [
    Home,
    RoutePlannerTool,
    Roadmap,
    AvmSearch,
    IncidentReport,
    NHVASApprovedAuditors,
    WAHVAApprovedAuditors,
    NHVASApprovedRTOs,
  ],
  path: '/information',
  menuTitle: 'Information Hub',
  menuDescription:
    'The Information Hub contains useful services such as the National Network Map, Route Planner Tool, Approved Auditors and more.',
  showChildRoutesInMenu: true,
  menuIcon: InfoIcon,
};
