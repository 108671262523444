import {RouteConfig} from '../types';
import {UserProfile} from '@regulatory-platform/common-utils/dist';
import userProfileAuthorisedForRoute from './userProfileAuthorisedForRoute';

export const userProfileIsAuthorisedForRouteAsideFromIdVerification = (
  route: RouteConfig,
  userProfile?: UserProfile,
  options?: {ignoreImpersonationPolicies?: boolean},
): boolean => {
  return userProfileAuthorisedForRoute(
    route,
    userProfile ? {...userProfile, idVerified: true} : undefined,
    options,
  );
};

export const userProfileIsAuthorisedButNotVerifiedForRoute = (
  route: RouteConfig,
  userProfile?: UserProfile,
  options?: {ignoreImpersonationPolicies?: boolean},
): boolean => {
  const isAuthorised = userProfileAuthorisedForRoute(
    route,
    userProfile,
    options,
  );

  if (isAuthorised) {
    return false;
  }

  return userProfileIsAuthorisedForRouteAsideFromIdVerification(
    route,
    userProfile,
    options,
  );
};
