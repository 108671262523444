import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_KeepRight = (props: DSL_IconProps) => {
  const theme: Theme = useTheme();
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            <path
              fill={theme.palette.text.primary}
              fillRule="evenodd"
              d="m31.268 21.768-3.536-3.535L37 8.965l9.268 9.268-3.536 3.535-3.232-3.232V48h-5V18.536z"
              clipRule="evenodd"
            />
            <path
              fill={theme.palette.text.primary}
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M24 48V13h-5v35z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill={theme.palette.background.paper} d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_KeepRight);
