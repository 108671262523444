import {useScript} from 'usehooks-ts';
import {useApolloClient} from '@apollo/client';
import {useIdentityVerifyMutation} from '../../api/mutation/IdentityVerify.generated';
import {useOnValueChange} from 'utils/useOnValueChange';
import {jwtDecode} from 'jwt-decode';
import {JwtTokenDecoded} from '@regulatory-platform/common-utils/dist';
import authService from 'app/services/authService';
import {useCallback} from 'react';
import {useSnackbarService} from 'app/services/AppServicesContext';

interface VerificationResponseSuccess {
  code?: string;
  transactionId?: string;
}

interface VerificationResponseFailure {
  error?:
    | 'unauthorized_client'
    | 'access_denied'
    | 'unsupported_response_type'
    | 'invalid_scope'
    | 'server_error'
    | 'temporarily_unavailable'
    | 'verification_cancelled'
    | 'verification_failed'
    | 'verification_in_progress';
  error_description?: string;
  transaction_id?: string;
}

type VerificationResponse =
  | VerificationResponseSuccess
  | VerificationResponseFailure;

interface DigitalIdInit {
  clientId: string;
  onClick?: (options: {verificationSessionToken: string}) => void;
  onLoadComplete?: () => void;
  onKeepAlive?: () => void;
  buttonConfig?: {
    type?: 'basic';
    label?: string;
    classNames?: string;
  };
  uxMode?: 'popup' | 'redirect';
  selector?: string;
  action?: 'idv' | 'authentication';
  onComplete: (response: VerificationResponse) => void;
  experienceConfig?: {
    displayName?: string;
    logoUri?: string;
  };
  overlayEnabled?: boolean;
}

function isVerificationError(
  verificationResponse: VerificationResponse,
): verificationResponse is VerificationResponseFailure {
  return !!(
    (verificationResponse as VerificationResponseFailure).error ||
    (verificationResponse as VerificationResponseFailure).error_description
  );
}

interface DigitalId {
  init: (args: DigitalIdInit) => void;
}

declare global {
  interface Window {
    digitalId: DigitalId;
  }
}

export function useIdVerify(onVerify?: () => void) {
  const digitalIdConfig = window.globalEnvVariables.connectors.digitalIdApi;
  const script = `${digitalIdConfig.redirect_url}/sdk/app.js`;
  const snackbarService = useSnackbarService();

  const scriptStatus = useScript(script, {removeOnUnmount: true});

  const client = useApolloClient();
  const [verifyIdentityMutation, {loading}] = useIdentityVerifyMutation({
    client,
  });

  const onSubmitVerification = useCallback(
    async (code: string) => {
      const {data, errors} = await verifyIdentityMutation({variables: {code}});

      const {token, refreshToken} =
        data?.userModelControllerIdentityVerify ?? {};

      if (errors?.[0]) {
        const message =
          errors[0].extensions?.responseBody?.error?.message ??
          'Unknown error verifying your identity. Please try again.';
        snackbarService.send('TRIGGER', {
          record: {
            message,
            variant: 'error',
          },
        });
      } else if (token && refreshToken) {
        const userToken = jwtDecode(token) as JwtTokenDecoded;

        // send to auth service and trigger save of new refresh token to cookies
        authService.service.send('REFRESH', {
          data: {
            data: {
              userModelControllerRefresh: {
                refreshToken,
                userProfile: userToken,
              },
            },
          },
        });

        snackbarService.send('TRIGGER', {
          record: {
            message: `ID successfully verified`,
            variant: 'success',
          },
        });

        onVerify?.();
      }
    },
    [verifyIdentityMutation, onVerify, snackbarService],
  );

  useOnValueChange(scriptStatus, newStatus => {
    if (newStatus === 'ready') {
      window.digitalId.init({
        clientId: digitalIdConfig.USER_ID,
        onComplete: function (response) {
          if (isVerificationError(response)) {
            snackbarService.send('TRIGGER', {
              record: {
                message: response.error_description,
                variant: 'error',
              },
            });
          } else {
            if (response?.code) {
              onSubmitVerification(response.code);
            }
          }
        },
      });
    }
  });

  return {
    loading,
    scriptStatus,
  };
}
