import {useMemo} from 'react';
import * as R from 'ramda';
import {createTheme, Theme, ThemeOptions} from '@mui/material/styles';
import {outlinedInputClasses} from '@mui/material';
import {useThemeSelection} from 'app/theming/ThemeSelectionProvider';
import {isNhvrGo} from 'utils/global';

function getPaletteColor(key: string, fallback: string, theme: Theme) {
  return (R.path(key.split('.'), theme.palette) ??
    R.path(fallback.split('.'), theme.palette)) as string;
}

export const useAppBarThemeProvider = ({map}: {map?: boolean}) => {
  // TODO: once NHVR GO goes live we can kill this code
  const useNHVRGo = isNhvrGo();
  const {selectedTheme, availableThemes} = useThemeSelection();
  const baseTheme = availableThemes[selectedTheme];

  const appbarBgColor = getPaletteColor(
    map ? 'background.paper' : useNHVRGo ? 'brand.main' : 'background.paper',
    'background.paper',
    baseTheme,
  );

  const appBarSecondaryColor = getPaletteColor(
    map ? 'text.secondary' : 'brand.mutant',
    'secondary.main',
    baseTheme,
  );

  const primaryTextColor = getPaletteColor(
    map ? 'text.primary' : 'brand.contrastText',
    'text.primary',
    baseTheme,
  );

  const secondaryTextColor = getPaletteColor(
    map ? 'text.secondary' : 'brand.contrastText',
    'text.secondary',
    baseTheme,
  );

  const inputBorderColor = getPaletteColor(
    'brand.contrastText',
    'brand.primary',
    baseTheme,
  );

  return useMemo(
    () =>
      useNHVRGo
        ? createTheme({
            ...baseTheme,
            palette: {
              ...baseTheme.palette,
              secondary: {
                main: appBarSecondaryColor,
              },
              background: {
                default: appbarBgColor,
                paper: appbarBgColor,
              },
              text: {
                primary: primaryTextColor,
                secondary: secondaryTextColor,
              },
              action: {
                ...baseTheme.palette.action,
                active: primaryTextColor,
              },
            },
            components: {
              ...baseTheme.components,
              MuiAppBar: {
                styleOverrides: {
                  colorPrimary: {
                    backgroundColor: appbarBgColor,
                  },
                },
              },
              MuiBadge: {
                defaultProps: {
                  color: 'secondary',
                },
              },
              MuiSvgIcon: {
                defaultProps: {
                  color: 'secondary',
                },
              },
              MuiFormLabel: {
                styleOverrides: {
                  root: {
                    '&.Mui-focused': {
                      color: primaryTextColor,
                    },
                  },
                },
              },
              MuiOutlinedInput: {
                styleOverrides: {
                  root: {
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: inputBorderColor,
                    },
                    [`& .${outlinedInputClasses.notchedOutline}:hover`]: {
                      borderColor: inputBorderColor,
                    },
                    '&.Mui-focused': {
                      [`& .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: inputBorderColor,
                      },
                    },
                  },
                },
              },
            },
          } as ThemeOptions)
        : createTheme({
            ...baseTheme,
            palette: {
              ...baseTheme.palette,
            },
            components: {
              ...baseTheme.components,
              MuiAppBar: {
                styleOverrides: {
                  colorPrimary: {
                    backgroundColor: appbarBgColor,
                  },
                },
              },
            },
          } as ThemeOptions),
    [
      baseTheme,
      appBarSecondaryColor,
      appbarBgColor,
      primaryTextColor,
      secondaryTextColor,
      useNHVRGo,
      inputBorderColor,
    ],
  );
};
