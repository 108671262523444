import {WorkflowActivitySystemType} from 'containers-workflow/Shared/types';

import {DSP_WorkflowActivityNotificationItem} from './Workflow/types';

export const getHrefFromBaseUrl = (
  notification: DSP_WorkflowActivityNotificationItem,
  baseUrl: string,
): string | undefined => {
  const {id, systemType, urlProperties} = notification.data;
  const activityId =
    urlProperties?.activityId ?? notification.data.headerActivity?.id ?? id;

  let effectiveSystemType = systemType;

  if (systemType === WorkflowActivitySystemType.DECISION_TASK) {
    effectiveSystemType = WorkflowActivitySystemType.TASK;
  }

  if (!effectiveSystemType || !activityId) {
    return baseUrl;
  }

  return `${baseUrl}?workflowTab=${effectiveSystemType}&activityType=${effectiveSystemType}&activityId=${activityId}`;
};
