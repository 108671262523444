import ListOutlined from '@mui/icons-material/ListOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
  AuthorisationDomains,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accreditation-wahva',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AuditorsPublic-WAHVA" */ 'containers-accreditation/Auditors/Public/AuditorsPublic'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'WAHVA Approved Auditors',
  menuDescription:
    'List of WAHVA Approved Auditors who can audit an WAHVA management system.',
  menuIcon: ListOutlined,
  authorisation: {
    domain: AuthorisationDomains.WA,
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
