import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/audit-requests',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AuditRequestProvider" */ './AuditRequestProvider'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Audit Requests',
  menuIcon: Today,
  authorisation: {
    apiKey: 'AuditRequest.findProvider',
    policies: [
      {role: 'providerAccreditationUser', idVerified: true},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation',
      to: '../',
    },
  ],
};
