import React, {ReactNode} from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_Popover, DSL_PopoverProps} from '../../Layout/Popover';
import {DSL_Body1} from '../../Typography/Body1';
import {DSL_Body2} from '../../Typography/Body2';
import {DSL_EntitySubtitle} from '../EntitySubtitle/EntitySubtitle';
import {DSL_EntityTitle} from '../EntityTitle/EntityTitle';

export interface DSL_EntityPopoverProps
  extends Omit<
    DSL_PopoverProps,
    'disableRestoreFocus' | 'pointerEvents' | 'anchorOrigin' | 'transformOrigin'
  > {
  isLoading: boolean;
  title: string;
  subTitle?: string;
  children?: ReactNode;
}

export const DSL_EntityPopover = ({
  onClick,
  isLoading,
  title,
  subTitle,
  children,
  ...popoverProps
}: DSL_EntityPopoverProps) => {
  const theme: Theme = useTheme();

  return (
    <DSL_Popover
      disableRestoreFocus
      pointerEvents={'none'}
      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
      transformOrigin={{horizontal: 'left', vertical: 'top'}}
      {...popoverProps}
      zIndex={theme.zIndex.entityPopover}
    >
      <DSL_FlexColumn
        gap={3}
        p={3}
        pointerEvents={'auto'}
        onClick={event => {
          onClick?.(event);
          event.stopPropagation();
        }}
      >
        <DSL_FlexColumn justifyContent={'center'}>
          <DSL_Body1>
            <DSL_EntityTitle isLoading={isLoading}>{title}</DSL_EntityTitle>
          </DSL_Body1>
          {subTitle && (
            <DSL_Body2 color={'text.secondary'}>
              <DSL_EntitySubtitle isLoading={isLoading}>
                {subTitle}
              </DSL_EntitySubtitle>
            </DSL_Body2>
          )}
        </DSL_FlexColumn>
        {children && (
          <DSL_FlexColumn justifyContent={'center'}>{children}</DSL_FlexColumn>
        )}
      </DSL_FlexColumn>
    </DSL_Popover>
  );
};

DSL_EntityPopover.displayName = 'DSL_EntityPopover';

export {
  //
  /** @deprecated use DSL_EntityPopoverProps instead*/
  DSL_EntityPopoverProps as EntityPopoverProps, //
  /** @deprecated use DSL_EntityPopover instead*/
  DSL_EntityPopover as EntityPopover,
};
