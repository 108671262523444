import React from 'react';

import {DSP_UserEntityAvatarWithNameView} from './UserEntityAvatarWithName.View';
import {useUserEntityAvatarWithName} from './useUserEntityAvatarWithName';

export const DSP_UserEntityAvatarWithName = ({
  showTitle,
}: {
  showTitle?: boolean;
}) => {
  const viewProps = useUserEntityAvatarWithName();

  return (
    <DSP_UserEntityAvatarWithNameView {...viewProps} showTitle={showTitle} />
  );
};

DSP_UserEntityAvatarWithName.displayName = 'DSP_UserEntityAvatarWithName';
