import React, {forwardRef} from 'react';

import {
  DSL_BaseIconButton,
  DSL_FlexRow,
  DSL_NotificationsBell,
  DSL_Tooltip,
  DSL_TooltipProps,
} from 'components/DesignSystem/Library';

export interface DSP_NotificationsToolTipProps
  extends Omit<DSL_TooltipProps, 'title' | 'onClick' | 'children'> {
  open: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  badgeContent: number;
}

export const DSP_NotificationsToolTip = forwardRef(
  (
    {
      open,
      onClick,
      badgeContent,
      ...tooltipProps
    }: DSP_NotificationsToolTipProps,
    ref,
  ) => {
    return (
      <DSL_Tooltip
        ref={ref}
        title={open ? '' : 'Notifications'}
        {...tooltipProps}
      >
        <DSL_FlexRow alignItems={'center'} onClick={onClick} cursor={'pointer'}>
          <DSL_BaseIconButton
            id="notifications-button"
            aria-controls={open ? 'notifications-list' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-label="Toggle notifications menu"
          >
            <DSL_NotificationsBell
              badgeContent={badgeContent}
              aria-label={
                badgeContent > 0
                  ? 'You have unread messages'
                  : 'You have no unread messages'
              }
            />
          </DSL_BaseIconButton>
        </DSL_FlexRow>
      </DSL_Tooltip>
    );
  },
);

DSP_NotificationsToolTip.displayName = 'DSP_NotificationsToolTip';

export {
  //
  /** @deprecated use DSP_NotificationsToolTipProps instead*/
  DSP_NotificationsToolTipProps as NotificationsToolTipProps, //
  /** @deprecated use DSP_NotificationsToolTip instead*/
  DSP_NotificationsToolTip as NotificationsToolTip,
};
