import React from 'react';

import {DSL_MoreVertIconButton} from '../../Buttons/IconButtons/MoreVertIconButton';
import {useMenu} from '../../hooks/useMenu';
import {DSL_Menu, DSL_MenuItem} from '../../Menus';

export interface DSL_MoreVertIconMenuProps {
  menuItems: {
    label: string;
    onClick: () => void;
  }[];
  id?: string;
}

export const DSL_MoreVertIconMenu = ({
  menuItems,
  id = 'more-vert-icon-menu',
}: DSL_MoreVertIconMenuProps) => {
  const {isOpen, anchorEl, handleOpenMenu, handleCloseMenu} = useMenu();
  return (
    <>
      <DSL_MoreVertIconButton
        aria-label={`'Open menu`}
        id={`${id}-more-vert-icon-menu-button`}
        aria-controls={`${id}-menu`}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      />
      <DSL_Menu
        id={`${id}-menu`}
        MenuListProps={{
          'aria-labelledby': `${id}-more-vert-icon-menu-button`,
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleCloseMenu}
      >
        {menuItems.map((menuItem, index) => (
          <DSL_MenuItem
            key={index}
            onClick={() => {
              handleCloseMenu();
              menuItem.onClick();
            }}
          >
            {menuItem.label}
          </DSL_MenuItem>
        ))}
      </DSL_Menu>
    </>
  );
};

DSL_MoreVertIconMenu.displayName = 'DSL_MoreVertIconMenu';
