import React from 'react';

import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@mui/material';
import {SystemCssProperties} from '@mui/system';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_PopoverProps
  extends Omit<MuiPopoverProps, DSL_RestrictedVisualProps> {
  pointerEvents?: SystemCssProperties['pointerEvents'];
  zIndex?: SystemCssProperties['zIndex'];
}

export const DSL_Popover = ({
  pointerEvents,
  zIndex,
  ...props
}: DSL_PopoverProps) => {
  return (
    <MuiPopover
      sx={{
        pointerEvents,
        zIndex,
      }}
      {...props}
    />
  );
};

DSL_Popover.displayName = 'DSL_Popover';

export {
  //
  /** @deprecated use DSL_PopoverProps instead*/
  DSL_PopoverProps as PopoverProps, //
  /** @deprecated use DSL_Popover instead*/
  DSL_Popover as Popover,
};
