import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/vehicleDefects',
  component: lazy(
    () => import(/* webpackChunkName: "VehicleDefects" */ './VehicleDefects'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Vehicle Defects',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'VehicleDefectWithRelations.findById',
    policies: [
      {role: 'operationsComplianceRead'},
      {role: 'customerComplianceRead', idVerified: true},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
