import React from 'react';

export interface DSL_SelfServeBadgeProps {
  width?: string;
  height?: string;
}

const DSL_SelfServeBadge = ({
  width = '100',
  height = '100',
}: DSL_SelfServeBadgeProps) => {
  const viewBoxParams = '0 0 '.concat(width, ' ', height);
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBoxParams}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50.125" cy="49.875" r="34.5869" fill="#CEE4E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.3092 32.9502L36.5112 39.0826V48.2813C36.5112 56.79 42.3984 64.7469 50.3092 66.6786C58.22 64.7469 64.1072 56.79 64.1072 48.2813V39.0826L50.3092 32.9502ZM44.3371 50.9892C43.7145 50.451 43.7145 49.495 44.3371 48.8971C44.9592 48.3589 45.955 48.3589 46.5772 48.9564L48.5691 50.8691L53.7347 45.9087C54.2952 45.3109 55.2908 45.3109 55.9134 45.9087C56.536 46.447 56.536 47.403 55.9134 48.0008L49.6893 53.9776C49.3785 54.2765 49.0051 54.3962 48.5692 54.3962C48.1338 54.3962 47.7604 54.2765 47.4491 53.9776L44.3371 50.9892Z"
        fill="#00696E"
      />
    </svg>
  );
};

export default React.memo(DSL_SelfServeBadge);
