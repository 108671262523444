import React from 'react';

import {DSL_HelpIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_HelpIconButtonProps = Omit<DSL_BaseIconButtonProps, 'children'>;

export const DSL_HelpIconButton = (
  props: DSL_HelpIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="more" {...props}>
      <DSL_HelpIcon />
    </DSL_BaseIconButton>
  );
};

DSL_HelpIconButton.displayName = 'DSL_HelpIconButton';

export {
  //
  /** @deprecated use DSL_HelpIconButtonProps instead*/
  DSL_HelpIconButtonProps as HelpIconButtonProps, //
  /** @deprecated use DSL_HelpIconButton instead*/
  DSL_HelpIconButton as HelpIconButton,
};
