import React from 'react';
import {RouteConfig} from '../types';
import SetPageTitle from './SetPageTitle';
import {useRegisterContent} from 'containers-content';
export interface RouteElementProps extends RouteConfig {
  shouldSetPageTitle?: boolean;
}

export const RouteElement = ({
  shouldSetPageTitle = true,
  content,
  ...route
}: RouteElementProps) => {
  useRegisterContent(content);
  return (
    <>
      {shouldSetPageTitle && <SetPageTitle route={route} />}
      {/* we have an issue with the way our pages are constructed where, when the
      page component is loaded, it instantiates the machine with the given params
      like the id, and then doesn't re-instantiate if those params change.

      This means that if you're viewing a page for a record with id x, and you
      click on a link that takes you to id y, the machine isn't re-instantiated and
      so you stay on the old record.  Fixing this at every page would require
      significant work, so instead we set the key of the component to the path to force
      an unmount and re-mout when the path changes (NOT the query params)
      This works everywhere I've tested it. If we ever enable sub-paths via react-router
      we may need to revisit this. */}
      {/* key pattern: to create unique keys, combination of route patn and window.location.pathname
      is used. For the subroutes, window.location.pathname is appended to the route path */}
      <route.component
        key={`${route.path} ${route.parent ? '' : window.location.pathname}`}
      />
    </>
  );
};

RouteElement.displayName = 'RouteElement';
