import React from 'react';

export const NHVRGoIcon = () => {
  return (
    <svg
      height="24"
      viewBox="0 0 544 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_302_675)">
        <mask
          id="mask0_302_675"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="544"
          height="93"
        >
          <path d="M544 0H0V93H544V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_302_675)">
          <path
            d="M71.3991 0.794394H66.3319C65.2693 0.794394 64.1954 1.06292 64.1954 2.93142V72.48C55.4146 61.5599 12.5169 9.33133 4.79872 1.06292C3.99334 0.268527 3.19914 0 2.13649 0C0.805379 0 0 0.794394 0 1.8685V89.0057C0 90.6056 0.536919 91.1427 1.86803 91.1427H7.19248C8.79206 91.1427 9.06052 90.0798 9.06052 89.0057V19.9829C18.121 30.9142 62.3498 84.2057 68.2111 91.1315C68.7481 91.9259 69.5423 92.4629 70.8734 92.4629C72.7414 92.4629 73.2671 91.4 73.2671 90.3259V3.19995C73.5356 2.13703 73.5356 0.805582 71.3991 0.805582"
            fill="white"
          />
          <path
            d="M160.382 0.794434H155.852C153.984 0.794434 153.727 1.60002 153.727 3.45733V46.8916H98.849V3.46852C98.849 1.86854 98.5805 0.805622 96.7125 0.805622H91.9138C90.0458 0.805622 89.52 1.34268 89.52 3.46852V89.0057C89.52 90.8742 90.0569 91.4001 91.6453 91.4001H96.444C98.0436 91.4001 98.5805 90.863 98.5805 89.2742V55.4286H153.458V89.0057C153.458 90.8742 154.252 91.1427 155.595 91.1427H160.125C161.993 91.1427 162.519 90.8742 162.519 89.0057V3.46852C162.519 1.60002 162.25 0.805622 160.393 0.805622"
            fill="white"
          />
          <path
            d="M252.565 0.794448H246.972C245.909 0.794448 245.372 1.60003 244.847 2.66295L212.877 75.143L179.029 1.86856C178.492 0.805637 177.698 0.537109 176.635 0.537109H171.042C170.506 0.537109 169.98 0.537109 169.711 1.07416C169.174 1.61122 169.443 2.40561 169.711 2.94267C171.311 6.41115 209.678 89.2854 210.472 90.8854C210.741 91.4225 211.267 93.0112 212.866 93.0112C214.197 93.0112 214.734 92.2168 215.003 91.1427C215.797 89.5428 251.771 7.73141 253.37 4.27412C253.907 2.40561 254.164 1.87975 253.639 1.34269C253.907 0.805637 253.37 0.805637 252.576 0.805637"
            fill="white"
          />
          <path
            d="M270.686 9.06268H297.062C309.579 9.06268 318.908 17.5884 318.908 29.3141C318.908 41.0398 310.384 48.2341 296.525 48.2341H270.686V9.06268ZM332.487 87.6741C324.489 76.4854 311.973 59.1654 308.773 54.9025C320.765 50.6397 327.42 41.3083 327.42 29.3253C327.42 12.7997 314.635 0.81665 297.319 0.81665H263.214C262.945 0.81665 261.614 1.08518 261.614 2.95368V89.2964C261.614 89.8335 261.614 91.4223 263.482 91.4223H269.075C270.675 91.4223 270.943 90.3594 270.943 89.2964V56.5249H299.713L323.427 89.565C324.221 90.6279 325.026 90.8964 326.089 90.8964H331.414C332.208 90.8964 333.807 90.8964 333.807 89.2964C333.27 88.7594 333.013 88.2335 332.476 87.6965"
            fill="white"
          />
        </g>
        <path
          d="M498.286 75.957C482.212 75.957 469.169 62.9222 469.169 46.8329C469.169 30.7437 482.201 17.7089 498.286 17.7089C514.371 17.7089 527.391 30.7437 527.391 46.8329C527.391 62.9222 514.36 75.957 498.286 75.957ZM498.286 2.01123C473.196 2.01123 452.86 22.3522 452.86 47.4483C452.86 72.5444 473.196 92.8854 498.286 92.8854C523.376 92.8854 543.712 72.5444 543.712 47.4483C543.712 22.3522 523.376 2.01123 498.286 2.01123Z"
          fill="#CBDB2A"
        />
        <path
          d="M444.147 39.4372H407.278L391.786 55.8621L416.283 55.9852L391.484 81.3834L383.061 89.8084C383.117 89.8308 383.184 89.8532 383.24 89.8756C388.273 91.8 393.732 92.8741 399.448 92.8741C400.801 92.8741 402.133 92.807 403.453 92.6839L427.938 68.192V92.8629L444.169 76.6282V39.4148H444.158L444.147 39.4372Z"
          fill="white"
        />
        <path
          d="M374.134 85.1764L386.428 72.8801C376.886 68.1025 370.331 58.2341 370.331 46.8329C370.331 30.7548 383.363 17.7089 399.437 17.7089C409.392 17.7089 418.184 22.7214 423.43 30.352H441.518C434.762 13.7257 418.475 2 399.426 2C374.336 2 354 22.341 354 47.4371C354 63.1571 361.987 77.0087 374.123 85.1652"
          fill="#CBDB2A"
        />
      </g>
      <defs>
        <clipPath id="clip0_302_675">
          <rect width="544" height="93" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
