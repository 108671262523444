import React, {ForwardedRef, forwardRef, ReactNode} from 'react';

import {DSL_ChevronRightIcon} from 'components/DesignSystem/Library/Icons';
import {DSL_BorderlessCard} from 'components/DesignSystem/Library/Layout/Card/BorderlessCard';
import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';
import {
  usePopper,
  DSL_Popper,
  DSL_PopperBody,
} from 'components/DesignSystem/Library/Layout/Popper';

import {
  DSL_ButtonCardActionArea,
  DSL_ButtonCardActionAreaProps,
} from '../../../../Layout/Card/CardActionArea';
import {DSL_Body1} from '../../../../Typography/Body1';
import {DSL_BaseCardInputProps} from '../../BaseCardInput';
import {DSL_RadioOption, DSL_RadioOptionProps} from '../../RadioGroupInput';

export interface DSL_NestedRadioCardOptionViewProps
  extends Omit<DSL_BaseCardInputProps, 'input'>,
    Omit<DSL_RadioOptionProps, 'ref'> {
  label: ReactNode;
  onClickCard: DSL_ButtonCardActionAreaProps['onClick'];
  description?: ReactNode;
  describedBy?: string;
  tooltip?: string;
}

export const DSL_NestedRadioCardOptionView = forwardRef(
  (
    {
      isHighlighted,
      value,
      label,
      disabled,
      onClickCard,
      description,
      describedBy,
      children,
    }: DSL_NestedRadioCardOptionViewProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const {openerProps, popperProps} = usePopper();
    return (
      <>
        <DSL_FlexRow width={'100%'}>
          <DSL_BorderlessCard square width={'100%'}>
            <DSL_ButtonCardActionArea
              {...openerProps}
              ref={openerProps.ref as React.Ref<HTMLButtonElement>}
              active={isHighlighted}
              onClick={event => {
                onClickCard?.(event);
                openerProps.onClick?.(event);
              }}
            >
              <DSL_FlexRow
                px={2}
                py={1}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <DSL_FlexRow alignItems={'center'}>
                  <DSL_RadioOption
                    ref={ref}
                    value={value}
                    label={label}
                    disabled={disabled}
                    aria-describedby={describedBy}
                    checked={isHighlighted}
                  />
                </DSL_FlexRow>
                {description && (
                  <DSL_Body1 component={'div'} color={'text.secondary'}>
                    {description}
                  </DSL_Body1>
                )}
                <DSL_ChevronRightIcon color={'action'} />
              </DSL_FlexRow>
            </DSL_ButtonCardActionArea>
          </DSL_BorderlessCard>
        </DSL_FlexRow>
        <DSL_Popper {...popperProps} placement={'right-start'}>
          <DSL_PopperBody>{children}</DSL_PopperBody>
        </DSL_Popper>
      </>
    );
  },
);

DSL_NestedRadioCardOptionView.displayName = 'DSL_NestedRadioCardOptionView';
