import React from 'react';

import {DSL_ButtonBarGroup} from 'components/DesignSystem/Library/Buttons/ButtonBar';
import {DSL_ContainedButton} from 'components/DesignSystem/Library/Buttons/ContainedButton';
import {DSL_OutlinedButton} from 'components/DesignSystem/Library/Buttons/OutlinedButton';
import {useBreakpoint} from 'components/DesignSystem/Library/hooks/useBreakpoint';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_FlexColumn} from '../../FlexColumn';
import {DSL_Dialog, DSL_DialogProps} from '../Dialog';
import {DSL_DialogActions} from '../DialogActions';
import {DSL_DialogContent} from '../DialogContent';
import {DSL_DialogTitle} from '../DialogTitle';

export interface DSL_TransactionalDialogProps
  extends Omit<DSL_DialogProps, DSL_RestrictedVisualProps | 'title'> {
  onClose?: () => void;
  isLoading?: boolean;
  onSave?: () => void;
  title?: React.ReactNode;
  saveLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
}

export const DSL_TransactionalDialog = ({
  disabled,
  title,
  onClose,
  children,
  isLoading,
  onSave,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  ...props
}: DSL_TransactionalDialogProps) => {
  const {isXs} = useBreakpoint();
  const Wrapper = isXs ? DSL_FlexColumn : React.Fragment;
  const WrapperProps = isXs ? {gap: 2, width: '100%'} : {};
  return (
    <DSL_Dialog
      aria-label={'transactional-dialog'}
      aria-labelledby="transactional-dialog-title"
      aria-describedby="transactional-dialog-content"
      {...props}
    >
      <DSL_DialogTitle
        id="transactional-dialog-title"
        title={title}
        onClose={onClose}
      />
      <DSL_DialogContent id="transactional-dialog-content">
        {children}
      </DSL_DialogContent>
      <DSL_DialogActions>
        <DSL_ButtonBarGroup noWrap={isXs}>
          <Wrapper {...WrapperProps}>
            <DSL_OutlinedButton onClick={onClose} fullWidth={isXs}>
              {cancelLabel}
            </DSL_OutlinedButton>
            <DSL_ContainedButton
              onClick={onSave}
              loading={isLoading}
              disabled={disabled || isLoading}
              fullWidth={isXs}
            >
              {saveLabel}
            </DSL_ContainedButton>
          </Wrapper>
        </DSL_ButtonBarGroup>
      </DSL_DialogActions>
    </DSL_Dialog>
  );
};

DSL_TransactionalDialog.displayName = 'DSL_TransactionalDialog';

export {
  //
  /** @deprecated use DSL_TransactionalDialogProps instead*/
  DSL_TransactionalDialogProps as TransactionalDialogProps, //
  /** @deprecated use DSL_TransactionalDialog instead*/
  DSL_TransactionalDialog as TransactionalDialog,
};
