import React from 'react';

import {DSL_ChipProps} from 'components/DesignSystem/Library';

import {DSP_StatusChipView} from './StatusChip.View';
import {useStatusChip} from './useStatusChip';

export type DSP_StatusChipProps = Omit<DSL_ChipProps, 'color' | 'label'> & {
  fieldRef: string;
  style?: string;
};

export const DSP_StatusChip = ({
  fieldRef,
  style,
  ...props
}: DSP_StatusChipProps) => {
  const statusChipProps = useStatusChip(fieldRef, style);

  return <DSP_StatusChipView {...statusChipProps} {...props} />;
};

DSP_StatusChip.displayName = 'DSP_StatusChip';
