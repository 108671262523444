import React from 'react';

import {useBaseCardInput, UseBaseCardInputProps} from '../../BaseCardInput';

import {
  DSL_NestedRadioCardOptionView,
  DSL_NestedRadioCardOptionViewProps,
} from './NestedRadioCardOption.View';

export interface DSL_NestedRadioCardOptionProps
  extends Omit<DSL_NestedRadioCardOptionViewProps, 'onClickCard'>,
    UseBaseCardInputProps {}

export const DSL_NestedRadioCardOption = ({
  onClickCard,
  children,
  ...props
}: DSL_NestedRadioCardOptionProps) => {
  const viewProps = useBaseCardInput({onClickCard});

  return (
    <DSL_NestedRadioCardOptionView {...props} {...viewProps}>
      {children}
    </DSL_NestedRadioCardOptionView>
  );
};

DSL_NestedRadioCardOption.displayName = 'DSL_NestedRadioCardOption';
