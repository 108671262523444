import {lazy} from 'react';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/registrations',
  component: lazy(
    () => import(/* webpackChunkName: "Registrations" */ './Registrations'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Vehicle registrations',
  menuIcon: EmojiTransportationIcon,
  authorisation: {
    apiKey: 'VehicleRegistrationExtendedWithRelations.findById',
    policies: [
      {
        role: 'operationsRegistrationEnabled',
      },
      {
        role: 'partnerRegistrationEnabled',
        idVerified: true,
      },
      {
        role: 'customerRegistrationEnabled',
        idVerified: true,
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
