import React, {ReactNode} from 'react';

import {
  DSL_Chip,
  DSL_HorizontalStack,
  DSL_RouterLink,
} from 'components/DesignSystem/Library';

import {DSP_NotificationCard} from '../NotificationCard';
import {DSP_NotificationCardBody} from '../NotificationCardBody';
import {DSP_NotificationCardHeader} from '../NotificationCardHeader';
import {DSP_NotificationCardTitle} from '../NotificationCardTitle';

export interface DSP_RecordAssignmentNotificationCardViewProps {
  avatar: ReactNode | undefined;
  href?: string;
  applicationId?: string;
  created?: Date;
  actionDescription?: string;
  name?: string;
  description?: string;
  status?: string;
  isRead?: boolean;
}

export const DSP_RecordAssignmentNotificationCardView = ({
  avatar,
  href,
  created,
  actionDescription,
  name,
  description,
  status,
  applicationId,
  isRead,
}: DSP_RecordAssignmentNotificationCardViewProps) => {
  return (
    <DSP_NotificationCard href={href} isRead={isRead}>
      <DSP_NotificationCardHeader
        created={created}
        actionDescription={actionDescription}
        avatar={avatar}
      />
      {name && <DSP_NotificationCardTitle>{name}</DSP_NotificationCardTitle>}
      {description && (
        <DSP_NotificationCardBody>{description}</DSP_NotificationCardBody>
      )}
      <DSL_HorizontalStack>
        <DSL_RouterLink href={href}>{applicationId}</DSL_RouterLink>
        <DSL_Chip variant={'outlined'} size={'small'} label={status} />
      </DSL_HorizontalStack>
    </DSP_NotificationCard>
  );
};

DSP_RecordAssignmentNotificationCardView.displayName =
  'DSP_RecordAssignmentNotificationCardView';
