import DataUsage from '@mui/icons-material/Dashboard';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import UpdateAccreditationVehicleMapping from './AccreditationVehicleMapping';

const AccreditationVehicleMappings = {
  path: '/vehicles',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccreditationVehicleMappings" */ './AccreditationVehicleMappings'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Vehicles',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'accreditationVehicleMappingWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};

export default AccreditationVehicleMappings;

export const AccreditationVehicleMappingRoutes = [
  AccreditationVehicleMappings,
  UpdateAccreditationVehicleMapping,
];
