import React from 'react';

import {AuditRequestAccountTypeNotificationRendererMap} from './constants';
import {DSP_AuditRequestNotificationItem} from './types';

export interface DSP_AuditRequestNotificationCardProps {
  notification: DSP_AuditRequestNotificationItem;
}

export const DSP_AuditRequestNotificationCard = ({
  notification,
}: DSP_AuditRequestNotificationCardProps) => {
  const DSP_NotificationComponent =
    AuditRequestAccountTypeNotificationRendererMap[notification.accountType];

  if (!DSP_NotificationComponent) {
    return null;
  }

  return <DSP_NotificationComponent notification={notification} />;
};

DSP_AuditRequestNotificationCard.displayName =
  'DSP_AuditRequestNotificationCard';
