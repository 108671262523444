import {useFormContext} from 'components/DesignSystem/Portal';
import {useRef, useState} from 'react';
import {Transition} from 'history';
import {useBlocker} from 'react-router-dom';

export const useRouteBlocker = () => {
  const retryFn = useRef<Function | null>(null);
  const [open, setOpen] = useState(false);
  const {service, hasChangesToSave} = useFormContext();

  const handleBlockNavigation = ({retry, location}: Transition): void => {
    if (location.pathname === window.location.pathname) {
      retry();
      return;
    }
    retryFn.current = retry;
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useBlocker(handleBlockNavigation, !open && hasChangesToSave);

  return {
    onStay: () => {
      service?.send('BLOCKED');
      handleClose();
    },
    onLeave: () => {
      if (retryFn.current) retryFn.current();
    },
    open,
    onClose: handleClose,
    hasChangesToSave,
  };
};
