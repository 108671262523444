import React, {ReactNode, RefObject} from 'react';

import {DSL_ArrowDropDownOutlinedIcon} from '../../Icons';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_Paper} from '../../Layout/Paper';
import {DSL_Popper, usePopper} from '../../Layout/Popper';
import {DSL_MenuItem, DSL_MenuList} from '../../Menus';
import {DSL_Body1} from '../../Typography/Body1';
import {DSL_Caption} from '../../Typography/Caption';
import {DSL_ButtonGroup} from '../ButtonGroup/ButtonGroup';
import {DSL_ContainedButton} from '../ContainedButton';

export type DSL_SplitButtonOption = {
  value: string;
  label: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

const defaultRenderOption = (option: DSL_SplitButtonOption) => (
  <DSL_FlexColumn>
    <DSL_Body1>{option.label}</DSL_Body1>
    {option?.description && (
      <DSL_Caption color={'text.secondary'}>{option.description}</DSL_Caption>
    )}
  </DSL_FlexColumn>
);

export interface DSL_SplitButtonProps {
  options: DSL_SplitButtonOption[];
  onClick: (value: string) => void;
  renderOption?: (option: DSL_SplitButtonOption) => ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const DSL_SplitButton = ({
  options,
  onClick,
  fullWidth,
  renderOption = defaultRenderOption,
  disabled,
}: DSL_SplitButtonProps) => {
  const {popperProps, openerProps} = usePopper();
  const {onClick: onOpenerClick, ref} = openerProps;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    onClick(options[selectedIndex].value);
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    popperProps.closePopper();
  };

  return (
    <React.Fragment>
      <DSL_ButtonGroup
        variant={'contained'}
        ref={ref as RefObject<HTMLDivElement>}
        aria-label="split button"
        fullWidth={fullWidth}
        disabled={disabled}
      >
        <DSL_ContainedButton
          fullWidth={fullWidth}
          onClick={handleClick}
          disabled={options[selectedIndex].disabled}
          loading={options[selectedIndex].loading}
        >
          {options[selectedIndex].label}
        </DSL_ContainedButton>
        <DSL_ContainedButton
          size="small"
          aria-controls={popperProps.open ? 'split-button-menu' : undefined}
          aria-expanded={popperProps.open ? 'true' : undefined}
          aria-label="toggle split button"
          aria-haspopup="menu"
          onClick={onOpenerClick}
          autoWidth
        >
          <DSL_ArrowDropDownOutlinedIcon />
        </DSL_ContainedButton>
      </DSL_ButtonGroup>
      <DSL_Popper {...popperProps} disablePortal placement="bottom">
        <DSL_Paper>
          <DSL_MenuList id="split-button-menu" autoFocusItem>
            {options.map((option, index) => {
              if (index === selectedIndex) {
                return null;
              }
              return (
                <DSL_MenuItem
                  key={index}
                  selected={index === selectedIndex}
                  onClick={event => handleMenuItemClick(event, index)}
                  disabled={option.disabled}
                >
                  {renderOption(option)}
                </DSL_MenuItem>
              );
            })}
          </DSL_MenuList>
        </DSL_Paper>
      </DSL_Popper>
    </React.Fragment>
  );
};

export {
  //
  /** @deprecated use DSL_SplitButtonProps instead*/
  DSL_SplitButtonProps as SplitButtonProps, //
  /** @deprecated use DSL_SplitButtonOption instead*/
  DSL_SplitButtonOption as SplitButtonOption, //
  /** @deprecated use DSL_SplitButton instead*/
  DSL_SplitButton as SplitButton,
};
