import {lazy} from 'react';
import {RouteConfig} from 'app/router/types';
import {
  AuthorisationDomains,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils/dist';
import Assignment from '@mui/icons-material/Assignment';

export default {
  path: '/applications',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccreditationApplications" */ 'containers-accreditation/Manage/Applications/ManageAccreditationApplications'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: true,
  menuTitle: 'Applications',
  menuIcon: Assignment,
  authorisation: {
    domain: AuthorisationDomains.NHVR,
    apiKey: 'AccreditationApplicationWithRelations.findOperations',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [],
} as RouteConfig;
